import { createContext, useContext, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { isMobileByUserAgent } from '@/shared/utils/utils';
import useIssueAnonymousToken from '@/lib/hooks/useIssueAnonymousToken';

type ProviderType = {
  isMobile?: boolean;
  screenSize?: {
    width: number;
    height: number;
  };
  userAgent?: string;
};
const Context = createContext<ProviderType>({});
const Provider = (props: any) => {
  const { value, children } = props;
  const { width, height } = useWindowSize();
  const [isMobile, setIsMobile] = useState<boolean>(isMobileByUserAgent(value?.userAgent) || false);
  const [screenSize, setScreenSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const { issueAnonymousToken } = useIssueAnonymousToken();

  useEffect(() => {
    issueAnonymousToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsMobile(isMobileByUserAgent(value?.userAgent) || width < 780);
    setScreenSize({ width, height });
  }, [height, value?.userAgent, width]);

  const exposed = {
    isMobile,
    screenSize,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

/**
 * @deprecated This hook is deprecated and will be removed in future releases.
 * Please use the https://nextjs.org/docs/pages/api-reference/functions/userAgent and https://developer.mozilla.org/en-US/docs/Web/CSS/@media instead.
 */
export const useConfigProvider = () => useContext(Context);

export default Provider;
