import axios from '@/shared/utils/axios';
import { mock } from './mock';
import {
  AxiosRequestConfigExtendsProps,
  IfCreateRequestProps,
  PollRequestProps,
} from '@/shared/types/business-kits/api';
import {
  CreateResponseProps,
  GetCatalogCompaniesRequestProps,
  GetRequestProps,
} from '@/shared/types/business-kits/api/app/brand-kit-modules';
import { BrandKitProps } from '@/shared/types/business-kits/brand-kits';
import { MarketResearchResponseProps } from '@/shared/types/business-kits/api/app/market-research';
import { ProductSkuResponseProps } from '@/shared/types/business-kits/api/app/product-sku';
import { TargetAudienceResponseProps } from '@/shared/types/business-kits/api/app/target-audience';
import { BrandIdeaResponseProps } from '@/shared/types/business-kits/api/app/brand-idea';
import {
  AiStabilityAiImagesRequestProps,
  AiStabilityAiImagesResponseProps,
} from '@/shared/types/business-kits/api/app/ai/stability-ai-images';
import { GetCatalogCompaniesResponseProps } from '@/shared/types/business-kits/api/app/catelog-companies';
import { getUUID } from '../storage/uuid';
import { produce } from 'immer';
import { sessionStorageService } from '../storage';

const BASE_DIR = '/app/';
const BASE_URL = BASE_DIR + 'brand-kit-modules';

const _sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const request = async <T>({ method, url, data, headers, times, timeout }: AxiosRequestConfigExtendsProps) => {
  const mockData = mock({ method, url, data, headers, times });
  if (mockData !== null) return mockData;

  const config = {
    method,
    url,
    data,
    headers,
    timeout,
  };

  if (data === void 0) delete config.data;

  const response = await axios(config);

  return response.data as T;
};

export const create = async () => {
  return await request<CreateResponseProps>({
    method: 'POST',
    url: BASE_URL,
    headers: {
      'X-Anonymous-User-Id': getUUID('X-Anonymous-User-Id'),
    },
  });
};

export const get = async ({ id, type, ifCreateRequest = IfCreateRequestProps.BaseOnResponse }: GetRequestProps) => {
  return type === void 0
    ? await request<BrandKitProps>({
        method: 'GET',
        url: BASE_URL + '/' + id,
      })
    : await poll<
        TargetAudienceResponseProps | BrandIdeaResponseProps | ProductSkuResponseProps | MarketResearchResponseProps
      >({
        config: {
          method: 'GET',
          url: BASE_URL + '/' + id + '/requests/latest?type=' + type,
          headers: {
            'X-Anonymous-User-Id': getUUID('X-Anonymous-User-Id'),
          },
        },
        maxAttempts: 10,
        interval: 1500,
        ifCreateRequest,
      });
};

export const put = async (payload: BrandKitProps) => {
  return await request<BrandKitProps>({
    method: 'PUT',
    url: BASE_URL + '/' + payload.id,
    data: payload,
    headers: {
      'X-Anonymous-User-Id': getUUID('X-Anonymous-User-Id'),
    },
  });
};

const createRequest = async ({ config }: { config: AxiosRequestConfigExtendsProps }) => {
  return request({
    method: 'POST',
    url: BASE_URL + '/' + config.url.replace(/^.*?(\d+).*$/, '$1') + '/requests',
    data: {
      type: config.url.replace(/.*=/, ''),
    },
    headers: {
      'X-Anonymous-User-Id': getUUID('X-Anonymous-User-Id'),
    },
  });
};

async function poll<T>({ config, maxAttempts, interval, ifCreateRequest }: PollRequestProps) {
  const { Force, Once } = IfCreateRequestProps;
  for (let i = 0; i < maxAttempts; i++) {
    try {
      if (i === 0 && (ifCreateRequest === Force || ifCreateRequest === Once)) {
        await createRequest({ config });
        if (ifCreateRequest === Once) return;
      }
      const response = await request({
        ...config,
        times: i,
      });
      if (response.request === null || response.request.status === 'FAILURE') {
        await createRequest({ config });
        i--;
        continue;
      }
      const status = response.request?.status;
      if (status === 'SUCCESS') {
        return response as T;
      } else if (status === 'FAILURE') {
        throw new Error(response.request?.error?.data?.error);
      }
    } catch (error) {
      throw new Error(error);
    }
    await _sleep(interval);
  }
  throw new Error('Max attempts reached');
}

export const aiStabilityAiImages = async (payload: AiStabilityAiImagesRequestProps) => {
  return await request<AiStabilityAiImagesResponseProps>({
    method: 'POST',
    data: payload,
    url: BASE_DIR + 'ai/stability-ai-images',
    timeout: 60000,
  });
};

export const getCatalogCompanies = async ({ keyword }: GetCatalogCompaniesRequestProps) => {
  return await request<GetCatalogCompaniesResponseProps>({
    method: 'GET',
    url: BASE_DIR + 'catalog-companies/?keyword=' + keyword,
    timeout: 60000,
  });
};

export const storeMeBrandKit = async (pdfLink: string) => {
  const anonymousUserId = getUUID('X-Anonymous-User-Id');
  if (anonymousUserId === null) return;
  return await request({
    method: 'PUT',
    data: {
      brandKit: { pdfLink },
    },
    url: '/web/stores/me/migrate-anonymous-data',
    headers: {
      'X-Anonymous-User-Id': anonymousUserId,
    },
  });
};

export enum UpdateTypeProps {
  None = 0,
  Sync = 1,
  Async = 2,
}

export const save = async (
  data: BrandKitProps,
  updataDatabase: UpdateTypeProps = UpdateTypeProps.None,
  editPaths?: string[][],
  producer?: any,
) => {
  const id = data.id;
  const { getStorage, setStorage } = sessionStorageService('business-kits-' + id);
  let nextData = data;
  if (producer) {
    nextData = produce(data, producer);
    if (data === nextData) return;
  }
  setStorage(nextData, editPaths);
  const synchronizedData = getStorage() as BrandKitProps;
  if (updataDatabase === UpdateTypeProps.Sync) await put(synchronizedData);
  else if (updataDatabase === UpdateTypeProps.Async) put(synchronizedData);
  return synchronizedData;
};

export const load = (id: string) => {
  const { getStorage } = sessionStorageService('business-kits-' + id);
  return getStorage();
};
