import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache } from '@apollo/client';
import getConfig from 'next/config';
import { useMemo } from 'react';
import { SchemaLink } from '@apollo/client/link/schema';
import { onError } from '@apollo/client/link/error';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { message } from 'antd';
import { token } from '@/shared/utils/tools';

const { publicRuntimeConfig } = getConfig();
let apolloClient: ApolloClient<any>;

type InitialState = {
  handle?: string;
  [key: string]: any;
};

function createIsomorphLink() {
  if (typeof window === 'undefined') {
    return new SchemaLink(<SchemaLink.Options>{ schema: {} });
  } else {
    return new HttpLink({
      uri: publicRuntimeConfig.NEXT_PUBLIC_GRAPHQL_API_HOST + '/graphql',
      fetchOptions: {
        timeout: 90 * 1000, //Set the request timeout to 90 seconds
      },
    });
  }
}

function createApolloClient({ handle }: InitialState) {
  const errorLink = onError(({ networkError, response }) => {
    let _message: string;
    if (networkError) {
      _message = 'network request failed';
    } else {
      _message = response?.errors?.[0]?.message;
    }
    // Check network error type
    if (networkError?.name === 'TimeoutError' || networkError?.message.includes('Timeout exceeded')) {
      // Here you can perform your timeout error handling logic, such as notifying the user, retrying, etc.
      // console.error('GraphQL request timed out:', networkError.message);
      message.error('Timeout, please try again.');
    } else {
      const statusCode = response?.errors?.[0]?.extensions?.['statusCode'];
      message.error(_message);
      if (statusCode && (statusCode === 401 || statusCode === 403 || statusCode === 988)) {
        // There is no token, or the token expires, what 401 403, 988. Jump to the dedicated page.
        message.error('Failed to obtain token, please log in again.');
        // console.log('Failed to obtain token, please log in again');
      }
    }
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // get the authentication token from local storage if it exists
    // add the authorization to the headers

    const _token = token();

    operation.setContext(({ headers = {} }) => {
      if (headers?.['Authorization']) return headers;
      return {
        headers: {
          ...headers,
          Authorization: _token ? `Bearer ${_token}` : undefined,
        },
      };
    });

    return forward(operation);
  });

  const timeoutLink = new ApolloLinkTimeout(90 * 1000); // 90 second timeout

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([concat(errorLink.concat(timeoutLink), authMiddleware).concat(createIsomorphLink())]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
}

export function initializeApollo(initialState: InitialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient(initialState);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = { ...initialState, ...existingCache };

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useGraphQLClient(initialState: InitialState) {
  return useMemo(() => initializeApollo(initialState), [initialState]);
}
