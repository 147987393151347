import axios from '@/shared/utils/axios';
import { isStaging } from '@/shared/utils/utils';

export function websiteLink() {
  if (isStaging) {
    return 'https://beta.pietrastudio.com';
  }
  return 'https://www.pietrastudio.com';
}

export function webToolsLink() {
  if (isStaging) {
    return 'https://creators-staging.pietrastudio.com';
  }
  return 'https://creators.pietrastudio.com';
}

export function supplierPortalLink() {
  if (isStaging) {
    return 'https://partner-staging.pietrastudio.com';
  } else {
    return 'https://partner.pietrastudio.com';
  }
}

export function marketplaceLink() {
  if (isStaging) {
    return 'https://staging.pietra.store';
  }
  return 'https://pietra.store';
}

export function creatorHubLink() {
  if (isStaging) {
    return 'https://creators-staging.pietrastudio.com';
  }
  return 'https://creators.pietrastudio.com';
}

export function getApiV2Host() {
  if (isStaging) {
    return 'https://apiv2-next.pietrastudio.com';
  }
  return 'https://apiv2.pietrastudio.com/';
}

export const proxyDownload = (url) => {
  return axios.get('commons/util/proxy-download?url=' + encodeURIComponent(url));
};

export const submitAppSearchClick = (engineName, query, documentId, requestId) => {
  axios
    .put(`commons/app-search/engines/${engineName}/click`, {
      query,
      documentId,
      requestId,
    })
    .catch((e) => console.warn('submitAppSearchClick', e.message));
};
