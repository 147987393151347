import axios from 'axios';
import { getApiV2Host } from '@/lib/service/utils';

export const hostname_v2 = getApiV2Host();

const instance = axios.create({
  baseURL: hostname_v2,
  timeout: 120000,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;
