import { CreateStoreAccountParam } from '@/shared/types/api/store';
import axios, { axiosWithoutAuth } from '@/shared/utils/axios';
import { Store } from '@/shared/types/store';
import RequestV2 from './RequestV2';

export const registerStore = async (params: CreateStoreAccountParam) => {
  const { data } = await axios.post('/register?userType=STORE', params);
  return data;
};

export async function loginWithEmail(params: { [key: string]: string }) {
  const response = await axios.post('/login', { ...params });
  return response.data;
}

export async function getStoreDetailsWithHandle(handle: string, params: { [key: string]: boolean }) {
  const response = await axios.get<Store>('/app/stores/action/handle/' + handle, { params });
  return response.data;
}

export async function getStoreDetails(storeId: string, params: { [key: string]: boolean }) {
  const response = await axios.get<Store>('/app/stores/' + storeId, { params });
  return response.data;
}

export const sendNotificationEmail = (
  fromAddress: string,
  toAddresses: string[],
  bccAddresses: string[],
  subject: string,
  body: string,
) => {
  return axiosWithoutAuth.post('/commons/notify/send-notification-email-in-safe-mode', {
    fromAddress: fromAddress,
    toAddresses: toAddresses,
    bccAddresses: bccAddresses,
    subject: subject,
    body: body,
  });
};

export const sendEmailToCreator = (platform: string) => (email: string) => {
  return RequestV2.post('/wait-list/-/send-email-to-creator', {
    email,
    platform,
  });
};
