interface StoredData<T> {
  value: T;
  expiry: number | null;
}

export enum StorageTypeProps {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage',
  Memory = 'memory',
}

export class StorageService {
  private storage: Storage;

  constructor(storageType: StorageTypeProps) {
    if (typeof window !== 'undefined') {
      this.storage =
        storageType === StorageTypeProps.Memory ? Object.create(null) : window[storageType] || Object.create(null);
    } else {
      this.storage = Object.create(null);
    }
  }

  set<T>(key: string, value: T, ttl: number | null = null): void {
    const data: StoredData<T> = {
      value,
      expiry: ttl ? new Date().getTime() + ttl : null,
    };
    this.storage[key] = JSON.stringify(data);
  }

  get<T>(key: string): T | null {
    const item = this.storage[key];
    if (!item) {
      return null;
    }
    const data: StoredData<T> = JSON.parse(item);
    if (!data.expiry || data.expiry > new Date().getTime()) {
      return data.value;
    }
    this.del(key);
    return null;
  }

  del(key: string): void {
    delete this.storage[key];
  }
}
