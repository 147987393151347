import { StorageService, StorageTypeProps } from '@/lib/service/storage/classes/StorageService.class';

const storageService = (storageType: StorageTypeProps, key: string) => {
  const storage = new StorageService(storageType);
  const getStorage = () => storage.get(key);
  const setStorage = (data: any, editPaths?: string[][]) => {
    if (editPaths === void 0) return storage.set(key, data);
    const sourceData = storage.get(key);
    const n = editPaths.length;
    for (let i = 0; i < n; i++) {
      const editPath = editPaths[i];
      const m = editPath.length;
      let sourceCurrent = sourceData,
        current = data;
      for (let j = 0; j < m - 1; j++) {
        sourceCurrent = sourceCurrent[editPath[j]];
        current = current[editPath[j]];
      }
      sourceCurrent[editPath[m - 1]] = current[editPath[m - 1]];
    }
    return storage.set(key, sourceData);
  };
  return { getStorage, setStorage };
};

export const sessionStorageService = (key: string) => {
  return storageService(StorageTypeProps.SessionStorage, key);
};

export const localStorageService = (key: string) => {
  return storageService(StorageTypeProps.LocalStorage, key);
};

export const memoryStorageService = (key: string) => {
  return storageService(StorageTypeProps.Memory, key);
};

export default storageService;
