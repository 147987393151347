import axios from '@/shared/utils/axios';
import { BookDemo } from '@/shared/types/store';

export async function getEmailCaptcha(params: { [key: string]: string }) {
  const response = await axios.get('/email-captcha', { params });
  return response.data;
}

export async function resetPassword(data: {
  username: string;
  resetCode: string;
  newPassword: string;
  userType: string;
}) {
  const response = await axios.put('/reset-password', { ...data });
  return response.data;
}

export async function storeLogin(params: { [key: string]: string }) {
  const response = await axios.post('/login', { ...params });
  return response.data;
}

export async function businessSettingsDisplay<T = any>(key: string) {
  const { data } = await axios.get<{ value: T }>(`/commons/business-settings/DISPLAY/${key}`);
  return data;
}

export async function businessSettingsNone<T = any>(key: string) {
  const { data } = await axios.get<{ value: T }>(`/commons/business-settings/NONE/${key}`);
  return data;
}

export async function appBookDemos(params: { [key: string]: string }) {
  const { data } = await axios.post<BookDemo>(`/app/book-demos`, { ...params });
  return data;
}

export async function appCalendarAssigns(params: { bookDemoId: number; name: string; url: string }) {
  const { data } = await axios.post(`/app/calendar-assigns`, { ...params });
  return data;
}
