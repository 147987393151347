import { useIssueAnonymousTokenMutation } from '@/lib/service/pietra-v2';
import { setAnonymousUser } from '@/shared/utils/utils';
import { ANONYMOUS_LOGIN_TOKEN } from '@/shared/constants';
import { useApolloClient } from '@apollo/client';
import { sessionStorageService } from '../service/storage';
import debounce from 'lodash/debounce';

const { getStorage, setStorage } = sessionStorageService(ANONYMOUS_LOGIN_TOKEN);

const useIssueAnonymousToken = () => {
  const client = useApolloClient();
  const [issueAnonymousTokenMutation] = useIssueAnonymousTokenMutation();

  const issueAnonymousToken = debounce(async () => {
    if (!getStorage()) {
      const data = await issueAnonymousTokenMutation({
        variables: { anonymousUserId: setAnonymousUser() },
      });
      if (data.data.issueAnonymousToken.token) {
        setStorage(data.data.issueAnonymousToken.token);
        await client.resetStore();
        return;
      }
    }
  }, 500);

  return {
    issueAnonymousToken,
  };
};

export default useIssueAnonymousToken;
