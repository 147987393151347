import axios from '@/shared/utils/axios';
import { getUUID } from '../storage/uuid';
import { GenerateGptImagesResponse, AiProductProps } from '@/shared/types/ai-tools';

const BASE_DIR = '/app/';

export const getAiIdeas = async (payload: any) => {
  const response = await axios.get<any>(BASE_DIR + 'ai-ideas/-/public', {
    params: payload,
    headers: {
      'X-Anonymous-User-Id': getUUID('X-Anonymous-User-Id'),
      'Ingnore-Authorization': true,
    },
  });
  return response.data;
};

export const generateGptImages = async (prompt: string): Promise<GenerateGptImagesResponse> => {
  const { data } = await axios.post(
    BASE_DIR + 'ai/gpt-images',
    { prompt },
    {
      headers: {
        'X-Anonymous-User-Id': getUUID('X-Anonymous-User-Id'),
        'Ingnore-Authorization': true,
      },
      timeout: 30000,
    },
  );
  return data === '' ? null : data;
};

export const postAiProduct = async (payload: AiProductProps) => {
  const response = await axios.post<any>(BASE_DIR + 'ai-products', payload, {
    headers: {
      'X-Anonymous-User-Id': getUUID('X-Anonymous-User-Id'),
      'Ingnore-Authorization': true,
    },
  });
  return response.data;
};

export const putAiProduct = async (id: number, payload: AiProductProps) => {
  const response = await axios.put<any>(BASE_DIR + 'ai-products/' + id, payload, {
    headers: {
      'X-Anonymous-User-Id': getUUID('X-Anonymous-User-Id'),
      'Ingnore-Authorization': true,
    },
  });
  return response.data;
};
