import {
  CatalogCategoryListParam,
  CatalogCompanyListParam,
  CatalogItemListParam,
  CountCatalogItemsInSearchParams,
  CountCatalogItemsInSearchResponse,
  ProductRecommendItemsToItemParam,
  ProductRecommendItemsToUserParam,
  ProductRecommendNextItemsParam,
  RecommendedProductResponse,
  RecommendedSupplierResponse,
  SearchAutoCompleteParams,
  SearchAutoCompleteResponse,
  SupplierRecommendItemsToUserParam,
  SupplierRecommendNextItemsParam,
  SupplierReviewsQueryParam,
  SupplierReviewSummaryResponse,
} from '@/shared/types/api/catalog';
import { CatalogCategory, CatalogCompany, CatalogItem, SupplierReview } from '@/shared/types/catalog';
import axios from '@/shared/utils/axios';

export async function getCatalogCompanyByHandle(handle: string) {
  const { data } = await axios.get<CatalogCompany>(`/app/catalog-companies/${handle}`);
  return data;
}

export async function getCatalogCompanies(params: CatalogCompanyListParam) {
  const { data } = await axios.get<ListResponse<CatalogCompany>>('/app/catalog-companies', { params });
  return data;
}

export async function getCatalogCompaniesByIds(ids: number[] | string[]) {
  const { data } = await axios.get<ListResponse<CatalogCompany>>(
    `/app/catalog-companies/${ids.join()}?alwaysList=true`,
  );
  return data;
}

export async function getCatalogItemByHandle(handle: string) {
  const { data } = await axios.get<CatalogItem>(`/app/catalog-items/${handle}`);
  return data;
}

export async function getCatalogItems(params: CatalogItemListParam) {
  const { data } = await axios.get<ListResponse<CatalogItem>>('/app/catalog-items', { params });
  return data;
}

export async function getCatalogCategories(params: CatalogCategoryListParam) {
  const { data } = await axios.get<ListResponse<CatalogCategory>>('/app/catalog-categories', { params });
  return data;
}

export async function productRecommendItemsToUser(params: ProductRecommendItemsToUserParam) {
  const { data } = await axios.get<RecommendedProductResponse>('/app/catalog-items/action/recommend-items-to-user', {
    params,
  });
  return data;
}

export async function productRecommendNextItems(params: ProductRecommendNextItemsParam) {
  const { data } = await axios.get<RecommendedProductResponse>('/app/catalog-items/action/recommend-next-items', {
    params,
  });
  return data;
}

export async function supplierRecommendItemsToUser(params: SupplierRecommendItemsToUserParam) {
  const { data } = await axios.get<RecommendedSupplierResponse>(
    '/app/catalog-companies/action/recommend-items-to-user',
    {
      params,
    },
  );
  return data;
}

export async function supplierRecommendNextItems(params: SupplierRecommendNextItemsParam) {
  const { data } = await axios.get<RecommendedSupplierResponse>('/app/catalog-companies/action/recommend-next-items', {
    params,
  });
  return data;
}

export async function productRecommendItemsToItem(catalogItemId: number, params: ProductRecommendItemsToItemParam) {
  const { data } = await axios.get<RecommendedProductResponse>(
    `/app/catalog-items/${catalogItemId}/recommend-items-to-item`,
    {
      params,
    },
  );
  return data;
}

export async function getCatalogCompanyReviews(params: SupplierReviewsQueryParam) {
  const { data } = await axios.get<ListResponse<SupplierReview>>('/app/catalog-company-reviews', { params });
  return data;
}

export async function getCatalogCompanyReviewSummary(supplierId: number, itemId?: number) {
  const params = {
    itemId,
  };
  const { data } = await axios.get<SupplierReviewSummaryResponse>(
    `/app/catalog-company-reviews/-/summary/${supplierId}`,
    { params },
  );
  return data;
}

export async function countCatalogItemsInSearch(params: CountCatalogItemsInSearchParams) {
  const { data } = await axios.get<CountCatalogItemsInSearchResponse>('/app/catalog-items/action/category-group', {
    params,
  });
  return data;
}

export async function getSearchAutoComplete(params: SearchAutoCompleteParams) {
  const { data } = await axios.get<SearchAutoCompleteResponse>('/app/catalog-items/action/suggest-auto-complete', {
    params,
  });
  return data;
}
