import TagManager from 'react-gtm-module';
import axios from '@/shared/utils/axios';
import storage from '@/shared/utils/storage';
import { CommonEventDataType } from '@/shared/types/api/event-analyses';
import { EventName, EventSource } from '@/shared/constants/event';
import { ANONYMOUS_USER_ID } from '@/shared/constants';
import { setAnonymousUser } from '@/shared/utils/utils';
import { AxiosRequestConfig } from 'axios';

export const sendEventAnalyses = (data: CommonEventDataType, config?: AxiosRequestConfig) => {
  try {
    return axios.post('/commons/event-analyses/report', data, config);
  } catch (e) {
    return null;
  }
};

export const logUserRegister = (user: any, isMobile: boolean) => {
  TagManager.dataLayer({
    dataLayer: { event: 'userRegister', userId: user.userId, email: user.email, userType: user.userType },
  });
  sendEventAnalyses({
    eventName: EventName.USER_REGISTER,
    userId: user.userId,
    userType: (user.userType || '').toLowerCase(),
    sourceClient: isMobile ? 'mobile_web' : 'desktop_web',
  }).then();
};

export const logCommonViewAnonymous = (type: EventSource, content: string | number, isMobile?: boolean) => {
  logCommonView(type, content, isMobile, {
    headers: {
      'Ingnore-Authorization': true,
    },
  });
};

export const logCommonView = (
  type: EventSource,
  content: string | number,
  isMobile?: boolean,
  config?: AxiosRequestConfig,
) => {
  if (
    type == EventSource.AUDIENCE_CAPTURE_SIGNUP_FORM ||
    type == EventSource.EXISTING_BRANDS_GET_IN_TOUCH_FORM ||
    type == EventSource.EXISTING_BRANDS_SIGNUP_FORM
  ) {
    TagManager.dataLayer({
      dataLayer: { event: 'commonView', commonType: type, commonContent: content },
    });
  }
  sendEventAnalyses(
    {
      eventName: EventName.COMMON_VIEW,
      commonType: type,
      commonContent: content,
      sourceClient: isMobile ? 'mobile_web' : 'desktop_web',
      anonymousUserId: storage.get(ANONYMOUS_USER_ID) ?? setAnonymousUser(),
    },
    config,
  );
};

export const logPageView = (params: { pageUrl: string; routeName: string; pageTitle: string }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'nextPageView',
      pageType: 'PageView',
      anonymousUserId: storage.get(ANONYMOUS_USER_ID) ?? setAnonymousUser(),
      ...params,
    },
  });
};
