import { UploadResponseProps } from '@/shared/types/business-kits/api/common/upload';
import { request } from '.';
import {
  EventAnalysesReportRequestProps,
  EventAnalysesReportResponseProps,
} from '@/shared/types/business-kits/api/common/event-analyses/report';
import {
  UtilSendSlackMessageRequestProps,
  UtilSendSlackMessageResponseProps,
} from '@/shared/types/business-kits/api/common/util/send-slack-message';
import html2canvas from 'html2canvas';
import { getUUID } from '../storage/uuid';
import { StorageService, StorageTypeProps } from '../storage/classes/StorageService.class';
import jsPDF from 'jspdf';
import { AxiosRequestConfig } from 'axios';
import { merge } from 'lodash';

const BASE_DIR = '/commons/';

export const upload = async (file: any, axiosRequestConfig?: AxiosRequestConfig) => {
  const formData = new FormData();
  formData.append('file', file);

  const defaultConfig = {
    method: 'POST',
    url: '/upload',
    data: formData,
    headers: {
      'Content-Type': file.type,
      'X-Anonymous-User-Id': getUUID('X-Anonymous-User-Id'),
    },
  };

  const mergedConfig = merge(defaultConfig, axiosRequestConfig);

  return await request<UploadResponseProps>(mergedConfig);
};

export const eventAnalysesReport = async (payload: EventAnalysesReportRequestProps) => {
  return await request<EventAnalysesReportResponseProps>({
    method: 'POST',
    data: payload,
    url: BASE_DIR + 'event-analyses/report',
    headers: {
      'X-Anonymous-User-Id': getUUID('X-Anonymous-User-Id'),
    },
  });
};

export const utilSendSlackMessage = async (payload: UtilSendSlackMessageRequestProps) => {
  return await request<UtilSendSlackMessageResponseProps>({
    method: 'POST',
    data: payload,
    url: BASE_DIR + 'util/send-slack-message',
    headers: {
      'X-Anonymous-User-Id': getUUID('X-Anonymous-User-Id'),
    },
  });
};

export const capture = async (element: any): Promise<File> => {
  const canvas = await html2canvas(element, { useCORS: true });
  const blob = await new Promise((resolve) => canvas.toBlob(resolve));
  return new File([blob as BlobPart], 'image.png', { type: 'image/png' });
};

export const businessPDF = () => {
  const localStorage = new StorageService(StorageTypeProps.LocalStorage);
  const key = 'businessKitsPDF';
  return {
    get(): string {
      return localStorage.get(key);
    },
    set(path: string) {
      return localStorage.set(key, path);
    },
  };
};

export const getPDF = async (images: string[]): Promise<Blob> => {
  let doc = null;
  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    const img = new Image();
    img.src = image;
    img.crossOrigin = 'anonymous';

    await new Promise((resolve) => {
      img.onload = () => {
        const width = img.width * 0.264583;
        const height = img.height * 0.264583;
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imgData = canvas.toDataURL('image/jpeg', 0.75);
        if (i > 0) {
          doc.addPage([width, height], width > height ? 'landscape' : 'portrait');
        } else {
          doc = new jsPDF({
            orientation: width > height ? 'landscape' : 'portrait',
            unit: 'px',
            format: [width, height],
          });
        }
        doc.addImage(imgData, 'JPEG', 0, 0, width, height);
        resolve(null);
      };
    });
  }

  const blob = new Blob([doc.output('arraybuffer')], { type: 'application/pdf' });
  return new File([blob], 'pdf.pdf', { type: 'application/pdf' });
};
