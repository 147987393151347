import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Cursor for paging through collections */
  ConnectionCursor: { input: any; output: any };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: { input: any; output: any };
  /** Floats that will have a value greater than 0. */
  PositiveFloat: { input: any; output: any };
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: any; output: any };
  /** Represents NULL values */
  Void: { input: any; output: any };
};

export type AffiliateOffer = {
  __typename?: 'AffiliateOffer';
  /**
   * 100bp = 1%, if type = PERCENT_OF_SALE
   * 10000bp = $1, if type = FLAT_RATE_PER_ORDER
   */
  amountBp: Scalars['Int']['output'];
  autoApprove: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referralCount: Scalars['Int']['output'];
  revenueTotalBp: Scalars['Int']['output'];
  storeId: Scalars['ID']['output'];
  type: AffiliateOfferType;
  updatedAt: Scalars['DateTime']['output'];
};

export type AffiliateOfferConnection = {
  __typename?: 'AffiliateOfferConnection';
  /** Array of nodes. */
  nodes: Array<AffiliateOffer>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type AffiliateOfferCreateInput = {
  amountBp: Scalars['Int']['input'];
  autoApprove?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  storeId?: InputMaybe<Scalars['ID']['input']>;
  type: AffiliateOfferType;
};

export type AffiliateOfferFilter = {
  and?: InputMaybe<Array<AffiliateOfferFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isDefault?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AffiliateOfferFilter>>;
  storeId?: InputMaybe<IdFilterComparison>;
  type?: InputMaybe<AffiliateOfferTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type AffiliateOfferSort = {
  direction: SortDirection;
  field: AffiliateOfferSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AffiliateOfferSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsDefault = 'isDefault',
  Name = 'name',
  StoreId = 'storeId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

export enum AffiliateOfferType {
  FlatRatePerOrder = 'FLAT_RATE_PER_ORDER',
  PercentOfSale = 'PERCENT_OF_SALE',
}

export type AffiliateOfferTypeFilterComparison = {
  eq?: InputMaybe<AffiliateOfferType>;
  gt?: InputMaybe<AffiliateOfferType>;
  gte?: InputMaybe<AffiliateOfferType>;
  iLike?: InputMaybe<AffiliateOfferType>;
  in?: InputMaybe<Array<AffiliateOfferType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<AffiliateOfferType>;
  lt?: InputMaybe<AffiliateOfferType>;
  lte?: InputMaybe<AffiliateOfferType>;
  neq?: InputMaybe<AffiliateOfferType>;
  notILike?: InputMaybe<AffiliateOfferType>;
  notIn?: InputMaybe<Array<AffiliateOfferType>>;
  notLike?: InputMaybe<AffiliateOfferType>;
};

export type AffiliateOfferUpdateInput = {
  amountBp?: InputMaybe<Scalars['Int']['input']>;
  autoApprove?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AffiliateOfferType>;
};

export type AffiliateOrder = {
  __typename?: 'AffiliateOrder';
  commissionBp: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  itemCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  offer?: Maybe<AffiliateOffer>;
  offerAmountBp?: Maybe<Scalars['Int']['output']>;
  /** snapshot offer, calculations based on this */
  offerId?: Maybe<Scalars['ID']['output']>;
  offerType?: Maybe<AffiliateOfferType>;
  payoutId?: Maybe<Scalars['ID']['output']>;
  referral: AffiliateReferral;
  referralId: Scalars['ID']['output'];
  requestId: Scalars['ID']['output'];
  revenueBp: Scalars['Int']['output'];
  salesCreatorOrderId: Scalars['ID']['output'];
  status: AffiliateOrderStatus;
  store: Store;
  storeId: Scalars['ID']['output'];
  totalPriceBp: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AffiliateOrderConnection = {
  __typename?: 'AffiliateOrderConnection';
  /** Array of nodes. */
  nodes: Array<AffiliateOrder>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type AffiliateOrderFilter = {
  and?: InputMaybe<Array<AffiliateOrderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AffiliateOrderFilter>>;
  referral?: InputMaybe<AffiliateOrderFilterAffiliateReferralFilter>;
  referralId?: InputMaybe<IdFilterComparison>;
  salesCreatorOrderId?: InputMaybe<IdFilterComparison>;
  status?: InputMaybe<AffiliateOrderStatusFilterComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type AffiliateOrderFilterAffiliateReferralFilter = {
  and?: InputMaybe<Array<AffiliateOrderFilterAffiliateReferralFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  expiredAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  offerId?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<AffiliateOrderFilterAffiliateReferralFilter>>;
  paidAmountBp?: InputMaybe<NumberFieldComparison>;
  paymentType?: InputMaybe<AffiliateReferralPaymentTypeFilterComparison>;
  pendingAmountBp?: InputMaybe<NumberFieldComparison>;
  status?: InputMaybe<AffiliateReferralStatusFilterComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type AffiliateOrderPayoutInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  orderIds: Array<Scalars['ID']['input']>;
  referralId: Scalars['ID']['input'];
};

export type AffiliateOrderSort = {
  direction: SortDirection;
  field: AffiliateOrderSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AffiliateOrderSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  ReferralId = 'referralId',
  SalesCreatorOrderId = 'salesCreatorOrderId',
  Status = 'status',
  StoreId = 'storeId',
  UpdatedAt = 'updatedAt',
}

export enum AffiliateOrderStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Unqualified = 'UNQUALIFIED',
}

export type AffiliateOrderStatusFilterComparison = {
  eq?: InputMaybe<AffiliateOrderStatus>;
  gt?: InputMaybe<AffiliateOrderStatus>;
  gte?: InputMaybe<AffiliateOrderStatus>;
  iLike?: InputMaybe<AffiliateOrderStatus>;
  in?: InputMaybe<Array<AffiliateOrderStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<AffiliateOrderStatus>;
  lt?: InputMaybe<AffiliateOrderStatus>;
  lte?: InputMaybe<AffiliateOrderStatus>;
  neq?: InputMaybe<AffiliateOrderStatus>;
  notILike?: InputMaybe<AffiliateOrderStatus>;
  notIn?: InputMaybe<Array<AffiliateOrderStatus>>;
  notLike?: InputMaybe<AffiliateOrderStatus>;
};

export type AffiliatePayout = {
  __typename?: 'AffiliatePayout';
  amountBp: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  operator?: Maybe<PublicUserDto>;
  operatorId?: Maybe<Scalars['String']['output']>;
  orders: Array<AffiliateOrder>;
  referral: AffiliateReferral;
  referralId: Scalars['String']['output'];
  storeId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AffiliatePayoutOrdersArgs = {
  filter?: AffiliateOrderFilter;
  sorting?: Array<AffiliateOrderSort>;
};

export type AffiliatePayoutConnection = {
  __typename?: 'AffiliatePayoutConnection';
  /** Array of nodes. */
  nodes: Array<AffiliatePayout>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type AffiliatePayoutFilter = {
  amountBp?: InputMaybe<NumberFieldComparison>;
  and?: InputMaybe<Array<AffiliatePayoutFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  operatorId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AffiliatePayoutFilter>>;
  referralId?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type AffiliatePayoutSort = {
  direction: SortDirection;
  field: AffiliatePayoutSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AffiliatePayoutSortFields {
  AmountBp = 'amountBp',
  CreatedAt = 'createdAt',
  Id = 'id',
  OperatorId = 'operatorId',
  ReferralId = 'referralId',
  StoreId = 'storeId',
  UpdatedAt = 'updatedAt',
}

export type AffiliateReferral = {
  __typename?: 'AffiliateReferral';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  bankAccountName?: Maybe<Scalars['String']['output']>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  bankAccountType?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  bankRoutingNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  /** The sum of approved orders commission amount, ignore paid or not */
  commissionBpTotal: Scalars['Int']['output'];
  contactName?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  /**
   * The commission amount that should be paid(approved and not paid)
   * @deprecated using pendingAmountBp instead
   */
  estimatedPendingAmountBp: Scalars['Int']['output'];
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  /** The commission amount that waiting to approve(pending and not paid) */
  inApprovalCommissionBp: Scalars['Int']['output'];
  latestPaidAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  offer: AffiliateOffer;
  offerId: Scalars['ID']['output'];
  orderCount: Scalars['Int']['output'];
  /** The amount already paid */
  paidAmountBp: Scalars['Int']['output'];
  paymentType: AffiliateReferralPaymentType;
  paypalEmail?: Maybe<Scalars['String']['output']>;
  /** The amount that should be paid(approved and not paid) */
  pendingAmountBp: Scalars['Int']['output'];
  pendingOrderIds?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Scalars['String']['output']>;
  /** The sum of approved orders revenue amount, ignore paid or not */
  revenueBpTotal: Scalars['Int']['output'];
  stateCode?: Maybe<Scalars['String']['output']>;
  status: AffiliateReferralStatus;
  store: Store;
  storeId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AffiliateReferralConnection = {
  __typename?: 'AffiliateReferralConnection';
  /** Array of nodes. */
  nodes: Array<AffiliateReferral>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type AffiliateReferralCreateInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  bankAccountName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankAccountType?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  bankRoutingNumber?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  offerId: Scalars['ID']['input'];
  password: Scalars['String']['input'];
  paymentType: AffiliateReferralPaymentType;
  paypalEmail?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type AffiliateReferralFilter = {
  and?: InputMaybe<Array<AffiliateReferralFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  expiredAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  offerId?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<AffiliateReferralFilter>>;
  paidAmountBp?: InputMaybe<NumberFieldComparison>;
  paymentType?: InputMaybe<AffiliateReferralPaymentTypeFilterComparison>;
  pendingAmountBp?: InputMaybe<NumberFieldComparison>;
  status?: InputMaybe<AffiliateReferralStatusFilterComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export enum AffiliateReferralPaymentType {
  Bank = 'BANK',
  Paypal = 'PAYPAL',
}

export type AffiliateReferralPaymentTypeFilterComparison = {
  eq?: InputMaybe<AffiliateReferralPaymentType>;
  gt?: InputMaybe<AffiliateReferralPaymentType>;
  gte?: InputMaybe<AffiliateReferralPaymentType>;
  iLike?: InputMaybe<AffiliateReferralPaymentType>;
  in?: InputMaybe<Array<AffiliateReferralPaymentType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<AffiliateReferralPaymentType>;
  lt?: InputMaybe<AffiliateReferralPaymentType>;
  lte?: InputMaybe<AffiliateReferralPaymentType>;
  neq?: InputMaybe<AffiliateReferralPaymentType>;
  notILike?: InputMaybe<AffiliateReferralPaymentType>;
  notIn?: InputMaybe<Array<AffiliateReferralPaymentType>>;
  notLike?: InputMaybe<AffiliateReferralPaymentType>;
};

export type AffiliateReferralSort = {
  direction: SortDirection;
  field: AffiliateReferralSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AffiliateReferralSortFields {
  CreatedAt = 'createdAt',
  Email = 'email',
  ExpiredAt = 'expiredAt',
  Id = 'id',
  Name = 'name',
  OfferId = 'offerId',
  PaidAmountBp = 'paidAmountBp',
  PaymentType = 'paymentType',
  PendingAmountBp = 'pendingAmountBp',
  Status = 'status',
  StoreId = 'storeId',
  UpdatedAt = 'updatedAt',
}

export enum AffiliateReferralStatus {
  Accepted = 'ACCEPTED',
  Denied = 'DENIED',
  Pending = 'PENDING',
}

export type AffiliateReferralStatusFilterComparison = {
  eq?: InputMaybe<AffiliateReferralStatus>;
  gt?: InputMaybe<AffiliateReferralStatus>;
  gte?: InputMaybe<AffiliateReferralStatus>;
  iLike?: InputMaybe<AffiliateReferralStatus>;
  in?: InputMaybe<Array<AffiliateReferralStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<AffiliateReferralStatus>;
  lt?: InputMaybe<AffiliateReferralStatus>;
  lte?: InputMaybe<AffiliateReferralStatus>;
  neq?: InputMaybe<AffiliateReferralStatus>;
  notILike?: InputMaybe<AffiliateReferralStatus>;
  notIn?: InputMaybe<Array<AffiliateReferralStatus>>;
  notLike?: InputMaybe<AffiliateReferralStatus>;
};

export type AffiliateReferralUpdateInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  bankAccountName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankAccountType?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  bankRoutingNumber?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offerId?: InputMaybe<Scalars['ID']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<AffiliateReferralPaymentType>;
  paypalEmail?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AffiliateReferralStatus>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type AffiliateRegisterInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  offerId: Scalars['ID']['input'];
  password: Scalars['String']['input'];
  paypalEmail: Scalars['String']['input'];
};

export type AffiliateUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  paypalEmail?: InputMaybe<Scalars['String']['input']>;
};

export type AiDesignDescriptionDto = {
  __typename?: 'AiDesignDescriptionDto';
  description: Scalars['String']['output'];
};

export type AiDesignDescriptionInput = {
  title?: InputMaybe<Scalars['String']['input']>;
  uploadURL: Scalars['String']['input'];
};

export type AiDesignMarketingContentDto = {
  __typename?: 'AiDesignMarketingContentDto';
  manufacturingCost?: Maybe<Scalars['String']['output']>;
  manufacturingProcesses?: Maybe<Array<Scalars['String']['output']>>;
  materials?: Maybe<Array<Scalars['String']['output']>>;
};

export type AiDesignMarketingContentInput = {
  imageURL: Scalars['String']['input'];
  productMetric?: InputMaybe<Array<ProductMetricType>>;
};

export type AiDesignPictureDto = {
  __typename?: 'AiDesignPictureDto';
  url: Scalars['String']['output'];
};

export type AiDesignPictureInput = {
  categoryName?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  productName?: InputMaybe<Scalars['String']['input']>;
};

export type AiDesignProductSpecCostDto = {
  __typename?: 'AiDesignProductSpecCostDto';
  constructionSpecifications?: Maybe<Array<Scalars['String']['output']>>;
  designDescription?: Maybe<Array<Scalars['String']['output']>>;
  manufacturingCost?: Maybe<Scalars['String']['output']>;
  materialOptions?: Maybe<Array<Scalars['String']['output']>>;
  packagingDetails?: Maybe<Scalars['String']['output']>;
  pantoneColors?: Maybe<Array<PantoneColors>>;
};

export type AiDesignSearchDto = {
  __typename?: 'AiDesignSearchDto';
  product?: Maybe<Scalars['String']['output']>;
};

export type AiDesignSearchInput = {
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type AiDesignSketchDto = {
  __typename?: 'AiDesignSketchDto';
  error?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type AiDesignSketchInput = {
  /** user prompt */
  description: Scalars['String']['input'];
  /** sketch image base64 string */
  image: Scalars['String']['input'];
};

export type AiDesignTextDto = {
  __typename?: 'AiDesignTextDto';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AiDesignTextInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  itemURL?: InputMaybe<Array<Scalars['String']['input']>>;
  number?: InputMaybe<Scalars['Int']['input']>;
  titles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AiLabAnimateDiffInput = {
  guidanceScale?: Scalars['Float']['input'];
  motionModule?: AnimateDiffMotionModule;
  nPrompt: Scalars['String']['input'];
  path?: AnimateDiffPath;
  prompt: Scalars['String']['input'];
  steps?: Scalars['Int']['input'];
};

export type AiLabColorChangeInput = {
  color: Scalars['String']['input'];
  denoise: Scalars['Float']['input'];
  desc: Scalars['String']['input'];
  imgUrl: Scalars['String']['input'];
  threshold: Scalars['Float']['input'];
};

export type AiLabCommonDto = {
  __typename?: 'AiLabCommonDto';
  content: Scalars['String']['output'];
};

export type AiLabFabricPattern1Input = {
  desc1: Scalars['String']['input'];
  desc2: Scalars['String']['input'];
  desc3: Scalars['String']['input'];
  imgUrl: Scalars['String']['input'];
};

export type AiLabFabricPattern2Input = {
  desc: Scalars['String']['input'];
  imgUrl: Scalars['String']['input'];
  patternImgUrl: Scalars['String']['input'];
};

export type AiLabFabricPatternInput = {
  desc: Scalars['String']['input'];
  imgUrl: Scalars['String']['input'];
  isAutoGeneration: Scalars['Boolean']['input'];
};

export type AiLabReplaceBackgroudInput = {
  describeBackground: Scalars['String']['input'];
  describeObject: Scalars['String']['input'];
  imgUrl?: InputMaybe<Scalars['String']['input']>;
};

export type AiPlaygroundChatDto = {
  __typename?: 'AiPlaygroundChatDto';
  content: Scalars['String']['output'];
};

export type AiPlaygroundChatInput = {
  model: Scalars['String']['input'];
  systemPrompt?: InputMaybe<Scalars['String']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  userPrompt: Scalars['String']['input'];
};

export type AiPlaygroundImageDto = {
  __typename?: 'AiPlaygroundImageDto';
  revised_prompt: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AiPlaygroundImageInput = {
  userPrompt: Scalars['String']['input'];
};

export type AiPlaygroundImageSearchDto = {
  __typename?: 'AiPlaygroundImageSearchDto';
  matches: Array<MatchedResult>;
};

export type AiPlaygroundImageSearchInput = {
  imageUrl: Scalars['String']['input'];
  topK: Scalars['Int']['input'];
};

export type AiPlaygroundOutfitDto = {
  __typename?: 'AiPlaygroundOutfitDto';
  images: Array<Scalars['String']['output']>;
};

export type AiPlaygroundOutfitInput = {
  /** category: 0 (upper_body), 1 (lower_body), 2(cress) */
  category: Scalars['Int']['input'];
  /** cloth image base64 string */
  cloth_img: Scalars['String']['input'];
  /** model image base64 string */
  model_img: Scalars['String']['input'];
  /** the type of ML model, dc or hd */
  model_type: Scalars['String']['input'];
  /** num steps */
  sample?: InputMaybe<Scalars['Int']['input']>;
  /** Guidance scale */
  scale?: InputMaybe<Scalars['Float']['input']>;
  /** If the seed is set to -1, it will randomize the seed. */
  seed?: InputMaybe<Scalars['Int']['input']>;
  /** num steps */
  steps?: InputMaybe<Scalars['Int']['input']>;
};

export type AiPlaygroundSketchDto = {
  __typename?: 'AiPlaygroundSketchDto';
  base64: Scalars['String']['output'];
};

export type AiPlaygroundSketchInput = {
  /** adapter conditioning factor */
  adapter_conditioning_factor?: InputMaybe<Scalars['Float']['input']>;
  /** adapter conditioning scale */
  adapter_conditioning_scale?: InputMaybe<Scalars['Float']['input']>;
  /** guidance scale */
  guidance_scale?: InputMaybe<Scalars['Int']['input']>;
  /** sketch image base64 string */
  image: Scalars['String']['input'];
  /** negative prompt */
  negative_prompt?: InputMaybe<Scalars['String']['input']>;
  /** num steps */
  num_steps?: InputMaybe<Scalars['Int']['input']>;
  /** user prompt */
  prompt: Scalars['String']['input'];
  /** such as Photographic */
  randomize_seed?: InputMaybe<Scalars['Boolean']['input']>;
  /** seed number */
  seed: Scalars['Int']['input'];
  /** such as Photographic */
  style_name?: InputMaybe<Scalars['String']['input']>;
};

export type AiPlaygroundVisionDto = {
  __typename?: 'AiPlaygroundVisionDto';
  content: Scalars['String']['output'];
};

export type AiPlaygroundVisionInput = {
  imageUrls: Array<Scalars['String']['input']>;
  systemPrompt?: InputMaybe<Scalars['String']['input']>;
  userPrompt: Scalars['String']['input'];
};

export enum AnimateDiffMotionModule {
  MmSdV14 = 'mm_sd_v14',
  MmSdV15 = 'mm_sd_v15',
  MmSdV15V2 = 'mm_sd_v15_v2',
}

export enum AnimateDiffPath {
  LyrielV16 = 'lyriel_v16',
  MajicmixRealisticV5Preview = 'majicmixRealistic_v5Preview',
  RcnzCartoon3dV10 = 'rcnzCartoon3d_v10',
  RealisticVisionV40V20Novae = 'realisticVisionV40_v20Novae',
  ToonyouBeta3 = 'toonyou_beta3',
}

export type AssignPickerBatchInput = {
  orderIds: Array<Scalars['String']['input']>;
  pickerId: Scalars['String']['input'];
};

/** Sort the field of the Best selling item */
export enum BestSellingItemOrderField {
  /** GROSS ITEMS SOLD */
  ItemSold = 'itemSold',
  /** GROSS REVENUE */
  Revenue = 'revenue',
}

/** period of stat */
export enum BiDateType {
  /** example 2023-02-02 */
  Day = 'DAY',
  /** example 20xx-0x-01 */
  Month = 'MONTH',
  /** example 20xx-01-01, 20xx--01, 20xx-0-01 20xx-10-01 */
  Quarter = 'QUARTER',
  /** example 2023-02-02  */
  Week = 'WEEK',
  /** example 2023-01-01 */
  Year = 'YEAR',
}

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BundleCreateUpdateInput = {
  childSkus: Array<SkuItemData>;
  parentSku: Scalars['String']['input'];
};

export type ClipdropCleanupDto = {
  __typename?: 'ClipdropCleanupDto';
  imageBase64: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ClipdropCleanupInput = {
  imageBase64?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  maskBase64?: InputMaybe<Scalars['String']['input']>;
  maskUrl?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  testApiKey?: InputMaybe<Scalars['String']['input']>;
};

export type Company = {
  __typename?: 'Company';
  blueprintSubscriptionCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  blueprintSubscriptionId?: Maybe<Scalars['String']['output']>;
  blueprintSubscriptionPeriodEndAt?: Maybe<Scalars['DateTime']['output']>;
  blueprintSubscriptionPeriodStartAt?: Maybe<Scalars['DateTime']['output']>;
  blueprintSubscriptionStatus?: Maybe<StripeSubscriptionStatus>;
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  modules?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  shopifyAppModules?: Maybe<Array<Scalars['String']['output']>>;
  stripeModules?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
  /** Company Owner ID */
  userId: Scalars['ID']['output'];
  v1Id?: Maybe<Scalars['Int']['output']>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** Array of nodes. */
  nodes: Array<Company>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CompanyCreateInput = {
  name: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type CompanyDeleteResponse = {
  __typename?: 'CompanyDeleteResponse';
  blueprintSubscriptionCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  blueprintSubscriptionId?: Maybe<Scalars['String']['output']>;
  blueprintSubscriptionPeriodEndAt?: Maybe<Scalars['DateTime']['output']>;
  blueprintSubscriptionPeriodStartAt?: Maybe<Scalars['DateTime']['output']>;
  blueprintSubscriptionStatus?: Maybe<StripeSubscriptionStatus>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  modules?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  shopifyAppModules?: Maybe<Array<Scalars['String']['output']>>;
  stripeModules?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  v1Id?: Maybe<Scalars['Int']['output']>;
};

export type CompanyFilter = {
  and?: InputMaybe<Array<CompanyFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CompanyFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  userId?: InputMaybe<IdFilterComparison>;
  v1Id?: InputMaybe<NumberFieldComparison>;
};

export type CompanyModuleItem = {
  __typename?: 'CompanyModuleItem';
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  isBundle: Scalars['Boolean']['output'];
  isDraft: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  /** When `isBundle` is true, it contains the names from which `isBundle` is false items. */
  modules?: Maybe<Array<Scalars['String']['output']>>;
  /** unique name */
  name: Scalars['String']['output'];
  prices: Array<CompanyModuleItemPrice>;
  stripeId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  trialDays?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyModuleItemPricesArgs = {
  filter?: CompanyModuleItemPriceFilter;
  sorting?: Array<CompanyModuleItemPriceSort>;
};

export type CompanyModuleItemConnection = {
  __typename?: 'CompanyModuleItemConnection';
  /** Array of nodes. */
  nodes: Array<CompanyModuleItem>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CompanyModuleItemCreateInput = {
  isBundle?: Scalars['Boolean']['input'];
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  modules?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  /** left blank will create new */
  stripeId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  trialDays?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyModuleItemFilter = {
  and?: InputMaybe<Array<CompanyModuleItemFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isBundle?: InputMaybe<BooleanFieldComparison>;
  isDraft?: InputMaybe<BooleanFieldComparison>;
  isEnabled?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CompanyModuleItemFilter>>;
  prices?: InputMaybe<CompanyModuleItemFilterCompanyModuleItemPriceFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CompanyModuleItemFilterCompanyModuleItemPriceFilter = {
  and?: InputMaybe<Array<CompanyModuleItemFilterCompanyModuleItemPriceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isEnabled?: InputMaybe<BooleanFieldComparison>;
  itemId?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<CompanyModuleItemFilterCompanyModuleItemPriceFilter>>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CompanyModuleItemPrice = {
  __typename?: 'CompanyModuleItemPrice';
  createdAt: Scalars['DateTime']['output'];
  crossedPriceBp?: Maybe<Scalars['Int']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  itemId: Scalars['ID']['output'];
  priceBp: Scalars['Int']['output'];
  /** @deprecated unused */
  recurringAggregateUsage?: Maybe<StripePriceRecurringAggregateUsage>;
  recurringInterval: StripePriceRecurringInterval;
  recurringIntervalCount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated unused */
  recurringUsageType?: Maybe<StripePriceRecurringUsageType>;
  /** @deprecated not implement */
  stripeId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyModuleItemPriceConnection = {
  __typename?: 'CompanyModuleItemPriceConnection';
  /** Array of nodes. */
  nodes: Array<CompanyModuleItemPrice>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CompanyModuleItemPriceCreateInput = {
  crossedPriceBp?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  itemId: Scalars['ID']['input'];
  priceBp: Scalars['Int']['input'];
  recurringAggregateUsage?: InputMaybe<StripePriceRecurringAggregateUsage>;
  recurringInterval: StripePriceRecurringInterval;
  recurringIntervalCount?: InputMaybe<Scalars['Int']['input']>;
  recurringUsageType?: InputMaybe<StripePriceRecurringUsageType>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CompanyModuleItemPriceDeleteResponse = {
  __typename?: 'CompanyModuleItemPriceDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crossedPriceBp?: Maybe<Scalars['Int']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  itemId?: Maybe<Scalars['ID']['output']>;
  priceBp?: Maybe<Scalars['Int']['output']>;
  /** @deprecated unused */
  recurringAggregateUsage?: Maybe<StripePriceRecurringAggregateUsage>;
  recurringInterval?: Maybe<StripePriceRecurringInterval>;
  recurringIntervalCount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated unused */
  recurringUsageType?: Maybe<StripePriceRecurringUsageType>;
  /** @deprecated not implement */
  stripeId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyModuleItemPriceFilter = {
  and?: InputMaybe<Array<CompanyModuleItemPriceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isEnabled?: InputMaybe<BooleanFieldComparison>;
  itemId?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<CompanyModuleItemPriceFilter>>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CompanyModuleItemPriceSort = {
  direction: SortDirection;
  field: CompanyModuleItemPriceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanyModuleItemPriceSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsEnabled = 'isEnabled',
  ItemId = 'itemId',
  Title = 'title',
  UpdatedAt = 'updatedAt',
}

export type CompanyModuleItemPriceUpdateInput = {
  crossedPriceBp?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  priceBp?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyModuleItemSort = {
  direction: SortDirection;
  field: CompanyModuleItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanyModuleItemSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsBundle = 'isBundle',
  IsDraft = 'isDraft',
  IsEnabled = 'isEnabled',
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export type CompanyModuleItemUpdateInput = {
  isBundle?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  modules?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** left blank will create new */
  stripeId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  trialDays?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanySort = {
  direction: SortDirection;
  field: CompanySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanySortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  V1Id = 'v1Id',
}

export type CompanyUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateManyWarehouseLotCodesInput = {
  /** Array of records to create */
  warehouseLotCodes: Array<WarehouseLotCreateBatch>;
};

export type CreateManyWarehouseRulesInput = {
  /** Array of records to create */
  warehouseRules: Array<CreateWarehouseRuleInputBatch>;
};

export type CreateOneAffiliateOfferInput = {
  /** The record to create */
  affiliateOffer: AffiliateOfferCreateInput;
};

export type CreateOneAffiliateReferralInput = {
  /** The record to create */
  affiliateReferral: AffiliateReferralCreateInput;
};

export type CreateOneCompanyInput = {
  /** The record to create */
  company: CompanyCreateInput;
};

export type CreateOneCompanyModuleItemPriceInput = {
  /** The record to create */
  companyModuleItemPrice: CompanyModuleItemPriceCreateInput;
};

export type CreateOneStoreInput = {
  /** The record to create */
  store: StoreCreateInput;
};

export type CreateOneStoreModuleItemPriceInput = {
  /** The record to create */
  storeModuleItemPrice: StoreModuleItemPriceCreateInput;
};

export type CreateOneStoreRewardProductInput = {
  /** The record to create */
  storeRewardProduct: StoreRewardProductCreateInput;
};

export type CreateOneStoreShopifyAppInfoInput = {
  /** The record to create */
  storeShopifyAppInfo: StoreShopifyAppInfoCreateInput;
};

export type CreateOneUserInput = {
  /** The record to create */
  user: UserCreateInput;
};

export type CreateOneWarehouseBoxInput = {
  /** The record to create */
  warehouseBox: WarehouseBoxCreateInput;
};

export type CreateOneWarehouseBundleInput = {
  /** The record to create */
  warehouseBundle: CreateWarehouseBundle;
};

export type CreateOneWarehouseLotCodeInput = {
  /** The record to create */
  warehouseLotCode: WarehouseLotCreateBatch;
};

export type CreateOneWarehouseOrderLineItemInput = {
  /** The record to create */
  warehouseOrderLineItem: CreateWarehouseOrderLineItem;
};

export type CreateOneWarehousePackageInput = {
  /** The record to create */
  warehousePackage: WarehousePackageCreateInput;
};

export type CreateOneWarehouseProductInput = {
  /** The record to create */
  warehouseProduct: WarehouseProductCreateInput;
};

export type CreateOneWarehouseProductSkuAliasInput = {
  /** The record to create */
  warehouseProductSkuAlias: CreateWarehouseProductSkuAlias;
};

export type CreateOneWarehouseRuleInput = {
  /** The record to create */
  warehouseRule: CreateWarehouseRuleInputBatch;
};

export type CreateWarehouseBundle = {
  createdAt: Scalars['DateTime']['input'];
  /** ID */
  id: Scalars['ID']['input'];
  quantity: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  updatedAt: Scalars['DateTime']['input'];
  warehouseLocation?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWarehouseOrderLineItem = {
  category?: InputMaybe<Scalars['String']['input']>;
  createdAt: Scalars['DateTime']['input'];
  customsPriceBp?: InputMaybe<Scalars['Int']['input']>;
  harmonizedCode?: InputMaybe<Scalars['String']['input']>;
  /** ID */
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
  originCountryCode?: InputMaybe<Scalars['String']['input']>;
  packedQuantity: Scalars['Int']['input'];
  packingStatus: WarehouseOrderItemPackingStatus;
  pickedQuantity: Scalars['Int']['input'];
  pickingStatus: WarehouseOrderItemPickingStatus;
  priceBp?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  referenceId?: InputMaybe<Scalars['String']['input']>;
  referenceType?: InputMaybe<ReferenceType>;
  updatedAt: Scalars['DateTime']['input'];
  warehouseLocation?: InputMaybe<Scalars['String']['input']>;
  /** lb */
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateWarehouseProductSkuAlias = {
  alias: Scalars['String']['input'];
  createdAt: Scalars['DateTime']['input'];
  /** ID */
  id: Scalars['ID']['input'];
  sku: Scalars['String']['input'];
  storeId: Scalars['ID']['input'];
  updatedAt: Scalars['DateTime']['input'];
};

export type CreateWarehouseRuleInputBatch = {
  rules: Array<WarehouseRuleInput>;
  v1StoreId: Scalars['Int']['input'];
};

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DeleteOneCompanyInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneCompanyModuleItemPriceInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneStoreInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneStoreModuleItemPriceInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneStoreRewardProductInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneStoreShopifyAppInfoInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneUserInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneWarehouseBoxInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneWarehouseBundleInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneWarehouseLotCodeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneWarehousePackageInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneWarehouseProductSkuAliasInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneWarehouseRuleInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteWarehouseRuleInput = {
  v2RuleId: Scalars['String']['input'];
};

export type EmailCustomer = {
  __typename?: 'EmailCustomer';
  age?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customerEmail: Scalars['String']['output'];
  eventTags?: Maybe<Array<Scalars['String']['output']>>;
  firstAccessed?: Maybe<Scalars['DateTime']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  lastAccessed?: Maybe<Scalars['DateTime']['output']>;
  lastEvent?: Maybe<EmailCustomerEventDto>;
  md5: Scalars['String']['output'];
  nonId: Scalars['String']['output'];
  sha2?: Maybe<Scalars['String']['output']>;
  source: EmailCustomerSource;
  storeId?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  verdict?: Maybe<Scalars['String']['output']>;
};

export type EmailCustomerConnection = {
  __typename?: 'EmailCustomerConnection';
  /** Array of nodes. */
  nodes: Array<EmailCustomer>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type EmailCustomerEventDto = {
  __typename?: 'EmailCustomerEventDto';
  latestVisitStart: Scalars['DateTime']['output'];
  productId: Scalars['String']['output'];
  productTitle?: Maybe<Scalars['String']['output']>;
  variantId: Scalars['String']['output'];
};

export type EmailCustomerEventTagsFilterComparison = {
  like?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailCustomerFilter = {
  age?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<EmailCustomerFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customerEmail?: InputMaybe<StringFieldComparison>;
  eventTags?: InputMaybe<EmailCustomerEventTagsFilterComparison>;
  gender?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<EmailCustomerFilter>>;
  source?: InputMaybe<EmailCustomerSourceFilterComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  tags?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type EmailCustomerSort = {
  direction: SortDirection;
  field: EmailCustomerSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EmailCustomerSortFields {
  Age = 'age',
  CreatedAt = 'createdAt',
  CustomerEmail = 'customerEmail',
  EventTags = 'eventTags',
  Gender = 'gender',
  Id = 'id',
  Source = 'source',
  StoreId = 'storeId',
  Tags = 'tags',
  UpdatedAt = 'updatedAt',
}

export enum EmailCustomerSource {
  GoogleTagManager = 'GOOGLE_TAG_MANAGER',
  Pietra = 'PIETRA',
  Shopify = 'SHOPIFY',
}

export type EmailCustomerSourceFilterComparison = {
  eq?: InputMaybe<EmailCustomerSource>;
  in?: InputMaybe<Array<EmailCustomerSource>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<EmailCustomerSource>;
  notIn?: InputMaybe<Array<EmailCustomerSource>>;
};

export enum EntityType {
  AffiliateReferral = 'AffiliateReferral',
  CompanyModuleItem = 'CompanyModuleItem',
  Store = 'Store',
  StoreModuleItem = 'StoreModuleItem',
  User = 'User',
  WarehouseBox = 'WarehouseBox',
}

export type FloatFieldComparison = {
  between?: InputMaybe<FloatFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<FloatFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatFieldComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

/** Display Fulfillment Metrics Data */
export type FulfillmentMetricsData = {
  __typename?: 'FulfillmentMetricsData';
  all: Scalars['Int']['output'];
  canceled?: Maybe<Scalars['Int']['output']>;
  day: Scalars['DateTime']['output'];
  delayed: Scalars['Int']['output'];
  domesticOrder?: Maybe<Scalars['Int']['output']>;
  domesticShippingFee?: Maybe<Scalars['Float']['output']>;
  fulfilled: Scalars['Int']['output'];
  fulfillmentOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  revenue: Scalars['Float']['output'];
  shippingFee: Scalars['Float']['output'];
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type FulfillmentMetricsDataAggregateFilter = {
  all?: InputMaybe<IntFieldComparison>;
  and?: InputMaybe<Array<FulfillmentMetricsDataAggregateFilter>>;
  canceled?: InputMaybe<IntFieldComparison>;
  day?: InputMaybe<FulfillmentMetricsDataDayFilterComparison>;
  delayed?: InputMaybe<IntFieldComparison>;
  domesticOrder?: InputMaybe<IntFieldComparison>;
  domesticShippingFee?: InputMaybe<FloatFieldComparison>;
  fulfilled?: InputMaybe<IntFieldComparison>;
  fulfillmentOrder?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FulfillmentMetricsDataAggregateFilter>>;
  revenue?: InputMaybe<FloatFieldComparison>;
  shippingFee?: InputMaybe<FloatFieldComparison>;
  storeId?: InputMaybe<FulfillmentMetricsDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentMetricsDataStoreV1IdFilterComparison>;
};

export type FulfillmentMetricsDataAggregateGroupBy = {
  __typename?: 'FulfillmentMetricsDataAggregateGroupBy';
  all?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['DateTime']['output']>;
  delayed?: Maybe<Scalars['Int']['output']>;
  domesticOrder?: Maybe<Scalars['Int']['output']>;
  domesticShippingFee?: Maybe<Scalars['Float']['output']>;
  fulfilled?: Maybe<Scalars['Int']['output']>;
  fulfillmentOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  shippingFee?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentMetricsDataAggregateGroupByDayArgs = {
  by?: GroupBy;
};

export type FulfillmentMetricsDataAggregateResponse = {
  __typename?: 'FulfillmentMetricsDataAggregateResponse';
  avg?: Maybe<FulfillmentMetricsDataAvgAggregate>;
  count?: Maybe<FulfillmentMetricsDataCountAggregate>;
  groupBy?: Maybe<FulfillmentMetricsDataAggregateGroupBy>;
  max?: Maybe<FulfillmentMetricsDataMaxAggregate>;
  min?: Maybe<FulfillmentMetricsDataMinAggregate>;
  sum?: Maybe<FulfillmentMetricsDataSumAggregate>;
};

export type FulfillmentMetricsDataAvgAggregate = {
  __typename?: 'FulfillmentMetricsDataAvgAggregate';
  all?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  delayed?: Maybe<Scalars['Float']['output']>;
  domesticOrder?: Maybe<Scalars['Float']['output']>;
  domesticShippingFee?: Maybe<Scalars['Float']['output']>;
  fulfilled?: Maybe<Scalars['Float']['output']>;
  fulfillmentOrder?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  shippingFee?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type FulfillmentMetricsDataCountAggregate = {
  __typename?: 'FulfillmentMetricsDataCountAggregate';
  all?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['Int']['output']>;
  delayed?: Maybe<Scalars['Int']['output']>;
  domesticOrder?: Maybe<Scalars['Int']['output']>;
  domesticShippingFee?: Maybe<Scalars['Int']['output']>;
  fulfilled?: Maybe<Scalars['Int']['output']>;
  fulfillmentOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  revenue?: Maybe<Scalars['Int']['output']>;
  shippingFee?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentMetricsDataDayFilterComparison = {
  between?: InputMaybe<FulfillmentMetricsDataDayFilterComparisonBetween>;
};

export type FulfillmentMetricsDataDayFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type FulfillmentMetricsDataFilter = {
  all?: InputMaybe<IntFieldComparison>;
  and?: InputMaybe<Array<FulfillmentMetricsDataFilter>>;
  canceled?: InputMaybe<IntFieldComparison>;
  day?: InputMaybe<FulfillmentMetricsDataDayFilterComparison>;
  delayed?: InputMaybe<IntFieldComparison>;
  domesticOrder?: InputMaybe<IntFieldComparison>;
  domesticShippingFee?: InputMaybe<FloatFieldComparison>;
  fulfilled?: InputMaybe<IntFieldComparison>;
  fulfillmentOrder?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FulfillmentMetricsDataFilter>>;
  revenue?: InputMaybe<FloatFieldComparison>;
  shippingFee?: InputMaybe<FloatFieldComparison>;
  storeId?: InputMaybe<FulfillmentMetricsDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentMetricsDataStoreV1IdFilterComparison>;
};

export type FulfillmentMetricsDataMaxAggregate = {
  __typename?: 'FulfillmentMetricsDataMaxAggregate';
  all?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['DateTime']['output']>;
  delayed?: Maybe<Scalars['Int']['output']>;
  domesticOrder?: Maybe<Scalars['Int']['output']>;
  domesticShippingFee?: Maybe<Scalars['Float']['output']>;
  fulfilled?: Maybe<Scalars['Int']['output']>;
  fulfillmentOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  shippingFee?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentMetricsDataMinAggregate = {
  __typename?: 'FulfillmentMetricsDataMinAggregate';
  all?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['DateTime']['output']>;
  delayed?: Maybe<Scalars['Int']['output']>;
  domesticOrder?: Maybe<Scalars['Int']['output']>;
  domesticShippingFee?: Maybe<Scalars['Float']['output']>;
  fulfilled?: Maybe<Scalars['Int']['output']>;
  fulfillmentOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  shippingFee?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentMetricsDataSort = {
  direction: SortDirection;
  field: FulfillmentMetricsDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FulfillmentMetricsDataSortFields {
  All = 'all',
  Canceled = 'canceled',
  Day = 'day',
  Delayed = 'delayed',
  DomesticOrder = 'domesticOrder',
  DomesticShippingFee = 'domesticShippingFee',
  Fulfilled = 'fulfilled',
  FulfillmentOrder = 'fulfillmentOrder',
  Id = 'id',
  Revenue = 'revenue',
  ShippingFee = 'shippingFee',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type FulfillmentMetricsDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type FulfillmentMetricsDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type FulfillmentMetricsDataSumAggregate = {
  __typename?: 'FulfillmentMetricsDataSumAggregate';
  all?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  delayed?: Maybe<Scalars['Float']['output']>;
  domesticOrder?: Maybe<Scalars['Float']['output']>;
  domesticShippingFee?: Maybe<Scalars['Float']['output']>;
  fulfilled?: Maybe<Scalars['Float']['output']>;
  fulfillmentOrder?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  shippingFee?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** Fulfillment out of stock Detail Data */
export type FulfillmentOosDetailData = {
  __typename?: 'FulfillmentOOSDetailData';
  id?: Maybe<Scalars['ID']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  productOOS: Scalars['Int']['output'];
  quantityOOS: Scalars['Int']['output'];
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type FulfillmentOosDetailDataAggregateFilter = {
  and?: InputMaybe<Array<FulfillmentOosDetailDataAggregateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FulfillmentOosDetailDataAggregateFilter>>;
  paidAt?: InputMaybe<FulfillmentOosDetailDataPaidAtFilterComparison>;
  productOOS?: InputMaybe<IntFieldComparison>;
  quantityOOS?: InputMaybe<IntFieldComparison>;
  storeId?: InputMaybe<FulfillmentOosDetailDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentOosDetailDataStoreV1IdFilterComparison>;
};

export type FulfillmentOosDetailDataAggregateGroupBy = {
  __typename?: 'FulfillmentOOSDetailDataAggregateGroupBy';
  id?: Maybe<Scalars['ID']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  productOOS?: Maybe<Scalars['Int']['output']>;
  quantityOOS?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentOosDetailDataAggregateGroupByPaidAtArgs = {
  by?: GroupBy;
};

export type FulfillmentOosDetailDataAggregateResponse = {
  __typename?: 'FulfillmentOOSDetailDataAggregateResponse';
  avg?: Maybe<FulfillmentOosDetailDataAvgAggregate>;
  count?: Maybe<FulfillmentOosDetailDataCountAggregate>;
  groupBy?: Maybe<FulfillmentOosDetailDataAggregateGroupBy>;
  max?: Maybe<FulfillmentOosDetailDataMaxAggregate>;
  min?: Maybe<FulfillmentOosDetailDataMinAggregate>;
  sum?: Maybe<FulfillmentOosDetailDataSumAggregate>;
};

export type FulfillmentOosDetailDataAvgAggregate = {
  __typename?: 'FulfillmentOOSDetailDataAvgAggregate';
  productOOS?: Maybe<Scalars['Float']['output']>;
  quantityOOS?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type FulfillmentOosDetailDataCountAggregate = {
  __typename?: 'FulfillmentOOSDetailDataCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  paidAt?: Maybe<Scalars['Int']['output']>;
  productOOS?: Maybe<Scalars['Int']['output']>;
  quantityOOS?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentOosDetailDataFilter = {
  and?: InputMaybe<Array<FulfillmentOosDetailDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FulfillmentOosDetailDataFilter>>;
  paidAt?: InputMaybe<FulfillmentOosDetailDataPaidAtFilterComparison>;
  productOOS?: InputMaybe<IntFieldComparison>;
  quantityOOS?: InputMaybe<IntFieldComparison>;
  storeId?: InputMaybe<FulfillmentOosDetailDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentOosDetailDataStoreV1IdFilterComparison>;
};

export type FulfillmentOosDetailDataMaxAggregate = {
  __typename?: 'FulfillmentOOSDetailDataMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  productOOS?: Maybe<Scalars['Int']['output']>;
  quantityOOS?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentOosDetailDataMinAggregate = {
  __typename?: 'FulfillmentOOSDetailDataMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  productOOS?: Maybe<Scalars['Int']['output']>;
  quantityOOS?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentOosDetailDataPaidAtFilterComparison = {
  between?: InputMaybe<FulfillmentOosDetailDataPaidAtFilterComparisonBetween>;
};

export type FulfillmentOosDetailDataPaidAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type FulfillmentOosDetailDataSort = {
  direction: SortDirection;
  field: FulfillmentOosDetailDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FulfillmentOosDetailDataSortFields {
  Id = 'id',
  PaidAt = 'paidAt',
  ProductOos = 'productOOS',
  QuantityOos = 'quantityOOS',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type FulfillmentOosDetailDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type FulfillmentOosDetailDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type FulfillmentOosDetailDataSumAggregate = {
  __typename?: 'FulfillmentOOSDetailDataSumAggregate';
  productOOS?: Maybe<Scalars['Float']['output']>;
  quantityOOS?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** Fulfillment OOS Exception Data */
export type FulfillmentOosExceptionData = {
  __typename?: 'FulfillmentOOSExceptionData';
  id?: Maybe<Scalars['ID']['output']>;
  inventoryName?: Maybe<Scalars['String']['output']>;
  itemsInException?: Maybe<Scalars['Int']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  totalOrderAffected?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentOosExceptionDataConnection = {
  __typename?: 'FulfillmentOOSExceptionDataConnection';
  /** Array of nodes. */
  nodes: Array<FulfillmentOosExceptionData>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type FulfillmentOosExceptionDataFilter = {
  and?: InputMaybe<Array<FulfillmentOosExceptionDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  inventoryName?: InputMaybe<StringFieldComparison>;
  itemsInException?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<FulfillmentOosExceptionDataFilter>>;
  sku?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<FulfillmentOosExceptionDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentOosExceptionDataStoreV1IdFilterComparison>;
  totalOrderAffected?: InputMaybe<NumberFieldComparison>;
};

export type FulfillmentOosExceptionDataSort = {
  direction: SortDirection;
  field: FulfillmentOosExceptionDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FulfillmentOosExceptionDataSortFields {
  Id = 'id',
  InventoryName = 'inventoryName',
  ItemsInException = 'itemsInException',
  Sku = 'sku',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
  TotalOrderAffected = 'totalOrderAffected',
}

export type FulfillmentOosExceptionDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type FulfillmentOosExceptionDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Fulfillment Order Except Summary Data */
export type FulfillmentOrderExceptSummaryData = {
  __typename?: 'FulfillmentOrderExceptSummaryData';
  allExceptionOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isOOSOrder?: Maybe<Scalars['Int']['output']>;
  otherExceptionOrder?: Maybe<Scalars['Int']['output']>;
  paidAt: Scalars['DateTime']['output'];
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type FulfillmentOrderExceptSummaryDataAggregateFilter = {
  allExceptionOrder?: InputMaybe<IntFieldComparison>;
  and?: InputMaybe<Array<FulfillmentOrderExceptSummaryDataAggregateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isOOSOrder?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<FulfillmentOrderExceptSummaryDataAggregateFilter>>;
  otherExceptionOrder?: InputMaybe<IntFieldComparison>;
  paidAt?: InputMaybe<FulfillmentOrderExceptSummaryDataPaidAtFilterComparison>;
  storeId?: InputMaybe<FulfillmentOrderExceptSummaryDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentOrderExceptSummaryDataStoreV1IdFilterComparison>;
};

export type FulfillmentOrderExceptSummaryDataAggregateGroupBy = {
  __typename?: 'FulfillmentOrderExceptSummaryDataAggregateGroupBy';
  allExceptionOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isOOSOrder?: Maybe<Scalars['Int']['output']>;
  otherExceptionOrder?: Maybe<Scalars['Int']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentOrderExceptSummaryDataAggregateGroupByPaidAtArgs = {
  by?: GroupBy;
};

export type FulfillmentOrderExceptSummaryDataAggregateResponse = {
  __typename?: 'FulfillmentOrderExceptSummaryDataAggregateResponse';
  avg?: Maybe<FulfillmentOrderExceptSummaryDataAvgAggregate>;
  count?: Maybe<FulfillmentOrderExceptSummaryDataCountAggregate>;
  groupBy?: Maybe<FulfillmentOrderExceptSummaryDataAggregateGroupBy>;
  max?: Maybe<FulfillmentOrderExceptSummaryDataMaxAggregate>;
  min?: Maybe<FulfillmentOrderExceptSummaryDataMinAggregate>;
  sum?: Maybe<FulfillmentOrderExceptSummaryDataSumAggregate>;
};

export type FulfillmentOrderExceptSummaryDataAvgAggregate = {
  __typename?: 'FulfillmentOrderExceptSummaryDataAvgAggregate';
  allExceptionOrder?: Maybe<Scalars['Float']['output']>;
  isOOSOrder?: Maybe<Scalars['Float']['output']>;
  otherExceptionOrder?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type FulfillmentOrderExceptSummaryDataCountAggregate = {
  __typename?: 'FulfillmentOrderExceptSummaryDataCountAggregate';
  allExceptionOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isOOSOrder?: Maybe<Scalars['Int']['output']>;
  otherExceptionOrder?: Maybe<Scalars['Int']['output']>;
  paidAt?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentOrderExceptSummaryDataFilter = {
  allExceptionOrder?: InputMaybe<IntFieldComparison>;
  and?: InputMaybe<Array<FulfillmentOrderExceptSummaryDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isOOSOrder?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<FulfillmentOrderExceptSummaryDataFilter>>;
  otherExceptionOrder?: InputMaybe<IntFieldComparison>;
  paidAt?: InputMaybe<FulfillmentOrderExceptSummaryDataPaidAtFilterComparison>;
  storeId?: InputMaybe<FulfillmentOrderExceptSummaryDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentOrderExceptSummaryDataStoreV1IdFilterComparison>;
};

export type FulfillmentOrderExceptSummaryDataMaxAggregate = {
  __typename?: 'FulfillmentOrderExceptSummaryDataMaxAggregate';
  allExceptionOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isOOSOrder?: Maybe<Scalars['Int']['output']>;
  otherExceptionOrder?: Maybe<Scalars['Int']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentOrderExceptSummaryDataMinAggregate = {
  __typename?: 'FulfillmentOrderExceptSummaryDataMinAggregate';
  allExceptionOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isOOSOrder?: Maybe<Scalars['Int']['output']>;
  otherExceptionOrder?: Maybe<Scalars['Int']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentOrderExceptSummaryDataPaidAtFilterComparison = {
  between?: InputMaybe<FulfillmentOrderExceptSummaryDataPaidAtFilterComparisonBetween>;
};

export type FulfillmentOrderExceptSummaryDataPaidAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type FulfillmentOrderExceptSummaryDataSort = {
  direction: SortDirection;
  field: FulfillmentOrderExceptSummaryDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FulfillmentOrderExceptSummaryDataSortFields {
  AllExceptionOrder = 'allExceptionOrder',
  Id = 'id',
  IsOosOrder = 'isOOSOrder',
  OtherExceptionOrder = 'otherExceptionOrder',
  PaidAt = 'paidAt',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type FulfillmentOrderExceptSummaryDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type FulfillmentOrderExceptSummaryDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type FulfillmentOrderExceptSummaryDataSumAggregate = {
  __typename?: 'FulfillmentOrderExceptSummaryDataSumAggregate';
  allExceptionOrder?: Maybe<Scalars['Float']['output']>;
  isOOSOrder?: Maybe<Scalars['Float']['output']>;
  otherExceptionOrder?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** Fulfillment OrderSnapshot */
export type FulfillmentOrderSnapshotData = {
  __typename?: 'FulfillmentOrderSnapshotData';
  activeOrder?: Maybe<Scalars['Int']['output']>;
  cancelOrder?: Maybe<Scalars['Int']['output']>;
  delayedOrder?: Maybe<Scalars['Int']['output']>;
  fulfilledOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  onholdOrder?: Maybe<Scalars['Int']['output']>;
  orderToday?: Maybe<Scalars['Int']['output']>;
  outstandingOrder?: Maybe<Scalars['Int']['output']>;
  processingOrder?: Maybe<Scalars['Int']['output']>;
  restockOrder?: Maybe<Scalars['Int']['output']>;
  shipedToday?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FulfillmentOrderSnapshotDataFilter = {
  and?: InputMaybe<Array<FulfillmentOrderSnapshotDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FulfillmentOrderSnapshotDataFilter>>;
  storeId?: InputMaybe<FulfillmentOrderSnapshotDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentOrderSnapshotDataStoreV1IdFilterComparison>;
};

export type FulfillmentOrderSnapshotDataSort = {
  direction: SortDirection;
  field: FulfillmentOrderSnapshotDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FulfillmentOrderSnapshotDataSortFields {
  Id = 'id',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type FulfillmentOrderSnapshotDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type FulfillmentOrderSnapshotDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Display Fulfillment Transit Time Data */
export type FulfillmentTransitTime2iData = {
  __typename?: 'FulfillmentTransitTime2iData';
  all: Scalars['Int']['output'];
  day: Scalars['DateTime']['output'];
  fromA: Scalars['Int']['output'];
  fromB: Scalars['Int']['output'];
  fromC: Scalars['Int']['output'];
  fromD: Scalars['Int']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type FulfillmentTransitTime2iDataAggregateFilter = {
  all?: InputMaybe<IntFieldComparison>;
  and?: InputMaybe<Array<FulfillmentTransitTime2iDataAggregateFilter>>;
  day?: InputMaybe<FulfillmentTransitTime2iDataDayFilterComparison>;
  fromA?: InputMaybe<IntFieldComparison>;
  fromB?: InputMaybe<IntFieldComparison>;
  fromC?: InputMaybe<IntFieldComparison>;
  fromD?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FulfillmentTransitTime2iDataAggregateFilter>>;
  storeId?: InputMaybe<FulfillmentTransitTime2iDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentTransitTime2iDataStoreV1IdFilterComparison>;
};

export type FulfillmentTransitTime2iDataAggregateGroupBy = {
  __typename?: 'FulfillmentTransitTime2iDataAggregateGroupBy';
  all?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['DateTime']['output']>;
  fromA?: Maybe<Scalars['Int']['output']>;
  fromB?: Maybe<Scalars['Int']['output']>;
  fromC?: Maybe<Scalars['Int']['output']>;
  fromD?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentTransitTime2iDataAggregateGroupByDayArgs = {
  by?: GroupBy;
};

export type FulfillmentTransitTime2iDataAggregateResponse = {
  __typename?: 'FulfillmentTransitTime2iDataAggregateResponse';
  avg?: Maybe<FulfillmentTransitTime2iDataAvgAggregate>;
  count?: Maybe<FulfillmentTransitTime2iDataCountAggregate>;
  groupBy?: Maybe<FulfillmentTransitTime2iDataAggregateGroupBy>;
  max?: Maybe<FulfillmentTransitTime2iDataMaxAggregate>;
  min?: Maybe<FulfillmentTransitTime2iDataMinAggregate>;
  sum?: Maybe<FulfillmentTransitTime2iDataSumAggregate>;
};

export type FulfillmentTransitTime2iDataAvgAggregate = {
  __typename?: 'FulfillmentTransitTime2iDataAvgAggregate';
  all?: Maybe<Scalars['Float']['output']>;
  fromA?: Maybe<Scalars['Float']['output']>;
  fromB?: Maybe<Scalars['Float']['output']>;
  fromC?: Maybe<Scalars['Float']['output']>;
  fromD?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type FulfillmentTransitTime2iDataCountAggregate = {
  __typename?: 'FulfillmentTransitTime2iDataCountAggregate';
  all?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['Int']['output']>;
  fromA?: Maybe<Scalars['Int']['output']>;
  fromB?: Maybe<Scalars['Int']['output']>;
  fromC?: Maybe<Scalars['Int']['output']>;
  fromD?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentTransitTime2iDataDayFilterComparison = {
  between?: InputMaybe<FulfillmentTransitTime2iDataDayFilterComparisonBetween>;
};

export type FulfillmentTransitTime2iDataDayFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type FulfillmentTransitTime2iDataFilter = {
  all?: InputMaybe<IntFieldComparison>;
  and?: InputMaybe<Array<FulfillmentTransitTime2iDataFilter>>;
  day?: InputMaybe<FulfillmentTransitTime2iDataDayFilterComparison>;
  fromA?: InputMaybe<IntFieldComparison>;
  fromB?: InputMaybe<IntFieldComparison>;
  fromC?: InputMaybe<IntFieldComparison>;
  fromD?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FulfillmentTransitTime2iDataFilter>>;
  storeId?: InputMaybe<FulfillmentTransitTime2iDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentTransitTime2iDataStoreV1IdFilterComparison>;
};

export type FulfillmentTransitTime2iDataMaxAggregate = {
  __typename?: 'FulfillmentTransitTime2iDataMaxAggregate';
  all?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['DateTime']['output']>;
  fromA?: Maybe<Scalars['Int']['output']>;
  fromB?: Maybe<Scalars['Int']['output']>;
  fromC?: Maybe<Scalars['Int']['output']>;
  fromD?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentTransitTime2iDataMinAggregate = {
  __typename?: 'FulfillmentTransitTime2iDataMinAggregate';
  all?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['DateTime']['output']>;
  fromA?: Maybe<Scalars['Int']['output']>;
  fromB?: Maybe<Scalars['Int']['output']>;
  fromC?: Maybe<Scalars['Int']['output']>;
  fromD?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentTransitTime2iDataSort = {
  direction: SortDirection;
  field: FulfillmentTransitTime2iDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FulfillmentTransitTime2iDataSortFields {
  All = 'all',
  Day = 'day',
  FromA = 'fromA',
  FromB = 'fromB',
  FromC = 'fromC',
  FromD = 'fromD',
  Id = 'id',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type FulfillmentTransitTime2iDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type FulfillmentTransitTime2iDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type FulfillmentTransitTime2iDataSumAggregate = {
  __typename?: 'FulfillmentTransitTime2iDataSumAggregate';
  all?: Maybe<Scalars['Float']['output']>;
  fromA?: Maybe<Scalars['Float']['output']>;
  fromB?: Maybe<Scalars['Float']['output']>;
  fromC?: Maybe<Scalars['Float']['output']>;
  fromD?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** Display Fulfillment Transit Time Data */
export type FulfillmentTransitTimeData = {
  __typename?: 'FulfillmentTransitTimeData';
  all: Scalars['Int']['output'];
  day: Scalars['DateTime']['output'];
  from1to2: Scalars['Int']['output'];
  from3to5: Scalars['Int']['output'];
  from6to8: Scalars['Int']['output'];
  from9to12: Scalars['Int']['output'];
  from13: Scalars['Int']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type FulfillmentTransitTimeDataAggregateFilter = {
  all?: InputMaybe<IntFieldComparison>;
  and?: InputMaybe<Array<FulfillmentTransitTimeDataAggregateFilter>>;
  day?: InputMaybe<FulfillmentTransitTimeDataDayFilterComparison>;
  from1to2?: InputMaybe<IntFieldComparison>;
  from3to5?: InputMaybe<IntFieldComparison>;
  from6to8?: InputMaybe<IntFieldComparison>;
  from9to12?: InputMaybe<IntFieldComparison>;
  from13?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FulfillmentTransitTimeDataAggregateFilter>>;
  storeId?: InputMaybe<FulfillmentTransitTimeDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentTransitTimeDataStoreV1IdFilterComparison>;
};

export type FulfillmentTransitTimeDataAggregateGroupBy = {
  __typename?: 'FulfillmentTransitTimeDataAggregateGroupBy';
  all?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['DateTime']['output']>;
  from1to2?: Maybe<Scalars['Int']['output']>;
  from3to5?: Maybe<Scalars['Int']['output']>;
  from6to8?: Maybe<Scalars['Int']['output']>;
  from9to12?: Maybe<Scalars['Int']['output']>;
  from13?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentTransitTimeDataAggregateGroupByDayArgs = {
  by?: GroupBy;
};

export type FulfillmentTransitTimeDataAggregateResponse = {
  __typename?: 'FulfillmentTransitTimeDataAggregateResponse';
  avg?: Maybe<FulfillmentTransitTimeDataAvgAggregate>;
  count?: Maybe<FulfillmentTransitTimeDataCountAggregate>;
  groupBy?: Maybe<FulfillmentTransitTimeDataAggregateGroupBy>;
  max?: Maybe<FulfillmentTransitTimeDataMaxAggregate>;
  min?: Maybe<FulfillmentTransitTimeDataMinAggregate>;
  sum?: Maybe<FulfillmentTransitTimeDataSumAggregate>;
};

export type FulfillmentTransitTimeDataAvgAggregate = {
  __typename?: 'FulfillmentTransitTimeDataAvgAggregate';
  all?: Maybe<Scalars['Float']['output']>;
  from1to2?: Maybe<Scalars['Float']['output']>;
  from3to5?: Maybe<Scalars['Float']['output']>;
  from6to8?: Maybe<Scalars['Float']['output']>;
  from9to12?: Maybe<Scalars['Float']['output']>;
  from13?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type FulfillmentTransitTimeDataCountAggregate = {
  __typename?: 'FulfillmentTransitTimeDataCountAggregate';
  all?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['Int']['output']>;
  from1to2?: Maybe<Scalars['Int']['output']>;
  from3to5?: Maybe<Scalars['Int']['output']>;
  from6to8?: Maybe<Scalars['Int']['output']>;
  from9to12?: Maybe<Scalars['Int']['output']>;
  from13?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentTransitTimeDataDayFilterComparison = {
  between?: InputMaybe<FulfillmentTransitTimeDataDayFilterComparisonBetween>;
};

export type FulfillmentTransitTimeDataDayFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type FulfillmentTransitTimeDataFilter = {
  all?: InputMaybe<IntFieldComparison>;
  and?: InputMaybe<Array<FulfillmentTransitTimeDataFilter>>;
  day?: InputMaybe<FulfillmentTransitTimeDataDayFilterComparison>;
  from1to2?: InputMaybe<IntFieldComparison>;
  from3to5?: InputMaybe<IntFieldComparison>;
  from6to8?: InputMaybe<IntFieldComparison>;
  from9to12?: InputMaybe<IntFieldComparison>;
  from13?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FulfillmentTransitTimeDataFilter>>;
  storeId?: InputMaybe<FulfillmentTransitTimeDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentTransitTimeDataStoreV1IdFilterComparison>;
};

export type FulfillmentTransitTimeDataMaxAggregate = {
  __typename?: 'FulfillmentTransitTimeDataMaxAggregate';
  all?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['DateTime']['output']>;
  from1to2?: Maybe<Scalars['Int']['output']>;
  from3to5?: Maybe<Scalars['Int']['output']>;
  from6to8?: Maybe<Scalars['Int']['output']>;
  from9to12?: Maybe<Scalars['Int']['output']>;
  from13?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentTransitTimeDataMinAggregate = {
  __typename?: 'FulfillmentTransitTimeDataMinAggregate';
  all?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['DateTime']['output']>;
  from1to2?: Maybe<Scalars['Int']['output']>;
  from3to5?: Maybe<Scalars['Int']['output']>;
  from6to8?: Maybe<Scalars['Int']['output']>;
  from9to12?: Maybe<Scalars['Int']['output']>;
  from13?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type FulfillmentTransitTimeDataSort = {
  direction: SortDirection;
  field: FulfillmentTransitTimeDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FulfillmentTransitTimeDataSortFields {
  All = 'all',
  Day = 'day',
  From1to2 = 'from1to2',
  From3to5 = 'from3to5',
  From6to8 = 'from6to8',
  From9to12 = 'from9to12',
  From13 = 'from13',
  Id = 'id',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type FulfillmentTransitTimeDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type FulfillmentTransitTimeDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type FulfillmentTransitTimeDataSumAggregate = {
  __typename?: 'FulfillmentTransitTimeDataSumAggregate';
  all?: Maybe<Scalars['Float']['output']>;
  from1to2?: Maybe<Scalars['Float']['output']>;
  from3to5?: Maybe<Scalars['Float']['output']>;
  from6to8?: Maybe<Scalars['Float']['output']>;
  from9to12?: Maybe<Scalars['Float']['output']>;
  from13?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** Fulfillment Turn Around Time Data */
export type FulfillmentTurnAroundTimeData = {
  __typename?: 'FulfillmentTurnAroundTimeData';
  daysLate?: Maybe<Scalars['Int']['output']>;
  fulfilledAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  slaStartAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type FulfillmentTurnAroundTimeDataConnection = {
  __typename?: 'FulfillmentTurnAroundTimeDataConnection';
  /** Array of nodes. */
  nodes: Array<FulfillmentTurnAroundTimeData>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type FulfillmentTurnAroundTimeDataFilter = {
  and?: InputMaybe<Array<FulfillmentTurnAroundTimeDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FulfillmentTurnAroundTimeDataFilter>>;
  paidAt?: InputMaybe<FulfillmentTurnAroundTimeDataPaidAtFilterComparison>;
  storeId?: InputMaybe<FulfillmentTurnAroundTimeDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentTurnAroundTimeDataStoreV1IdFilterComparison>;
};

export type FulfillmentTurnAroundTimeDataPaidAtFilterComparison = {
  between?: InputMaybe<FulfillmentTurnAroundTimeDataPaidAtFilterComparisonBetween>;
};

export type FulfillmentTurnAroundTimeDataPaidAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type FulfillmentTurnAroundTimeDataSort = {
  direction: SortDirection;
  field: FulfillmentTurnAroundTimeDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FulfillmentTurnAroundTimeDataSortFields {
  Id = 'id',
  PaidAt = 'paidAt',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type FulfillmentTurnAroundTimeDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type FulfillmentTurnAroundTimeDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Fulfillment Undelivered Shipment Data */
export type FulfillmentUndeliveredShipmentData = {
  __typename?: 'FulfillmentUndeliveredShipmentData';
  buyerEmail?: Maybe<Scalars['String']['output']>;
  carrier?: Maybe<Scalars['String']['output']>;
  carrierService?: Maybe<Scalars['String']['output']>;
  daysSinceShipped?: Maybe<Scalars['Int']['output']>;
  estTransitDays?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  shipDate?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  trackingId?: Maybe<Scalars['String']['output']>;
  zone?: Maybe<Scalars['String']['output']>;
};

export type FulfillmentUndeliveredShipmentDataConnection = {
  __typename?: 'FulfillmentUndeliveredShipmentDataConnection';
  /** Array of nodes. */
  nodes: Array<FulfillmentUndeliveredShipmentData>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type FulfillmentUndeliveredShipmentDataFilter = {
  and?: InputMaybe<Array<FulfillmentUndeliveredShipmentDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FulfillmentUndeliveredShipmentDataFilter>>;
  storeId?: InputMaybe<FulfillmentUndeliveredShipmentDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<FulfillmentUndeliveredShipmentDataStoreV1IdFilterComparison>;
};

export type FulfillmentUndeliveredShipmentDataSort = {
  direction: SortDirection;
  field: FulfillmentUndeliveredShipmentDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FulfillmentUndeliveredShipmentDataSortFields {
  Id = 'id',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type FulfillmentUndeliveredShipmentDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type FulfillmentUndeliveredShipmentDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Group by */
export enum GroupBy {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  iLike?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  neq?: InputMaybe<Scalars['ID']['input']>;
  notILike?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  notLike?: InputMaybe<Scalars['ID']['input']>;
};

export type IntFieldComparison = {
  between?: InputMaybe<IntFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  notBetween?: InputMaybe<IntFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int']['input'];
  upper: Scalars['Int']['input'];
};

export type IntegrationShopifyAppInfo = {
  __typename?: 'IntegrationShopifyAppInfo';
  appName: IntegrationShopifyAppName;
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  isActivated: Scalars['Boolean']['output'];
  shopName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type IntegrationShopifyAppInfoFilter = {
  and?: InputMaybe<Array<IntegrationShopifyAppInfoFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<IntegrationShopifyAppInfoFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type IntegrationShopifyAppInfoSort = {
  direction: SortDirection;
  field: IntegrationShopifyAppInfoSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum IntegrationShopifyAppInfoSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
}

export enum IntegrationShopifyAppName {
  EmailCapture = 'EMAIL_CAPTURE',
  ProductDesign = 'PRODUCT_DESIGN',
  Sourcing = 'SOURCING',
}

export type IntegrationStripeSubscriptionDto = {
  __typename?: 'IntegrationStripeSubscriptionDto';
  id: Scalars['ID']['output'];
  status: StripeSubscriptionStatus;
};

/** The status of the Klaviyo OAuth token */
export enum KlaviyoOauthStatus {
  Authorized = 'AUTHORIZED',
  Unauthorized = 'UNAUTHORIZED',
}

export type LoginDto = {
  __typename?: 'LoginDto';
  defaultCompanyToken?: Maybe<Scalars['JWT']['output']>;
  defaultStoreToken?: Maybe<Scalars['JWT']['output']>;
  token: Scalars['JWT']['output'];
};

/** long period of stat */
export enum LongDateType {
  /** example 20xx-0x-01 */
  Month = 'MONTH',
  /** example 20xx-01-01, 20xx--01, 20xx-0-01 20xx-10-01 */
  Quarter = 'QUARTER',
  /** example 2023-01-01 */
  Year = 'YEAR',
}

/** ltv type */
export enum LtvType {
  /** ltv 1 year */
  Ltv1y = 'ltv1y',
  /** ltv 2 year */
  Ltv2y = 'ltv2y',
  /** ltv 5 year  */
  Ltv5y = 'ltv5y',
  /** ltv 30 day  */
  Ltv30d = 'ltv30d',
  /** ltv 90 day */
  Ltv90d = 'ltv90d',
}

export type MatchedResult = {
  __typename?: 'MatchedResult';
  imageUrl?: Maybe<Scalars['String']['output']>;
  itemId?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** activate lot for picking */
  activateLot: WarehouseLotCode;
  addShopifyPullOrdersQueueJob: Scalars['URL']['output'];
  /** manual adjust warehouse product inventory */
  adjustWarehouseInventory: WarehouseInventory;
  aiLabAnimateDiff: AiLabCommonDto;
  aiLabColorChange: AiLabCommonDto;
  aiLabFabricPattern: AiLabCommonDto;
  aiLabFabricPattern1: AiLabCommonDto;
  aiLabFabricPattern2: AiLabCommonDto;
  aiLabReplaceBackgroud: AiLabCommonDto;
  aiPlaygroundChat: AiPlaygroundChatDto;
  aiPlaygroundImage: AiPlaygroundImageDto;
  aiPlaygroundImageSearchByOpenAI: AiPlaygroundImageSearchDto;
  aiPlaygroundImageSearchByResNet: AiPlaygroundImageSearchDto;
  aiPlaygroundOutfit: AiPlaygroundOutfitDto;
  aiPlaygroundSketch: AiPlaygroundSketchDto;
  aiPlaygroundVision: AiPlaygroundVisionDto;
  approveAffiliateOrder?: Maybe<Scalars['Void']['output']>;
  assignWarehouseOrderPicker: WarehouseOrder;
  assignWarehouseOrderPickerBatch: Array<WarehouseOrder>;
  assignWarehouseOrderPickerBatchV2: Array<WarehouseOrder>;
  batchUpdateBundle: Array<WarehouseBundle>;
  beginPickingV2: WarehouseBeginPick;
  beginWarehouseOrderPacking: WarehouseOrder;
  beginWarehouseOrderPicking: WarehouseOrder;
  biExportJewelrySkuSnapshots: Scalars['URL']['output'];
  bindIntegrationShopifyApp?: Maybe<Scalars['Void']['output']>;
  buyStoreRewardProduct: StoreRewardOrder;
  calculateWarehousePackageInfo: WarehousePackageCalculateResultDto;
  cancelCompanyBlueprintSubscription: IntegrationStripeSubscriptionDto;
  cancelIntegrationShopifyAppSubscription?: Maybe<Scalars['Void']['output']>;
  cancelScheduledSmsCampaign: Scalars['Boolean']['output'];
  cancelStoreBlueprintSubscription: IntegrationStripeSubscriptionDto;
  cancelWarehouseOrder: WarehouseOrder;
  changeUserPassword?: Maybe<Scalars['Void']['output']>;
  clipdropCleanup: ClipdropCleanupDto;
  createBatchBundles: Array<WarehouseBundle>;
  createBatchRules: Array<WarehouseRule>;
  createCompanyBlueprintSubscription: IntegrationStripeSubscriptionDto;
  createCompanyModuleItem: CompanyModuleItem;
  createIntegrationShopifyAppSubscription: Scalars['URL']['output'];
  /** create lot info */
  createLots: Array<WarehouseLotCode>;
  createManyWarehouseLotCodes: Array<WarehouseLotCode>;
  createManyWarehouseRules: Array<WarehouseRule>;
  createOneAffiliateOffer: AffiliateOffer;
  createOneAffiliateReferral: AffiliateReferral;
  createOneCompany: Company;
  createOneCompanyModuleItemPrice: CompanyModuleItemPrice;
  createOneStore: Store;
  createOneStoreModuleItemPrice: StoreModuleItemPrice;
  createOneStoreRewardProduct: StoreRewardProduct;
  createOneStoreShopifyAppInfo: StoreShopifyAppInfo;
  createOneUser: User;
  createOneWarehouseBox: WarehouseBox;
  createOneWarehouseBundle: WarehouseBundle;
  createOneWarehouseLotCode: WarehouseLotCode;
  createOneWarehouseOrderLineItem: WarehouseOrderLineItem;
  createOneWarehousePackage: WarehousePackage;
  createOneWarehouseProduct: WarehouseProduct;
  createOneWarehouseProductSkuAlias: WarehouseProductSkuAlias;
  createOneWarehouseRule: WarehouseRule;
  /** create product on shopify */
  createProductOnShopify: Scalars['JSON']['output'];
  /** create and send sms immediately */
  createSmsCampaign: SmsCampaign;
  createStoreBlueprintSubscription: IntegrationStripeSubscriptionDto;
  createStoreModuleItem: StoreModuleItem;
  createStoreShopifyDiscountCode?: Maybe<Scalars['String']['output']>;
  createStoreShopifyScriptTags: StoreShopifyScriptTagDto;
  createStoreShopifyWebhooks: StoreShopifyWebhookDto;
  /** manual create warehouse order */
  createWarehouseOrder: WarehouseOrder;
  /** for packer to create simple quick package only */
  createWarehousePackageFromOrder: WarehousePackage;
  /** create shipment from package */
  createWarehousePackageShipment: WarehousePackage;
  deleteOneCompany: CompanyDeleteResponse;
  deleteOneCompanyModuleItemPrice: CompanyModuleItemPriceDeleteResponse;
  deleteOneStore: StoreDeleteResponse;
  deleteOneStoreModuleItemPrice: StoreModuleItemPriceDeleteResponse;
  deleteOneStoreRewardProduct: StoreRewardProductDeleteResponse;
  deleteOneStoreShopifyAppInfo: StoreShopifyAppInfoDeleteResponse;
  deleteOneUser: UserDeleteResponse;
  deleteOneWarehouseBox: WarehouseBoxDeleteResponse;
  deleteOneWarehouseBundle: WarehouseBundleDeleteResponse;
  deleteOneWarehouseLotCode: WarehouseLotCodeDeleteResponse;
  deleteOneWarehousePackage: WarehousePackageDeleteResponse;
  deleteOneWarehouseProductSkuAlias: WarehouseProductSkuAliasDeleteResponse;
  deleteOneWarehouseRule: WarehouseRuleDeleteResponse;
  /** delete warehouse rule */
  deleteRule: Scalars['Void']['output'];
  deleteStoreShopifyScriptTags?: Maybe<Scalars['Void']['output']>;
  deleteStoreShopifyWebhooks?: Maybe<Scalars['Void']['output']>;
  endPickingV2: Scalars['String']['output'];
  endWarehouseOrderPacking: WarehouseOrder;
  endWarehouseOrderPicking: WarehouseOrder;
  /** get company by v1Id, if not exist will try to create one */
  exchangeCompanyFromV1?: Maybe<Company>;
  /** get store by v1Id, if not exist will try to create one */
  exchangeStoreFromV1?: Maybe<Store>;
  exportWarehousePackages: Scalars['URL']['output'];
  extractProductName: AiDesignSearchDto;
  fetchWarehouseProductFromV1: Array<WarehouseProduct>;
  generateAiDesignDescription: AiDesignDescriptionDto;
  generateAiDesignMarketingContent: AiDesignMarketingContentDto;
  generateAiDesignPicture: AiDesignPictureDto;
  generateAiDesignProductSpecAndCost: AiDesignProductSpecCostDto;
  generateAiDesignText: Array<AiDesignTextDto>;
  generateImageBySketch: AiDesignSketchDto;
  generateRemixProductCandidatePicture: RemixProductCandidatePictureDto;
  generateRemixProductCandidateText: Array<RemixProductCandidateTextDto>;
  generateRemixProductDetails: RemixProductDetailsDto;
  generateShortLink: Scalars['URL']['output'];
  generateSmsCampaignCandidateMessages: Array<SmsCampaignCandidateMessageDto>;
  importSmsAudiences: Scalars['URL']['output'];
  /** issue a company access token */
  issueAnonymousToken: LoginDto;
  /** issue a company access token */
  issueCompanyToken: LoginDto;
  /** issue a store access token */
  issueStoreToken: LoginDto;
  /**
   * issue a supplier access token
   * @deprecated use issueCompanyToken instead
   */
  issueSupplierToken: LoginDto;
  login: LoginDto;
  /** affiliate user login */
  loginAffiliateUser: LoginDto;
  /** login from v1 and automatic register */
  loginFromV1: LoginDto;
  manualAddRewardPoints: StoreRewardIncomeRecord;
  manualRedeemRewardPoints: StoreRewardOrder;
  markAbleToPick: Scalars['String']['output'];
  markAffiliateOrdersAsPayoutV2: AffiliatePayout;
  markAsDefaultAffiliateOffer?: Maybe<Scalars['Void']['output']>;
  markUnableToPick: Scalars['String']['output'];
  /** pack one item each time */
  packWarehouseOrderLineItem?: Maybe<Scalars['Void']['output']>;
  pickScan: WarehouseScan;
  /** pick one item each time */
  pickWarehouseOrderLineItem?: Maybe<Scalars['Void']['output']>;
  /** record lot to lineItem associations */
  recordWarehouseScan?: Maybe<Scalars['Void']['output']>;
  refreshStoreRewardPoints?: Maybe<StoreRewardIncomeRecord>;
  refreshStoreRewardPointsForV1?: Maybe<StoreRewardIncomeRecord>;
  register: LoginDto;
  /** affiliate user register */
  registerAffiliateUser: LoginDto;
  resetUserPassword?: Maybe<Scalars['Void']['output']>;
  resetWarehouseOrderUnableStatus?: Maybe<Scalars['Void']['output']>;
  sendAffiliateProcessPayoutEmail: Scalars['Boolean']['output'];
  sendAffiliateReferralLinkEmail: Scalars['Boolean']['output'];
  sendAffiliateRegisterEmail: Scalars['Boolean']['output'];
  sendBusinessBuilderWelcomeMessage: Scalars['Void']['output'];
  sendSmsCampaignPreviewMessage?: Maybe<Scalars['Void']['output']>;
  setCompanyModules: Company;
  setStoreMenus: Store;
  setStoreModules: Store;
  setupStoreShopifyWebhooks?: Maybe<Scalars['Void']['output']>;
  unableToPackWarehouseOrderLineItem?: Maybe<Scalars['Void']['output']>;
  unableToPickWarehouseOrderLineItem?: Maybe<Scalars['Void']['output']>;
  unqualifyAffiliateOrder?: Maybe<Scalars['Void']['output']>;
  /** generate a new code and set a expire date */
  updateAffiliateReferralCode?: Maybe<Scalars['Void']['output']>;
  updateAffiliateUser: AffiliateReferral;
  updateCompanyBlueprintSubscription: IntegrationStripeSubscriptionDto;
  updateCompanyModuleItem: CompanyModuleItem;
  updateManyWarehouseLotCodes: UpdateManyResponse;
  updateManyWarehouseRules: UpdateManyResponse;
  updateOneAffiliateOffer: AffiliateOffer;
  updateOneAffiliateReferral: AffiliateReferral;
  updateOneCompany: Company;
  updateOneCompanyModuleItemPrice: CompanyModuleItemPrice;
  updateOneStore: Store;
  updateOneStoreModuleItemPrice: StoreModuleItemPrice;
  updateOneStoreRewardProduct: StoreRewardProduct;
  updateOneStoreShopifyAppInfo: StoreShopifyAppInfo;
  updateOneWarehouseBundle: WarehouseBundle;
  updateOneWarehouseLotCode: WarehouseLotCode;
  updateOneWarehousePackage: WarehousePackage;
  updateOneWarehouseProduct: WarehouseProduct;
  updateOneWarehouseProductSkuAlias: WarehouseProductSkuAlias;
  updateOneWarehouseRule: WarehouseRule;
  updateRule: WarehouseRule;
  /** Sets rule to active or inactive */
  updateRuleStatus: WarehouseRule;
  updateStoreBlueprintSubscription: IntegrationStripeSubscriptionDto;
  updateStoreModuleItem: StoreModuleItem;
  updateUser?: Maybe<Scalars['Void']['output']>;
  updateWarehouseOrderShippingInfo: WarehouseOrder;
};

export type MutationActivateLotArgs = {
  input: WarehouseLotActivateInput;
};

export type MutationAddShopifyPullOrdersQueueJobArgs = {
  createdAtFrom: Scalars['DateTime']['input'];
  createdAtTo: Scalars['DateTime']['input'];
  shopName: Scalars['String']['input'];
};

export type MutationAdjustWarehouseInventoryArgs = {
  deltaActualQuantity?: InputMaybe<Scalars['Int']['input']>;
  deltaAvailableQuantity?: InputMaybe<Scalars['Int']['input']>;
  deltaIncomingQuantity?: InputMaybe<Scalars['Int']['input']>;
  deltaOutgoingQuantity?: InputMaybe<Scalars['Int']['input']>;
  inventoryId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAiLabAnimateDiffArgs = {
  input: AiLabAnimateDiffInput;
};

export type MutationAiLabColorChangeArgs = {
  input: AiLabColorChangeInput;
};

export type MutationAiLabFabricPatternArgs = {
  input: AiLabFabricPatternInput;
};

export type MutationAiLabFabricPattern1Args = {
  input: AiLabFabricPattern1Input;
};

export type MutationAiLabFabricPattern2Args = {
  input: AiLabFabricPattern2Input;
};

export type MutationAiLabReplaceBackgroudArgs = {
  flowType: ReplaceBackgroundFlowType;
  input: AiLabReplaceBackgroudInput;
};

export type MutationAiPlaygroundChatArgs = {
  input: AiPlaygroundChatInput;
};

export type MutationAiPlaygroundImageArgs = {
  input: AiPlaygroundImageInput;
};

export type MutationAiPlaygroundImageSearchByOpenAiArgs = {
  input: AiPlaygroundImageSearchInput;
};

export type MutationAiPlaygroundImageSearchByResNetArgs = {
  input: AiPlaygroundImageSearchInput;
};

export type MutationAiPlaygroundOutfitArgs = {
  input: AiPlaygroundOutfitInput;
};

export type MutationAiPlaygroundSketchArgs = {
  input: AiPlaygroundSketchInput;
};

export type MutationAiPlaygroundVisionArgs = {
  input: AiPlaygroundVisionInput;
};

export type MutationApproveAffiliateOrderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationAssignWarehouseOrderPickerArgs = {
  id: Scalars['ID']['input'];
  pickerId: Scalars['ID']['input'];
};

export type MutationAssignWarehouseOrderPickerBatchArgs = {
  input: AssignPickerBatchInput;
};

export type MutationAssignWarehouseOrderPickerBatchV2Args = {
  input: AssignPickerBatchInput;
};

export type MutationBatchUpdateBundleArgs = {
  input: BundleCreateUpdateInput;
};

export type MutationBeginPickingV2Args = {
  id: Scalars['ID']['input'];
};

export type MutationBeginWarehouseOrderPackingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationBeginWarehouseOrderPickingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationBiExportJewelrySkuSnapshotsArgs = {
  availableForSale?: InputMaybe<Scalars['Boolean']['input']>;
  availableInStock?: InputMaybe<Scalars['Boolean']['input']>;
  fulfilledByPietra?: InputMaybe<Scalars['Boolean']['input']>;
  manualCategories?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  snapShotAt: Scalars['DateTime']['input'];
};

export type MutationBindIntegrationShopifyAppArgs = {
  appName: IntegrationShopifyAppName;
  bindCode: Scalars['String']['input'];
};

export type MutationBuyStoreRewardProductArgs = {
  productId: Scalars['ID']['input'];
};

export type MutationCalculateWarehousePackageInfoArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  length?: InputMaybe<Scalars['Int']['input']>;
  lineItemIds: Array<Scalars['ID']['input']>;
  orderId: Scalars['ID']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCancelCompanyBlueprintSubscriptionArgs = {
  cancelAtPeriodEnd?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCancelIntegrationShopifyAppSubscriptionArgs = {
  appName: IntegrationShopifyAppName;
};

export type MutationCancelScheduledSmsCampaignArgs = {
  campaignId: Scalars['String']['input'];
};

export type MutationCancelStoreBlueprintSubscriptionArgs = {
  cancelAtPeriodEnd?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCancelWarehouseOrderArgs = {
  cancelReason: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type MutationChangeUserPasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type MutationClipdropCleanupArgs = {
  input: ClipdropCleanupInput;
};

export type MutationCreateBatchBundlesArgs = {
  input: BundleCreateUpdateInput;
};

export type MutationCreateBatchRulesArgs = {
  input: CreateWarehouseRuleInputBatch;
};

export type MutationCreateCompanyBlueprintSubscriptionArgs = {
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  priceIds: Array<Scalars['ID']['input']>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateCompanyModuleItemArgs = {
  input: CompanyModuleItemCreateInput;
};

export type MutationCreateIntegrationShopifyAppSubscriptionArgs = {
  appName: IntegrationShopifyAppName;
};

export type MutationCreateLotsArgs = {
  input: WarehouseLotCreateBatch;
};

export type MutationCreateManyWarehouseLotCodesArgs = {
  input: CreateManyWarehouseLotCodesInput;
};

export type MutationCreateManyWarehouseRulesArgs = {
  input: CreateManyWarehouseRulesInput;
};

export type MutationCreateOneAffiliateOfferArgs = {
  input: CreateOneAffiliateOfferInput;
};

export type MutationCreateOneAffiliateReferralArgs = {
  input: CreateOneAffiliateReferralInput;
};

export type MutationCreateOneCompanyArgs = {
  input: CreateOneCompanyInput;
};

export type MutationCreateOneCompanyModuleItemPriceArgs = {
  input: CreateOneCompanyModuleItemPriceInput;
};

export type MutationCreateOneStoreArgs = {
  input: CreateOneStoreInput;
};

export type MutationCreateOneStoreModuleItemPriceArgs = {
  input: CreateOneStoreModuleItemPriceInput;
};

export type MutationCreateOneStoreRewardProductArgs = {
  input: CreateOneStoreRewardProductInput;
};

export type MutationCreateOneStoreShopifyAppInfoArgs = {
  input: CreateOneStoreShopifyAppInfoInput;
};

export type MutationCreateOneUserArgs = {
  input: CreateOneUserInput;
};

export type MutationCreateOneWarehouseBoxArgs = {
  input: CreateOneWarehouseBoxInput;
};

export type MutationCreateOneWarehouseBundleArgs = {
  input: CreateOneWarehouseBundleInput;
};

export type MutationCreateOneWarehouseLotCodeArgs = {
  input: CreateOneWarehouseLotCodeInput;
};

export type MutationCreateOneWarehouseOrderLineItemArgs = {
  input: CreateOneWarehouseOrderLineItemInput;
};

export type MutationCreateOneWarehousePackageArgs = {
  input: CreateOneWarehousePackageInput;
};

export type MutationCreateOneWarehouseProductArgs = {
  input: CreateOneWarehouseProductInput;
};

export type MutationCreateOneWarehouseProductSkuAliasArgs = {
  input: CreateOneWarehouseProductSkuAliasInput;
};

export type MutationCreateOneWarehouseRuleArgs = {
  input: CreateOneWarehouseRuleInput;
};

export type MutationCreateProductOnShopifyArgs = {
  appName: IntegrationShopifyAppName;
  input: ShopifyProductCreateInput;
};

export type MutationCreateSmsCampaignArgs = {
  input: SmsCampaignCreateInput;
};

export type MutationCreateStoreBlueprintSubscriptionArgs = {
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  priceIds: Array<Scalars['ID']['input']>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateStoreModuleItemArgs = {
  input: StoreModuleItemCreateInput;
};

export type MutationCreateStoreShopifyDiscountCodeArgs = {
  input: StoreShopifyDiscountCodeCreateInput;
  storeId: Scalars['ID']['input'];
};

export type MutationCreateStoreShopifyScriptTagsArgs = {
  input: StoreShopifyScriptTagCreateInput;
  storeId: Scalars['ID']['input'];
};

export type MutationCreateStoreShopifyWebhooksArgs = {
  input: StoreShopifyWebhookCreateInput;
  storeId: Scalars['ID']['input'];
};

export type MutationCreateWarehouseOrderArgs = {
  input: WarehouseOrderCreateInput;
};

export type MutationCreateWarehousePackageFromOrderArgs = {
  height?: InputMaybe<Scalars['Float']['input']>;
  length?: InputMaybe<Scalars['Float']['input']>;
  orderId: Scalars['ID']['input'];
  shippingMaterialId: Scalars['ID']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationCreateWarehousePackageShipmentArgs = {
  orderId: Scalars['ID']['input'];
  packageId: Scalars['ID']['input'];
  shippingMaterialId: Scalars['ID']['input'];
};

export type MutationDeleteOneCompanyArgs = {
  input: DeleteOneCompanyInput;
};

export type MutationDeleteOneCompanyModuleItemPriceArgs = {
  input: DeleteOneCompanyModuleItemPriceInput;
};

export type MutationDeleteOneStoreArgs = {
  input: DeleteOneStoreInput;
};

export type MutationDeleteOneStoreModuleItemPriceArgs = {
  input: DeleteOneStoreModuleItemPriceInput;
};

export type MutationDeleteOneStoreRewardProductArgs = {
  input: DeleteOneStoreRewardProductInput;
};

export type MutationDeleteOneStoreShopifyAppInfoArgs = {
  input: DeleteOneStoreShopifyAppInfoInput;
};

export type MutationDeleteOneUserArgs = {
  input: DeleteOneUserInput;
};

export type MutationDeleteOneWarehouseBoxArgs = {
  input: DeleteOneWarehouseBoxInput;
};

export type MutationDeleteOneWarehouseBundleArgs = {
  input: DeleteOneWarehouseBundleInput;
};

export type MutationDeleteOneWarehouseLotCodeArgs = {
  input: DeleteOneWarehouseLotCodeInput;
};

export type MutationDeleteOneWarehousePackageArgs = {
  input: DeleteOneWarehousePackageInput;
};

export type MutationDeleteOneWarehouseProductSkuAliasArgs = {
  input: DeleteOneWarehouseProductSkuAliasInput;
};

export type MutationDeleteOneWarehouseRuleArgs = {
  input: DeleteOneWarehouseRuleInput;
};

export type MutationDeleteRuleArgs = {
  input: DeleteWarehouseRuleInput;
};

export type MutationDeleteStoreShopifyScriptTagsArgs = {
  storeId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};

export type MutationDeleteStoreShopifyWebhooksArgs = {
  storeId: Scalars['ID']['input'];
  webhookId: Scalars['ID']['input'];
};

export type MutationEndPickingV2Args = {
  id: Scalars['ID']['input'];
};

export type MutationEndWarehouseOrderPackingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEndWarehouseOrderPickingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationExchangeCompanyFromV1Args = {
  companyIdV1: Scalars['Int']['input'];
};

export type MutationExchangeStoreFromV1Args = {
  storeIdV1: Scalars['Int']['input'];
};

export type MutationExportWarehousePackagesArgs = {
  createdAtFrom: Scalars['DateTime']['input'];
  createdAtTo: Scalars['DateTime']['input'];
};

export type MutationExtractProductNameArgs = {
  input: AiDesignSearchInput;
};

export type MutationFetchWarehouseProductFromV1Args = {
  jewelryId: Scalars['Int']['input'];
};

export type MutationGenerateAiDesignDescriptionArgs = {
  input: AiDesignDescriptionInput;
};

export type MutationGenerateAiDesignMarketingContentArgs = {
  input: AiDesignMarketingContentInput;
};

export type MutationGenerateAiDesignPictureArgs = {
  input: AiDesignPictureInput;
};

export type MutationGenerateAiDesignProductSpecAndCostArgs = {
  input: AiDesignMarketingContentInput;
};

export type MutationGenerateAiDesignTextArgs = {
  input: AiDesignTextInput;
};

export type MutationGenerateImageBySketchArgs = {
  input: AiDesignSketchInput;
};

export type MutationGenerateRemixProductCandidatePictureArgs = {
  input: RemixProductPictureGenerateInput;
};

export type MutationGenerateRemixProductCandidateTextArgs = {
  input: RemixProductTextGenerateInput;
};

export type MutationGenerateRemixProductDetailsArgs = {
  input: RemixProductDetailsInput;
};

export type MutationGenerateShortLinkArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
};

export type MutationGenerateSmsCampaignCandidateMessagesArgs = {
  description: Scalars['String']['input'];
  discountType?: InputMaybe<SmsCampaignDiscountType>;
  isLongText?: InputMaybe<Scalars['Boolean']['input']>;
  tones?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationImportSmsAudiencesArgs = {
  fileUrl: Scalars['URL']['input'];
};

export type MutationIssueAnonymousTokenArgs = {
  anonymousUserId: Scalars['String']['input'];
};

export type MutationIssueCompanyTokenArgs = {
  companyId: Scalars['ID']['input'];
};

export type MutationIssueStoreTokenArgs = {
  storeId: Scalars['ID']['input'];
};

export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MutationLoginAffiliateUserArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  storeId: Scalars['ID']['input'];
};

export type MutationLoginFromV1Args = {
  password?: InputMaybe<Scalars['String']['input']>;
  portal?: InputMaybe<PietraV1UserType>;
  token?: InputMaybe<Scalars['JWT']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type MutationManualAddRewardPointsArgs = {
  note?: InputMaybe<Scalars['String']['input']>;
  points: Scalars['Int']['input'];
  storeId: Scalars['ID']['input'];
};

export type MutationManualRedeemRewardPointsArgs = {
  note?: InputMaybe<Scalars['String']['input']>;
  points: Scalars['Int']['input'];
  storeId: Scalars['ID']['input'];
};

export type MutationMarkAbleToPickArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMarkAffiliateOrdersAsPayoutV2Args = {
  input: AffiliateOrderPayoutInput;
};

export type MutationMarkAsDefaultAffiliateOfferArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMarkUnableToPickArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPackWarehouseOrderLineItemArgs = {
  lineItemId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type MutationPickScanArgs = {
  input: WarehousePickInput;
};

export type MutationPickWarehouseOrderLineItemArgs = {
  lineItemId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type MutationRecordWarehouseScanArgs = {
  input: WarehouseCaptureLotRecordInput;
};

export type MutationRefreshStoreRewardPointsArgs = {
  storeId: Scalars['ID']['input'];
};

export type MutationRefreshStoreRewardPointsForV1Args = {
  storeV1Id: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type MutationRegisterArgs = {
  input: UserCreateInput;
};

export type MutationRegisterAffiliateUserArgs = {
  input: AffiliateRegisterInput;
  storeId: Scalars['ID']['input'];
};

export type MutationResetUserPasswordArgs = {
  newPassword: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationResetWarehouseOrderUnableStatusArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSendAffiliateReferralLinkEmailArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSendAffiliateRegisterEmailArgs = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationSendBusinessBuilderWelcomeMessageArgs = {
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type MutationSendSmsCampaignPreviewMessageArgs = {
  discountCode?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  shortLink?: InputMaybe<Scalars['String']['input']>;
  smsContent: Scalars['String']['input'];
};

export type MutationSetCompanyModulesArgs = {
  id: Scalars['ID']['input'];
  moduleNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationSetStoreMenusArgs = {
  id: Scalars['ID']['input'];
  menus?: InputMaybe<Array<StoreMenu>>;
};

export type MutationSetStoreModulesArgs = {
  id: Scalars['ID']['input'];
  moduleNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationSetupStoreShopifyWebhooksArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUnableToPackWarehouseOrderLineItemArgs = {
  lineItemId?: InputMaybe<Scalars['ID']['input']>;
  orderId: Scalars['ID']['input'];
};

export type MutationUnableToPickWarehouseOrderLineItemArgs = {
  lineItemId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type MutationUnqualifyAffiliateOrderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateAffiliateReferralCodeArgs = {
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationUpdateAffiliateUserArgs = {
  input: AffiliateUpdateInput;
};

export type MutationUpdateCompanyBlueprintSubscriptionArgs = {
  priceIds: Array<Scalars['ID']['input']>;
};

export type MutationUpdateCompanyModuleItemArgs = {
  id: Scalars['ID']['input'];
  input: CompanyModuleItemUpdateInput;
};

export type MutationUpdateManyWarehouseLotCodesArgs = {
  input: UpdateManyWarehouseLotCodesInput;
};

export type MutationUpdateManyWarehouseRulesArgs = {
  input: UpdateManyWarehouseRulesInput;
};

export type MutationUpdateOneAffiliateOfferArgs = {
  input: UpdateOneAffiliateOfferInput;
};

export type MutationUpdateOneAffiliateReferralArgs = {
  input: UpdateOneAffiliateReferralInput;
};

export type MutationUpdateOneCompanyArgs = {
  input: UpdateOneCompanyInput;
};

export type MutationUpdateOneCompanyModuleItemPriceArgs = {
  input: UpdateOneCompanyModuleItemPriceInput;
};

export type MutationUpdateOneStoreArgs = {
  input: UpdateOneStoreInput;
};

export type MutationUpdateOneStoreModuleItemPriceArgs = {
  input: UpdateOneStoreModuleItemPriceInput;
};

export type MutationUpdateOneStoreRewardProductArgs = {
  input: UpdateOneStoreRewardProductInput;
};

export type MutationUpdateOneStoreShopifyAppInfoArgs = {
  input: UpdateOneStoreShopifyAppInfoInput;
};

export type MutationUpdateOneWarehouseBundleArgs = {
  input: UpdateOneWarehouseBundleInput;
};

export type MutationUpdateOneWarehouseLotCodeArgs = {
  input: UpdateOneWarehouseLotCodeInput;
};

export type MutationUpdateOneWarehousePackageArgs = {
  input: UpdateOneWarehousePackageInput;
};

export type MutationUpdateOneWarehouseProductArgs = {
  input: UpdateOneWarehouseProductInput;
};

export type MutationUpdateOneWarehouseProductSkuAliasArgs = {
  input: UpdateOneWarehouseProductSkuAliasInput;
};

export type MutationUpdateOneWarehouseRuleArgs = {
  input: UpdateOneWarehouseRuleInput;
};

export type MutationUpdateRuleArgs = {
  input: UpdateWarehouseRuleInput;
};

export type MutationUpdateRuleStatusArgs = {
  input: UpdateWarehouseRuleStatusInput;
};

export type MutationUpdateStoreBlueprintSubscriptionArgs = {
  priceIds: Array<Scalars['ID']['input']>;
};

export type MutationUpdateStoreModuleItemArgs = {
  id: Scalars['ID']['input'];
  input: StoreModuleItemUpdateInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UserUpdateInput;
};

export type MutationUpdateWarehouseOrderShippingInfoArgs = {
  id: Scalars['ID']['input'];
  input: WarehouseOrderShippingInfoUpdateInput;
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Int']['input'];
  upper: Scalars['Int']['input'];
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset to start returning records from */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum OrderOrigin {
  BcCreator = 'BC_CREATOR',
  CartRover = 'CART_ROVER',
  FairePlatform = 'FAIRE_PLATFORM',
  Import = 'IMPORT',
  MagentoCreator = 'MAGENTO_CREATOR',
  ManualRetail = 'MANUAL_RETAIL',
  ManualWholesale = 'MANUAL_WHOLESALE',
  ShopifyCreator = 'SHOPIFY_CREATOR',
  ShopifyPlatform = 'SHOPIFY_PLATFORM',
  SsCreator = 'SS_CREATOR',
  WcCreator = 'WC_CREATOR',
  WixCreator = 'WIX_CREATOR',
}

export type OrderOriginFilterComparison = {
  eq?: InputMaybe<OrderOrigin>;
  gt?: InputMaybe<OrderOrigin>;
  gte?: InputMaybe<OrderOrigin>;
  iLike?: InputMaybe<OrderOrigin>;
  in?: InputMaybe<Array<OrderOrigin>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<OrderOrigin>;
  lt?: InputMaybe<OrderOrigin>;
  lte?: InputMaybe<OrderOrigin>;
  neq?: InputMaybe<OrderOrigin>;
  notILike?: InputMaybe<OrderOrigin>;
  notIn?: InputMaybe<Array<OrderOrigin>>;
  notLike?: InputMaybe<OrderOrigin>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
};

export type PaidAtDateInput = {
  lower: Scalars['String']['input'];
  upper: Scalars['String']['input'];
};

export type PantoneColors = {
  __typename?: 'PantoneColors';
  code?: Maybe<Scalars['String']['output']>;
  hex?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PickingQuantity = {
  __typename?: 'PickingQuantity';
  imageUrl?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  productId: Scalars['String']['output'];
  productName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  status: WarehousePickingStatus;
  taskId: Scalars['String']['output'];
};

export enum PietraV1UserType {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  Partner = 'PARTNER',
  Store = 'STORE',
}

export enum ProductMetricType {
  ConstructionSpecifications = 'CONSTRUCTION_SPECIFICATIONS',
  DesignDescription = 'DESIGN_DESCRIPTION',
  ManufacturingCost = 'MANUFACTURING_COST',
  MaterialOptions = 'MATERIAL_OPTIONS',
  PackagingDetails = 'PACKAGING_DETAILS',
  PantoneColors = 'PANTONE_COLORS',
}

export type PublicCompanyDto = {
  __typename?: 'PublicCompanyDto';
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  v1Id?: Maybe<Scalars['Int']['output']>;
};

export type PublicStoreDto = {
  __typename?: 'PublicStoreDto';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  handle: Scalars['String']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  v1Id?: Maybe<Scalars['Int']['output']>;
};

export type PublicUserDto = {
  __typename?: 'PublicUserDto';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
  v1Id?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  adminLookAnalytics: Array<StoreAnalyticsDataDto>;
  affiliateOffer: AffiliateOffer;
  affiliateOffers: AffiliateOfferConnection;
  affiliateOrder: AffiliateOrder;
  affiliateOrders: AffiliateOrderConnection;
  affiliatePayout: AffiliatePayout;
  affiliatePayouts: AffiliatePayoutConnection;
  affiliateReferral: AffiliateReferral;
  affiliateReferrals: AffiliateReferralConnection;
  companies: CompanyConnection;
  company: Company;
  companyModuleItem: CompanyModuleItem;
  companyModuleItemPrice: CompanyModuleItemPrice;
  companyModuleItemPrices: CompanyModuleItemPriceConnection;
  companyModuleItems: CompanyModuleItemConnection;
  currentAffiliateUser: AffiliateReferral;
  currentMyCompany: Company;
  currentMyStore: Store;
  currentUser?: Maybe<User>;
  defaultAffiliateOffer?: Maybe<AffiliateOffer>;
  defaultCompany?: Maybe<Company>;
  defaultStore?: Maybe<Store>;
  emailCustomers: EmailCustomerConnection;
  executeStepFunction: Scalars['String']['output'];
  /** Query to fetch Bundles by SKU */
  fetchBundleForProduct: Array<WarehouseBundle>;
  /** Fetch lots for a given SKU */
  fetchLotsForSku: Array<WarehouseLotCode>;
  fulfillmentMetricsData: Array<FulfillmentMetricsData>;
  fulfillmentMetricsDataAggregate: Array<FulfillmentMetricsDataAggregateResponse>;
  fulfillmentOOSDataList: Array<FulfillmentOosExceptionData>;
  fulfillmentOOSDetailData: Array<FulfillmentOosDetailData>;
  fulfillmentOOSDetailDataAggregate: Array<FulfillmentOosDetailDataAggregateResponse>;
  fulfillmentOOSDetailDataSummary: Array<FulfillmentOosDetailData>;
  fulfillmentOOSExceptionData: FulfillmentOosExceptionDataConnection;
  fulfillmentOrderExceptSummary: FulfillmentOrderExceptSummaryData;
  fulfillmentOrderExceptSummaryData: Array<FulfillmentOrderExceptSummaryData>;
  fulfillmentOrderExceptSummaryDataAggregate: Array<FulfillmentOrderExceptSummaryDataAggregateResponse>;
  fulfillmentOrderSnapshot: FulfillmentOrderSnapshotData;
  fulfillmentOrderSnapshotData: Array<FulfillmentOrderSnapshotData>;
  fulfillmentTransitTime2iData: Array<FulfillmentTransitTime2iData>;
  fulfillmentTransitTime2iDataAggregate: Array<FulfillmentTransitTime2iDataAggregateResponse>;
  fulfillmentTransitTimeData: Array<FulfillmentTransitTimeData>;
  fulfillmentTransitTimeDataAggregate: Array<FulfillmentTransitTimeDataAggregateResponse>;
  fulfillmentTurnAroundTimeData: FulfillmentTurnAroundTimeDataConnection;
  fulfillmentUndeliveredShipmentData: FulfillmentUndeliveredShipmentDataConnection;
  /** get public company info */
  getOnePublicCompany?: Maybe<PublicCompanyDto>;
  /** get public store info */
  getOnePublicStore?: Maybe<PublicStoreDto>;
  getPickingStatus?: Maybe<WarehouseBeginPick>;
  getWarehouseBoxByBarcode: WarehouseProduct;
  /** ping test */
  hello?: Maybe<Scalars['JSON']['output']>;
  helloBi: Scalars['String']['output'];
  isFieldUnique: Scalars['Boolean']['output'];
  isSmsFederalSilentHours: Scalars['Boolean']['output'];
  salesCreatorOrder: SalesCreatorOrder;
  salesCreatorOrders: SalesCreatorOrderConnection;
  salesOrder: SalesOrder;
  salesOrders: SalesOrderConnection;
  signAttachments: Array<SignedAttachmentResponse>;
  smsAudience: SmsAudience;
  smsAudiences: SmsAudienceOffsetConnection;
  smsCampaign: SmsCampaign;
  smsCampaignAudiences: Array<SmsCampaignAudienceDto>;
  smsCampaigns: SmsCampaignConnection;
  stepFunctionStatus: StepFunctionStatusData;
  store: Store;
  storeCountryCityData: Array<StoreCountryCityData>;
  storeCustomerAllData: StoreCustomerAllDataConnection;
  storeCustomerBaseSegmentUserData: StoreCustomerBaseSegmentUserDataConnection;
  storeCustomerBestZone: Array<StoreCustomerZoneEverydayData>;
  storeCustomerChurnDevelopmentData: Array<StoreCustomerChurnDevelopmentData>;
  storeCustomerChurnRecoveredData: Array<StoreCustomerChurnRecoveredData>;
  storeCustomerChurnRiskData: Array<StoreCustomerChurnRiskData>;
  storeCustomerDevelopmentData: Array<StoreCustomerDevelopmentData>;
  storeCustomerEconomicsData: Array<StoreCustomerEconomicsData>;
  storeCustomerFirstOrderValueData: Array<StoreCustomerFirstOrderValueData>;
  storeCustomerFollowUpPurchaseData: Array<StoreCustomerFollowUpPurchaseData>;
  storeCustomerItemsPerBasketData: Array<StoreCustomerItemsPerBasketData>;
  storeCustomerLtvCohortsAvgData: Array<StoreCustomerLtvCohortsAvgData>;
  storeCustomerOrderAovPerOrderCountData: Array<StoreCustomerOrderAovPerOrderCountData>;
  storeCustomerOrderAvgData: Array<StoreCustomerOrderAvgData>;
  storeCustomerOrderCohortLtvData: Array<StoreCustomerOrderCohortLtvData>;
  storeCustomerOrderDailyLtvData: Array<StoreCustomerOrderDailyLtvData>;
  storeCustomerOrderDatetimePartData: Array<StoreCustomerOrderDatetimePartData>;
  storeCustomerOrderDatetimePartDataAggregate: Array<StoreCustomerOrderDatetimePartDataAggregateResponse>;
  storeCustomerOrderFirstPaidData: Array<StoreCustomerOrderFirstPaidData>;
  storeCustomerOrderItemReturnItemData: StoreCustomerOrderItemReturnItemDataConnection;
  storeCustomerOrderItemReturnItemDataAggregate: Array<StoreCustomerOrderItemReturnItemDataAggregateResponse>;
  storeCustomerOrderItemReturnRateData: Array<StoreCustomerOrderItemReturnRateData>;
  storeCustomerOrderItemSoldData: Array<StoreCustomerOrderItemSoldData>;
  storeCustomerOrderNotFirstPaidData: Array<StoreCustomerOrderNotFirstPaidData>;
  storeCustomerOrderZoneData: Array<StoreCustomerOrderZoneData>;
  storeCustomerPercentileAnalysisData: Array<StoreCustomerPercentileAnalysisData>;
  storeCustomerPersona: StoreCustomerPersonaDto;
  storeCustomerPurchaseIntervalData: Array<StoreCustomerPurchaseIntervalData>;
  storeCustomerPurchaseStatData: Array<StoreCustomerPurchaseStatData>;
  storeCustomerRFMScoreData: Array<StoreCustomerRfmScoreData>;
  storeCustomerRFMScoreDataAggregate: Array<StoreCustomerRfmScoreDataAggregateResponse>;
  storeCustomerRepeatPurchaseDevelopmentData: Array<StoreCustomerRepeatPurchaseDevelopmentData>;
  storeCustomerRepeatPurchaseDevelopmentDataAggregate: Array<StoreCustomerRepeatPurchaseDevelopmentDataAggregateResponse>;
  storeCustomerZoneEverydayData: Array<StoreCustomerZoneEverydayData>;
  storeCustomerZoneEverydayDataAggregate: Array<StoreCustomerZoneEverydayDataAggregateResponse>;
  storeEntriesInOrders: Array<StoreEntryDto>;
  storeKlaviyoAppInfo: StoreKlaviyoAppInfo;
  storeKlaviyoAppInfos: StoreKlaviyoAppInfoConnection;
  storeLtvCohorts: Array<StoreCustomerLtvCohortsDto>;
  storeMarketBestChannels: Array<StoreMarketBestChannelsData>;
  storeModuleItem: StoreModuleItem;
  storeModuleItemPrice: StoreModuleItemPrice;
  storeModuleItemPrices: StoreModuleItemPriceConnection;
  storeModuleItems: StoreModuleItemConnection;
  storeOrderAllData: StoreOrderAllDataConnection;
  storeOrderCountDistributionData: Array<StoreOrderCountDistributionData>;
  storeOrderCountDistributionDataAggregate: Array<StoreOrderCountDistributionDataAggregateResponse>;
  storeOrderDevelopmentData: Array<StoreOrderDevelopmentData>;
  storeOrderDevelopmentDataAggregate: Array<StoreOrderDevelopmentDataAggregateResponse>;
  storeOrderDiscountData: Array<StoreOrderDiscountData>;
  storeOrderDiscountDataAggregate: Array<StoreOrderDiscountDataAggregateResponse>;
  storeOrderFreeShippingThresholdData: Array<StoreOrderFreeShippingThresholdData>;
  storeOrderFreeShippingThresholdDataAggregate: Array<StoreOrderFreeShippingThresholdDataAggregateResponse>;
  storeOrderItemAllData: Array<StoreOrderItemAllData>;
  storeOrderItemBestSell: Array<StoreOrderItemBestSellData>;
  storeProductAllData: StoreProductAllDataConnection;
  storeProductCrossSellingAnalysisData: Array<StoreProductCrossSellingAnalysisData>;
  storeProductReturnItem: Array<StoreCustomerOrderItemReturnItemData>;
  storeRevenueByCohorts: Array<StoreRevenueByCohortsDto>;
  storeRewardIncomeRecord: StoreRewardIncomeRecord;
  storeRewardIncomeRecords: StoreRewardIncomeRecordConnection;
  storeRewardOrder: StoreRewardOrder;
  storeRewardOrders: StoreRewardOrderConnection;
  storeRewardProduct: StoreRewardProduct;
  storeRewardProducts: StoreRewardProductConnection;
  storeShopifyAppInfo: StoreShopifyAppInfo;
  storeShopifyAppInfos: StoreShopifyAppInfoConnection;
  storeShopifyDiscountCode?: Maybe<StoreShopifyDiscountCodeDto>;
  storeShopifyScriptTags: Array<StoreShopifyScriptTagDto>;
  storeShopifyWebhooks: Array<StoreShopifyWebhookDto>;
  storeVariantsAllData: StoreVariantsAllDataConnection;
  stores: StoreConnection;
  todayMyAssignedPackingWarehouseOrderCount: Scalars['Int']['output'];
  todayMyAssignedPickingWarehouseOrderCount: Scalars['Int']['output'];
  todayMyCompletedPackingWarehouseOrderCount: Scalars['Int']['output'];
  todayMyCompletedPickingWarehouseOrderCount: Scalars['Int']['output'];
  user: User;
  users: UserOffsetConnection;
  warehouseBox: WarehouseBox;
  warehouseBoxes: WarehouseBoxConnection;
  warehouseBundle: WarehouseBundle;
  warehouseBundles: WarehouseBundleConnection;
  warehouseFacilities: WarehouseFacilityConnection;
  warehouseFacility: WarehouseFacility;
  warehouseLotCode: WarehouseLotCode;
  warehouseLotCodes: WarehouseLotCodeConnection;
  warehouseOrder: WarehouseOrder;
  warehouseOrderLineItem: WarehouseOrderLineItem;
  warehouseOrderLineItems: WarehouseOrderLineItemConnection;
  warehouseOrders: WarehouseOrderOffsetConnection;
  warehousePackage: WarehousePackage;
  warehousePackages: WarehousePackageConnection;
  warehouseProduct: WarehouseProduct;
  warehouseProductSkuAlias: WarehouseProductSkuAlias;
  warehouseProductSkuAliases: WarehouseProductSkuAliasConnection;
  warehouseProducts: WarehouseProductConnection;
  warehouseRule: WarehouseRule;
  warehouseRules: WarehouseRuleConnection;
};

export type QueryAdminLookAnalyticsArgs = {
  storeV1Id: Scalars['Int']['input'];
};

export type QueryAffiliateOfferArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAffiliateOffersArgs = {
  filter?: AffiliateOfferFilter;
  paging?: OffsetPaging;
  sorting?: Array<AffiliateOfferSort>;
};

export type QueryAffiliateOrderArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAffiliateOrdersArgs = {
  filter?: AffiliateOrderFilter;
  paging?: OffsetPaging;
  sorting?: Array<AffiliateOrderSort>;
};

export type QueryAffiliatePayoutArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAffiliatePayoutsArgs = {
  filter?: AffiliatePayoutFilter;
  paging?: OffsetPaging;
  sorting?: Array<AffiliatePayoutSort>;
};

export type QueryAffiliateReferralArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAffiliateReferralsArgs = {
  filter?: AffiliateReferralFilter;
  paging?: OffsetPaging;
  sorting?: Array<AffiliateReferralSort>;
};

export type QueryCompaniesArgs = {
  filter?: CompanyFilter;
  paging?: OffsetPaging;
  sorting?: Array<CompanySort>;
};

export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCompanyModuleItemArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCompanyModuleItemPriceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCompanyModuleItemPricesArgs = {
  filter?: CompanyModuleItemPriceFilter;
  paging?: OffsetPaging;
  sorting?: Array<CompanyModuleItemPriceSort>;
};

export type QueryCompanyModuleItemsArgs = {
  filter?: CompanyModuleItemFilter;
  paging?: OffsetPaging;
  sorting?: Array<CompanyModuleItemSort>;
};

export type QueryDefaultAffiliateOfferArgs = {
  offerId?: InputMaybe<Scalars['ID']['input']>;
  storeId: Scalars['ID']['input'];
};

export type QueryEmailCustomersArgs = {
  filter?: EmailCustomerFilter;
  paging?: OffsetPaging;
  sorting?: Array<EmailCustomerSort>;
};

export type QueryFetchBundleForProductArgs = {
  sku: Scalars['String']['input'];
};

export type QueryFetchLotsForSkuArgs = {
  input: WarehouseLotsFetchInput;
};

export type QueryFulfillmentMetricsDataArgs = {
  filter?: FulfillmentMetricsDataFilter;
  sorting?: Array<FulfillmentMetricsDataSort>;
};

export type QueryFulfillmentMetricsDataAggregateArgs = {
  filter?: InputMaybe<FulfillmentMetricsDataAggregateFilter>;
};

export type QueryFulfillmentOosDataListArgs = {
  paidAt?: InputMaybe<PaidAtDateInput>;
  storeV1Id: Scalars['Int']['input'];
};

export type QueryFulfillmentOosDetailDataArgs = {
  filter?: FulfillmentOosDetailDataFilter;
  sorting?: Array<FulfillmentOosDetailDataSort>;
};

export type QueryFulfillmentOosDetailDataAggregateArgs = {
  filter?: InputMaybe<FulfillmentOosDetailDataAggregateFilter>;
};

export type QueryFulfillmentOosDetailDataSummaryArgs = {
  paidAt: PaidAtDateInput;
  storeV1Id: Scalars['Int']['input'];
};

export type QueryFulfillmentOosExceptionDataArgs = {
  filter?: FulfillmentOosExceptionDataFilter;
  paging?: OffsetPaging;
  sorting?: Array<FulfillmentOosExceptionDataSort>;
};

export type QueryFulfillmentOrderExceptSummaryArgs = {
  paidAt: PaidAtDateInput;
  storeV1Id: Scalars['Int']['input'];
};

export type QueryFulfillmentOrderExceptSummaryDataArgs = {
  filter?: FulfillmentOrderExceptSummaryDataFilter;
  sorting?: Array<FulfillmentOrderExceptSummaryDataSort>;
};

export type QueryFulfillmentOrderExceptSummaryDataAggregateArgs = {
  filter?: InputMaybe<FulfillmentOrderExceptSummaryDataAggregateFilter>;
};

export type QueryFulfillmentOrderSnapshotArgs = {
  storeV1Id: Scalars['Int']['input'];
};

export type QueryFulfillmentOrderSnapshotDataArgs = {
  filter?: FulfillmentOrderSnapshotDataFilter;
  sorting?: Array<FulfillmentOrderSnapshotDataSort>;
};

export type QueryFulfillmentTransitTime2iDataArgs = {
  filter?: FulfillmentTransitTime2iDataFilter;
  sorting?: Array<FulfillmentTransitTime2iDataSort>;
};

export type QueryFulfillmentTransitTime2iDataAggregateArgs = {
  filter?: InputMaybe<FulfillmentTransitTime2iDataAggregateFilter>;
};

export type QueryFulfillmentTransitTimeDataArgs = {
  filter?: FulfillmentTransitTimeDataFilter;
  sorting?: Array<FulfillmentTransitTimeDataSort>;
};

export type QueryFulfillmentTransitTimeDataAggregateArgs = {
  filter?: InputMaybe<FulfillmentTransitTimeDataAggregateFilter>;
};

export type QueryFulfillmentTurnAroundTimeDataArgs = {
  filter?: FulfillmentTurnAroundTimeDataFilter;
  paging?: OffsetPaging;
  sorting?: Array<FulfillmentTurnAroundTimeDataSort>;
};

export type QueryFulfillmentUndeliveredShipmentDataArgs = {
  filter?: FulfillmentUndeliveredShipmentDataFilter;
  paging?: OffsetPaging;
  sorting?: Array<FulfillmentUndeliveredShipmentDataSort>;
};

export type QueryGetOnePublicCompanyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  v1Id?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetOnePublicStoreArgs = {
  handle?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  v1Id?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetPickingStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetWarehouseBoxByBarcodeArgs = {
  barcode: Scalars['String']['input'];
  storeId: Scalars['ID']['input'];
};

export type QueryIsFieldUniqueArgs = {
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  field: Scalars['String']['input'];
  type: EntityType;
  value: Scalars['String']['input'];
  valueSep?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySalesCreatorOrderArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySalesCreatorOrdersArgs = {
  filter?: SalesCreatorOrderFilter;
  paging?: OffsetPaging;
  sorting?: Array<SalesCreatorOrderSort>;
};

export type QuerySalesOrderArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySalesOrdersArgs = {
  filter?: SalesOrderFilter;
  paging?: OffsetPaging;
  sorting?: Array<SalesOrderSort>;
};

export type QuerySignAttachmentsArgs = {
  input: SignWarehouseAttachmentRequest;
};

export type QuerySmsAudienceArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySmsAudiencesArgs = {
  filter?: SmsAudienceFilter;
  paging?: OffsetPaging;
  sorting?: Array<SmsAudienceSort>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QuerySmsCampaignArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySmsCampaignsArgs = {
  filter?: SmsCampaignFilter;
  paging?: OffsetPaging;
  sorting?: Array<SmsCampaignSort>;
};

export type QueryStoreArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStoreCountryCityDataArgs = {
  filter?: StoreCountryCityDataFilter;
  sorting?: Array<StoreCountryCityDataSort>;
};

export type QueryStoreCustomerAllDataArgs = {
  filter?: StoreCustomerAllDataFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreCustomerAllDataSort>;
};

export type QueryStoreCustomerBaseSegmentUserDataArgs = {
  filter?: StoreCustomerBaseSegmentUserDataFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreCustomerBaseSegmentUserDataSort>;
};

export type QueryStoreCustomerBestZoneArgs = {
  paidAtDate: PaidAtDateInput;
  storeV1Id: Scalars['Int']['input'];
};

export type QueryStoreCustomerChurnDevelopmentDataArgs = {
  filter?: StoreCustomerChurnDevelopmentDataFilter;
  sorting?: Array<StoreCustomerChurnDevelopmentDataSort>;
};

export type QueryStoreCustomerChurnRecoveredDataArgs = {
  filter?: StoreCustomerChurnRecoveredDataFilter;
  sorting?: Array<StoreCustomerChurnRecoveredDataSort>;
};

export type QueryStoreCustomerChurnRiskDataArgs = {
  filter?: StoreCustomerChurnRiskDataFilter;
  sorting?: Array<StoreCustomerChurnRiskDataSort>;
};

export type QueryStoreCustomerDevelopmentDataArgs = {
  filter?: StoreCustomerDevelopmentDataFilter;
  sorting?: Array<StoreCustomerDevelopmentDataSort>;
};

export type QueryStoreCustomerEconomicsDataArgs = {
  filter?: StoreCustomerEconomicsDataFilter;
  sorting?: Array<StoreCustomerEconomicsDataSort>;
};

export type QueryStoreCustomerFirstOrderValueDataArgs = {
  filter?: StoreCustomerFirstOrderValueDataFilter;
  sorting?: Array<StoreCustomerFirstOrderValueDataSort>;
};

export type QueryStoreCustomerFollowUpPurchaseDataArgs = {
  filter?: StoreCustomerFollowUpPurchaseDataFilter;
  sorting?: Array<StoreCustomerFollowUpPurchaseDataSort>;
};

export type QueryStoreCustomerItemsPerBasketDataArgs = {
  filter?: StoreCustomerItemsPerBasketDataFilter;
  sorting?: Array<StoreCustomerItemsPerBasketDataSort>;
};

export type QueryStoreCustomerLtvCohortsAvgDataArgs = {
  filter?: StoreCustomerLtvCohortsAvgDataFilter;
  sorting?: Array<StoreCustomerLtvCohortsAvgDataSort>;
};

export type QueryStoreCustomerOrderAovPerOrderCountDataArgs = {
  filter?: StoreCustomerOrderAovPerOrderCountDataFilter;
  sorting?: Array<StoreCustomerOrderAovPerOrderCountDataSort>;
};

export type QueryStoreCustomerOrderAvgDataArgs = {
  filter?: StoreCustomerOrderAvgDataFilter;
  sorting?: Array<StoreCustomerOrderAvgDataSort>;
};

export type QueryStoreCustomerOrderCohortLtvDataArgs = {
  filter?: StoreCustomerOrderCohortLtvDataFilter;
  sorting?: Array<StoreCustomerOrderCohortLtvDataSort>;
};

export type QueryStoreCustomerOrderDailyLtvDataArgs = {
  filter?: StoreCustomerOrderDailyLtvDataFilter;
  sorting?: Array<StoreCustomerOrderDailyLtvDataSort>;
};

export type QueryStoreCustomerOrderDatetimePartDataArgs = {
  filter?: StoreCustomerOrderDatetimePartDataFilter;
  sorting?: Array<StoreCustomerOrderDatetimePartDataSort>;
};

export type QueryStoreCustomerOrderDatetimePartDataAggregateArgs = {
  filter?: InputMaybe<StoreCustomerOrderDatetimePartDataAggregateFilter>;
};

export type QueryStoreCustomerOrderFirstPaidDataArgs = {
  filter?: StoreCustomerOrderFirstPaidDataFilter;
  sorting?: Array<StoreCustomerOrderFirstPaidDataSort>;
};

export type QueryStoreCustomerOrderItemReturnItemDataArgs = {
  filter?: StoreCustomerOrderItemReturnItemDataFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreCustomerOrderItemReturnItemDataSort>;
};

export type QueryStoreCustomerOrderItemReturnItemDataAggregateArgs = {
  filter?: InputMaybe<StoreCustomerOrderItemReturnItemDataAggregateFilter>;
};

export type QueryStoreCustomerOrderItemReturnRateDataArgs = {
  filter?: StoreCustomerOrderItemReturnRateDataFilter;
  sorting?: Array<StoreCustomerOrderItemReturnRateDataSort>;
};

export type QueryStoreCustomerOrderItemSoldDataArgs = {
  filter?: StoreCustomerOrderItemSoldDataFilter;
  sorting?: Array<StoreCustomerOrderItemSoldDataSort>;
};

export type QueryStoreCustomerOrderNotFirstPaidDataArgs = {
  filter?: StoreCustomerOrderNotFirstPaidDataFilter;
  sorting?: Array<StoreCustomerOrderNotFirstPaidDataSort>;
};

export type QueryStoreCustomerOrderZoneDataArgs = {
  filter?: StoreCustomerOrderZoneDataFilter;
  sorting?: Array<StoreCustomerOrderZoneDataSort>;
};

export type QueryStoreCustomerPercentileAnalysisDataArgs = {
  filter?: StoreCustomerPercentileAnalysisDataFilter;
  sorting?: Array<StoreCustomerPercentileAnalysisDataSort>;
};

export type QueryStoreCustomerPersonaArgs = {
  storeV1Id: Scalars['Int']['input'];
};

export type QueryStoreCustomerPurchaseIntervalDataArgs = {
  filter?: StoreCustomerPurchaseIntervalDataFilter;
  sorting?: Array<StoreCustomerPurchaseIntervalDataSort>;
};

export type QueryStoreCustomerPurchaseStatDataArgs = {
  filter?: StoreCustomerPurchaseStatDataFilter;
  sorting?: Array<StoreCustomerPurchaseStatDataSort>;
};

export type QueryStoreCustomerRfmScoreDataArgs = {
  filter?: StoreCustomerRfmScoreDataFilter;
  sorting?: Array<StoreCustomerRfmScoreDataSort>;
};

export type QueryStoreCustomerRfmScoreDataAggregateArgs = {
  filter?: InputMaybe<StoreCustomerRfmScoreDataAggregateFilter>;
};

export type QueryStoreCustomerRepeatPurchaseDevelopmentDataArgs = {
  filter?: StoreCustomerRepeatPurchaseDevelopmentDataFilter;
  sorting?: Array<StoreCustomerRepeatPurchaseDevelopmentDataSort>;
};

export type QueryStoreCustomerRepeatPurchaseDevelopmentDataAggregateArgs = {
  filter?: InputMaybe<StoreCustomerRepeatPurchaseDevelopmentDataAggregateFilter>;
};

export type QueryStoreCustomerZoneEverydayDataArgs = {
  filter?: StoreCustomerZoneEverydayDataFilter;
  sorting?: Array<StoreCustomerZoneEverydayDataSort>;
};

export type QueryStoreCustomerZoneEverydayDataAggregateArgs = {
  filter?: InputMaybe<StoreCustomerZoneEverydayDataAggregateFilter>;
};

export type QueryStoreEntriesInOrdersArgs = {
  type: StoreOrderType;
};

export type QueryStoreKlaviyoAppInfoArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStoreKlaviyoAppInfosArgs = {
  filter?: StoreKlaviyoAppInfoFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreKlaviyoAppInfoSort>;
};

export type QueryStoreLtvCohortsArgs = {
  ltvType: LtvType;
  paidAtDate: PaidAtDateInput;
  paidAtDateType: BiDateType;
  storeV1Id: Scalars['Int']['input'];
};

export type QueryStoreMarketBestChannelsArgs = {
  paidAtDate: PaidAtDateInput;
  storeV1Id: Scalars['Int']['input'];
};

export type QueryStoreModuleItemArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStoreModuleItemPriceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStoreModuleItemPricesArgs = {
  filter?: StoreModuleItemPriceFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreModuleItemPriceSort>;
};

export type QueryStoreModuleItemsArgs = {
  filter?: StoreModuleItemFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreModuleItemSort>;
};

export type QueryStoreOrderAllDataArgs = {
  filter?: StoreOrderAllDataFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreOrderAllDataSort>;
};

export type QueryStoreOrderCountDistributionDataArgs = {
  filter?: StoreOrderCountDistributionDataFilter;
  sorting?: Array<StoreOrderCountDistributionDataSort>;
};

export type QueryStoreOrderCountDistributionDataAggregateArgs = {
  filter?: InputMaybe<StoreOrderCountDistributionDataAggregateFilter>;
};

export type QueryStoreOrderDevelopmentDataArgs = {
  filter?: StoreOrderDevelopmentDataFilter;
  sorting?: Array<StoreOrderDevelopmentDataSort>;
};

export type QueryStoreOrderDevelopmentDataAggregateArgs = {
  filter?: InputMaybe<StoreOrderDevelopmentDataAggregateFilter>;
};

export type QueryStoreOrderDiscountDataArgs = {
  filter?: StoreOrderDiscountDataFilter;
  sorting?: Array<StoreOrderDiscountDataSort>;
};

export type QueryStoreOrderDiscountDataAggregateArgs = {
  filter?: InputMaybe<StoreOrderDiscountDataAggregateFilter>;
};

export type QueryStoreOrderFreeShippingThresholdDataArgs = {
  filter?: StoreOrderFreeShippingThresholdDataFilter;
  sorting?: Array<StoreOrderFreeShippingThresholdDataSort>;
};

export type QueryStoreOrderFreeShippingThresholdDataAggregateArgs = {
  filter?: InputMaybe<StoreOrderFreeShippingThresholdDataAggregateFilter>;
};

export type QueryStoreOrderItemAllDataArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderField?: InputMaybe<BestSellingItemOrderField>;
  paidAtDate: PaidAtDateInput;
  storeV1Id: Scalars['Int']['input'];
};

export type QueryStoreOrderItemBestSellArgs = {
  paidAtDate: PaidAtDateInput;
  storeV1Id: Scalars['Int']['input'];
};

export type QueryStoreProductAllDataArgs = {
  filter?: StoreProductAllDataFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreProductAllDataSort>;
};

export type QueryStoreProductCrossSellingAnalysisDataArgs = {
  filter?: StoreProductCrossSellingAnalysisDataFilter;
  sorting?: Array<StoreProductCrossSellingAnalysisDataSort>;
};

export type QueryStoreProductReturnItemArgs = {
  orderField?: InputMaybe<ReturnedItemsOrderField>;
  paidAt: PaidAtDateInput;
  storeV1Id: Scalars['Int']['input'];
};

export type QueryStoreRevenueByCohortsArgs = {
  paidAtDate: PaidAtDateInput;
  paidAtDateType: LongDateType;
  storeV1Id: Scalars['Int']['input'];
};

export type QueryStoreRewardIncomeRecordArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStoreRewardIncomeRecordsArgs = {
  filter?: StoreRewardIncomeRecordFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreRewardIncomeRecordSort>;
};

export type QueryStoreRewardOrderArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStoreRewardOrdersArgs = {
  filter?: StoreRewardOrderFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreRewardOrderSort>;
};

export type QueryStoreRewardProductArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStoreRewardProductsArgs = {
  filter?: StoreRewardProductFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreRewardProductSort>;
};

export type QueryStoreShopifyAppInfoArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStoreShopifyAppInfosArgs = {
  filter?: StoreShopifyAppInfoFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreShopifyAppInfoSort>;
};

export type QueryStoreShopifyDiscountCodeArgs = {
  code: Scalars['String']['input'];
  storeId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryStoreShopifyScriptTagsArgs = {
  storeId: Scalars['ID']['input'];
};

export type QueryStoreShopifyWebhooksArgs = {
  storeId: Scalars['ID']['input'];
};

export type QueryStoreVariantsAllDataArgs = {
  filter?: StoreVariantsAllDataFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreVariantsAllDataSort>;
};

export type QueryStoresArgs = {
  filter?: StoreFilter;
  paging?: OffsetPaging;
  sorting?: Array<StoreSort>;
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
  filter?: UserFilter;
  includeRoles?: InputMaybe<Array<Role>>;
  paging?: OffsetPaging;
  sorting?: Array<UserSort>;
};

export type QueryWarehouseBoxArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWarehouseBoxesArgs = {
  filter?: WarehouseBoxFilter;
  paging?: OffsetPaging;
  sorting?: Array<WarehouseBoxSort>;
};

export type QueryWarehouseBundleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWarehouseBundlesArgs = {
  filter?: WarehouseBundleFilter;
  paging?: CursorPaging;
  sorting?: Array<WarehouseBundleSort>;
};

export type QueryWarehouseFacilitiesArgs = {
  filter?: WarehouseFacilityFilter;
  paging?: OffsetPaging;
  sorting?: Array<WarehouseFacilitySort>;
};

export type QueryWarehouseFacilityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWarehouseLotCodeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWarehouseLotCodesArgs = {
  filter?: WarehouseLotCodeFilter;
  paging?: CursorPaging;
  sorting?: Array<WarehouseLotCodeSort>;
};

export type QueryWarehouseOrderArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWarehouseOrderLineItemArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWarehouseOrderLineItemsArgs = {
  filter?: WarehouseOrderLineItemFilter;
  paging?: OffsetPaging;
  sorting?: Array<WarehouseOrderLineItemSort>;
};

export type QueryWarehouseOrdersArgs = {
  filter?: WarehouseOrderFilter;
  paging?: OffsetPaging;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sorting?: Array<WarehouseOrderSort>;
};

export type QueryWarehousePackageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWarehousePackagesArgs = {
  filter?: WarehousePackageFilter;
  paging?: OffsetPaging;
  sorting?: Array<WarehousePackageSort>;
};

export type QueryWarehouseProductArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWarehouseProductSkuAliasArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWarehouseProductSkuAliasesArgs = {
  filter?: WarehouseProductSkuAliasFilter;
  paging?: OffsetPaging;
  sorting?: Array<WarehouseProductSkuAliasSort>;
};

export type QueryWarehouseProductsArgs = {
  filter?: WarehouseProductFilter;
  paging?: OffsetPaging;
  sorting?: Array<WarehouseProductSort>;
};

export type QueryWarehouseRuleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWarehouseRulesArgs = {
  filter?: WarehouseRuleFilter;
  paging?: OffsetPaging;
  sorting?: Array<WarehouseRuleSort>;
};

export enum ReferenceType {
  V1 = 'V1',
  V2 = 'V2',
}

export type ReferenceTypeFilterComparison = {
  eq?: InputMaybe<ReferenceType>;
  gt?: InputMaybe<ReferenceType>;
  gte?: InputMaybe<ReferenceType>;
  iLike?: InputMaybe<ReferenceType>;
  in?: InputMaybe<Array<ReferenceType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ReferenceType>;
  lt?: InputMaybe<ReferenceType>;
  lte?: InputMaybe<ReferenceType>;
  neq?: InputMaybe<ReferenceType>;
  notILike?: InputMaybe<ReferenceType>;
  notIn?: InputMaybe<Array<ReferenceType>>;
  notLike?: InputMaybe<ReferenceType>;
};

export type RemixProductCandidatePictureDto = {
  __typename?: 'RemixProductCandidatePictureDto';
  url: Scalars['String']['output'];
};

export type RemixProductCandidateTextDto = {
  __typename?: 'RemixProductCandidateTextDto';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type RemixProductDetailsDto = {
  __typename?: 'RemixProductDetailsDto';
  L1Category: Scalars['String']['output'];
  L2Category?: Maybe<Array<Scalars['String']['output']>>;
};

export type RemixProductDetailsInput = {
  imageUrl: Scalars['String']['input'];
  productName: Scalars['String']['input'];
};

export type RemixProductPictureGenerateInput = {
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type RemixProductTextGenerateInput = {
  itemURL?: InputMaybe<Scalars['String']['input']>;
  productName: Scalars['String']['input'];
  titles: Array<Scalars['String']['input']>;
};

export enum ReplaceBackgroundFlowType {
  RemoveBackground = 'REMOVE_BACKGROUND',
  ReplaceBackground = 'REPLACE_BACKGROUND',
}

/** Sort the field of the ReturnedItems */
export enum ReturnedItemsOrderField {
  /** returnCount field */
  ReturnCount = 'returnCount',
  /** returnRate field */
  ReturnRate = 'returnRate',
}

export enum Role {
  Admin = 'ADMIN',
  AffiliateUser = 'AFFILIATE_USER',
  Anonymous = 'ANONYMOUS',
  Company = 'COMPANY',
  CompanyOwner = 'COMPANY_OWNER',
  Store = 'STORE',
  StoreOwner = 'STORE_OWNER',
  Supplier = 'SUPPLIER',
  Warehouse = 'WAREHOUSE',
  WarehouseManager = 'WAREHOUSE_MANAGER',
  WarehousePacker = 'WAREHOUSE_PACKER',
  WarehousePicker = 'WAREHOUSE_PICKER',
  WarehouseReceiver = 'WAREHOUSE_RECEIVER',
}

export type SalesCreatorOrder = {
  __typename?: 'SalesCreatorOrder';
  createdAt: Scalars['DateTime']['output'];
  fulfilledAt?: Maybe<Scalars['DateTime']['output']>;
  fulfillmentStatus: SalesOrderFulfillmentStatus;
  /** ID */
  id: Scalars['ID']['output'];
  items: Array<SalesOrderLineItem>;
  name: Scalars['String']['output'];
  salesOrderId: Scalars['ID']['output'];
  sn: Scalars['String']['output'];
  storeId: Scalars['ID']['output'];
  subtotalPriceBp: Scalars['Int']['output'];
  totalDiscountBp: Scalars['Int']['output'];
  totalLineItemDiscountBp: Scalars['Int']['output'];
  totalLineItemPriceBp: Scalars['Int']['output'];
  totalPriceBp: Scalars['Int']['output'];
  totalTaxBp: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SalesCreatorOrderItemsArgs = {
  filter?: SalesOrderLineItemFilter;
  sorting?: Array<SalesOrderLineItemSort>;
};

export type SalesCreatorOrderConnection = {
  __typename?: 'SalesCreatorOrderConnection';
  /** Array of nodes. */
  nodes: Array<SalesCreatorOrder>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SalesCreatorOrderFilter = {
  and?: InputMaybe<Array<SalesCreatorOrderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  fulfilledAt?: InputMaybe<DateFieldComparison>;
  fulfillmentStatus?: InputMaybe<SalesCreatorOrderFulfillmentStatusFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  items?: InputMaybe<SalesCreatorOrderFilterSalesOrderLineItemFilter>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SalesCreatorOrderFilter>>;
  salesOrderId?: InputMaybe<IdFilterComparison>;
  sn?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SalesCreatorOrderFilterSalesOrderLineItemFilter = {
  and?: InputMaybe<Array<SalesCreatorOrderFilterSalesOrderLineItemFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  fulfillmentStatus?: InputMaybe<SalesOrderLineItemFulfillmentStatusFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SalesCreatorOrderFilterSalesOrderLineItemFilter>>;
  referenceId?: InputMaybe<StringFieldComparison>;
  referenceSku?: InputMaybe<StringFieldComparison>;
  salesCreatorOrderId?: InputMaybe<IdFilterComparison>;
  salesOrderId?: InputMaybe<IdFilterComparison>;
  sku?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SalesCreatorOrderFulfillmentStatusFilterComparison = {
  in?: InputMaybe<Array<SalesOrderFulfillmentStatus>>;
  notIn?: InputMaybe<Array<SalesOrderFulfillmentStatus>>;
};

export type SalesCreatorOrderSort = {
  direction: SortDirection;
  field: SalesCreatorOrderSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SalesCreatorOrderSortFields {
  CreatedAt = 'createdAt',
  FulfilledAt = 'fulfilledAt',
  FulfillmentStatus = 'fulfillmentStatus',
  Id = 'id',
  Name = 'name',
  SalesOrderId = 'salesOrderId',
  Sn = 'sn',
  StoreId = 'storeId',
  UpdatedAt = 'updatedAt',
}

export type SalesOrder = {
  __typename?: 'SalesOrder';
  createdAt: Scalars['DateTime']['output'];
  fulfillmentStatus: SalesOrderFulfillmentStatus;
  /** ID */
  id: Scalars['ID']['output'];
  items: Array<SalesOrderLineItem>;
  name: Scalars['String']['output'];
  platformType: SalesOrderPlatformType;
  referenceId?: Maybe<Scalars['String']['output']>;
  shippingAddress1?: Maybe<Scalars['String']['output']>;
  shippingAddress2?: Maybe<Scalars['String']['output']>;
  shippingCity?: Maybe<Scalars['String']['output']>;
  shippingCompany?: Maybe<Scalars['String']['output']>;
  shippingCountry?: Maybe<Scalars['String']['output']>;
  shippingCountryCode?: Maybe<Scalars['String']['output']>;
  shippingDiscountBp: Scalars['Int']['output'];
  shippingName?: Maybe<Scalars['String']['output']>;
  shippingPhone?: Maybe<Scalars['String']['output']>;
  shippingPriceBp: Scalars['Int']['output'];
  shippingProvince?: Maybe<Scalars['String']['output']>;
  shippingProvinceCode?: Maybe<Scalars['String']['output']>;
  shippingZip?: Maybe<Scalars['String']['output']>;
  sn: Scalars['String']['output'];
  storeId?: Maybe<Scalars['ID']['output']>;
  subtotalPriceBp: Scalars['Int']['output'];
  totalDiscountBp: Scalars['Int']['output'];
  totalLineItemDiscountBp: Scalars['Int']['output'];
  totalLineItemPriceBp: Scalars['Int']['output'];
  totalPriceBp: Scalars['Int']['output'];
  totalTaxBp: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SalesOrderItemsArgs = {
  filter?: SalesOrderLineItemFilter;
  sorting?: Array<SalesOrderLineItemSort>;
};

export type SalesOrderConnection = {
  __typename?: 'SalesOrderConnection';
  /** Array of nodes. */
  nodes: Array<SalesOrder>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SalesOrderFilter = {
  and?: InputMaybe<Array<SalesOrderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  fulfillmentStatus?: InputMaybe<SalesOrderFulfillmentStatusFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  items?: InputMaybe<SalesOrderFilterSalesOrderLineItemFilter>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SalesOrderFilter>>;
  platformType?: InputMaybe<SalesOrderPlatformTypeFilterComparison>;
  referenceId?: InputMaybe<StringFieldComparison>;
  sn?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SalesOrderFilterSalesOrderLineItemFilter = {
  and?: InputMaybe<Array<SalesOrderFilterSalesOrderLineItemFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  fulfillmentStatus?: InputMaybe<SalesOrderLineItemFulfillmentStatusFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SalesOrderFilterSalesOrderLineItemFilter>>;
  referenceId?: InputMaybe<StringFieldComparison>;
  referenceSku?: InputMaybe<StringFieldComparison>;
  salesCreatorOrderId?: InputMaybe<IdFilterComparison>;
  salesOrderId?: InputMaybe<IdFilterComparison>;
  sku?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export enum SalesOrderFulfillmentStatus {
  /** Every line item in the order has been fulfilled. */
  Fulfilled = 'FULFILLED',
  /** None of the line items in the order have been fulfilled. */
  None = 'NONE',
  /** At least one line item in the order has been fulfilled. */
  Partial = 'PARTIAL',
}

export type SalesOrderFulfillmentStatusFilterComparison = {
  in?: InputMaybe<Array<SalesOrderFulfillmentStatus>>;
  notIn?: InputMaybe<Array<SalesOrderFulfillmentStatus>>;
};

export type SalesOrderLineItem = {
  __typename?: 'SalesOrderLineItem';
  createdAt: Scalars['DateTime']['output'];
  fulfillmentStatus: SalesOrderFulfillmentStatus;
  /** ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priceBp: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  referenceId?: Maybe<Scalars['String']['output']>;
  referenceSku?: Maybe<Scalars['String']['output']>;
  salesCreatorOrderId?: Maybe<Scalars['ID']['output']>;
  salesOrderId: Scalars['ID']['output'];
  sku: Scalars['String']['output'];
  totalDiscountBp: Scalars['Int']['output'];
  totalTaxBp: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SalesOrderLineItemEdge = {
  __typename?: 'SalesOrderLineItemEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the SalesOrderLineItem */
  node: SalesOrderLineItem;
};

export type SalesOrderLineItemFilter = {
  and?: InputMaybe<Array<SalesOrderLineItemFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  fulfillmentStatus?: InputMaybe<SalesOrderLineItemFulfillmentStatusFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SalesOrderLineItemFilter>>;
  referenceId?: InputMaybe<StringFieldComparison>;
  referenceSku?: InputMaybe<StringFieldComparison>;
  salesCreatorOrderId?: InputMaybe<IdFilterComparison>;
  salesOrderId?: InputMaybe<IdFilterComparison>;
  sku?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SalesOrderLineItemFulfillmentStatusFilterComparison = {
  in?: InputMaybe<Array<SalesOrderFulfillmentStatus>>;
  notIn?: InputMaybe<Array<SalesOrderFulfillmentStatus>>;
};

export type SalesOrderLineItemSort = {
  direction: SortDirection;
  field: SalesOrderLineItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SalesOrderLineItemSortFields {
  CreatedAt = 'createdAt',
  FulfillmentStatus = 'fulfillmentStatus',
  Id = 'id',
  Name = 'name',
  ReferenceId = 'referenceId',
  ReferenceSku = 'referenceSku',
  SalesCreatorOrderId = 'salesCreatorOrderId',
  SalesOrderId = 'salesOrderId',
  Sku = 'sku',
  UpdatedAt = 'updatedAt',
}

export enum SalesOrderPlatformType {
  Pietra = 'PIETRA',
  PietraShopify = 'PIETRA_SHOPIFY',
  StoreShopify = 'STORE_SHOPIFY',
}

export type SalesOrderPlatformTypeFilterComparison = {
  in?: InputMaybe<Array<SalesOrderPlatformType>>;
  notIn?: InputMaybe<Array<SalesOrderPlatformType>>;
};

export type SalesOrderSort = {
  direction: SortDirection;
  field: SalesOrderSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SalesOrderSortFields {
  CreatedAt = 'createdAt',
  FulfillmentStatus = 'fulfillmentStatus',
  Id = 'id',
  Name = 'name',
  PlatformType = 'platformType',
  ReferenceId = 'referenceId',
  Sn = 'sn',
  StoreId = 'storeId',
  UpdatedAt = 'updatedAt',
}

export type ShopifyProductCreateInput = {
  descriptionHtml: Scalars['String']['input'];
  images: Array<ShopifyProductImageInput>;
  status?: ShopifyProductStatus;
  title: Scalars['String']['input'];
};

export type ShopifyProductImageInput = {
  src: Scalars['String']['input'];
};

export enum ShopifyProductStatus {
  Active = 'Active',
  Archived = 'Archived',
  Draft = 'Draft',
}

export type SignWarehouseAttachmentRequest = {
  attachmentIds: Array<Scalars['String']['input']>;
};

export type SignedAttachmentResponse = {
  __typename?: 'SignedAttachmentResponse';
  id: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type SkuItemData = {
  quantity: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
  warehouseLocation?: InputMaybe<Scalars['String']['input']>;
};

export type SmsAudience = {
  __typename?: 'SmsAudience';
  createdAt: Scalars['DateTime']['output'];
  firstName: Scalars['String']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  source: Scalars['String']['output'];
  storeId: Scalars['ID']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SmsAudienceFilter = {
  and?: InputMaybe<Array<SmsAudienceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SmsAudienceFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  source?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SmsAudienceOffsetConnection = {
  __typename?: 'SmsAudienceOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SmsAudience>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SmsAudienceSort = {
  direction: SortDirection;
  field: SmsAudienceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SmsAudienceSortFields {
  CreatedAt = 'createdAt',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  Phone = 'phone',
  Source = 'source',
  StoreId = 'storeId',
  UpdatedAt = 'updatedAt',
}

export type SmsCampaign = {
  __typename?: 'SmsCampaign';
  audienceGroup?: Maybe<SmsCampaignAudience>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  costAmountBp: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  discountCode?: Maybe<Scalars['String']['output']>;
  discountType?: Maybe<SmsCampaignDiscountType>;
  /** ID */
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['URL']['output']>;
  isPaid: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['URL']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  processedAt?: Maybe<Scalars['DateTime']['output']>;
  sendDate?: Maybe<Scalars['DateTime']['output']>;
  shortLink?: Maybe<Scalars['URL']['output']>;
  smsContent: Scalars['String']['output'];
  status: SmsCampaignStatus;
  storeId: Scalars['ID']['output'];
  succeededCount?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum SmsCampaignAudience {
  /** All of your customers. */
  All = 'ALL',
  /** Has placed an order and Has not placed an order in the last 120 days */
  AtRiskCustomer = 'AT_RISK_CUSTOMER',
  /** Num of orders is greater than 3 or all order value is greater than $500 */
  MostEngaged = 'MOST_ENGAGED',
  /** Has placed an order in the last 60 days or Number of orders is less than 3 */
  RecentCustomer = 'RECENT_CUSTOMER',
}

export type SmsCampaignAudienceDto = {
  __typename?: 'SmsCampaignAudienceDto';
  description?: Maybe<Scalars['String']['output']>;
  group: SmsCampaignAudience;
  /** Approximate quantity of customers */
  totalCount: Scalars['Int']['output'];
};

export type SmsCampaignAudienceGroupFilterComparison = {
  eq?: InputMaybe<SmsCampaignAudience>;
  in?: InputMaybe<Array<SmsCampaignAudience>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<SmsCampaignAudience>;
  notIn?: InputMaybe<Array<SmsCampaignAudience>>;
};

export type SmsCampaignCandidateMessageDto = {
  __typename?: 'SmsCampaignCandidateMessageDto';
  discountCode?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type SmsCampaignConnection = {
  __typename?: 'SmsCampaignConnection';
  /** Array of nodes. */
  nodes: Array<SmsCampaign>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SmsCampaignCreateInput = {
  /** if null, send all to sms audience instead of shopify customers */
  audienceGroup?: InputMaybe<SmsCampaignAudience>;
  /** only enabled in non-production env, bypass sms federal silent hours limitation */
  bypass?: InputMaybe<Scalars['Boolean']['input']>;
  description: Scalars['String']['input'];
  discountCode?: InputMaybe<Scalars['String']['input']>;
  discountType?: InputMaybe<SmsCampaignDiscountType>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  sendDate?: InputMaybe<Scalars['DateTime']['input']>;
  shortLink?: InputMaybe<Scalars['String']['input']>;
  smsContent: Scalars['String']['input'];
  storeId?: InputMaybe<Scalars['ID']['input']>;
};

export enum SmsCampaignDiscountType {
  PercentOff_15 = 'PERCENT_OFF_15',
  PercentOff_20 = 'PERCENT_OFF_20',
  PercentOff_30 = 'PERCENT_OFF_30',
  PercentOff_50 = 'PERCENT_OFF_50',
  ShippingFree = 'SHIPPING_FREE',
}

export type SmsCampaignDiscountTypeFilterComparison = {
  eq?: InputMaybe<SmsCampaignDiscountType>;
  in?: InputMaybe<Array<SmsCampaignDiscountType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<SmsCampaignDiscountType>;
  notIn?: InputMaybe<Array<SmsCampaignDiscountType>>;
};

export type SmsCampaignFilter = {
  and?: InputMaybe<Array<SmsCampaignFilter>>;
  audienceGroup?: InputMaybe<SmsCampaignAudienceGroupFilterComparison>;
  completedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  discountCode?: InputMaybe<StringFieldComparison>;
  discountType?: InputMaybe<SmsCampaignDiscountTypeFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPaid?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<SmsCampaignFilter>>;
  processedAt?: InputMaybe<DateFieldComparison>;
  sendDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<SmsCampaignStatusFilterComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SmsCampaignSort = {
  direction: SortDirection;
  field: SmsCampaignSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SmsCampaignSortFields {
  AudienceGroup = 'audienceGroup',
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  DiscountCode = 'discountCode',
  DiscountType = 'discountType',
  Id = 'id',
  IsPaid = 'isPaid',
  ProcessedAt = 'processedAt',
  SendDate = 'sendDate',
  Status = 'status',
  StoreId = 'storeId',
  UpdatedAt = 'updatedAt',
}

export enum SmsCampaignStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Processing = 'PROCESSING',
}

export type SmsCampaignStatusFilterComparison = {
  eq?: InputMaybe<SmsCampaignStatus>;
  in?: InputMaybe<Array<SmsCampaignStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<SmsCampaignStatus>;
  notIn?: InputMaybe<Array<SmsCampaignStatus>>;
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST',
}

/** Step Function Status Data */
export type StepFunctionStatusData = {
  __typename?: 'StepFunctionStatusData';
  startDate: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type Store = {
  __typename?: 'Store';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  blueprintSubscriptionCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  blueprintSubscriptionId?: Maybe<Scalars['String']['output']>;
  blueprintSubscriptionPeriodEndAt?: Maybe<Scalars['DateTime']['output']>;
  blueprintSubscriptionPeriodStartAt?: Maybe<Scalars['DateTime']['output']>;
  blueprintSubscriptionStatus?: Maybe<StripeSubscriptionStatus>;
  createdAt: Scalars['DateTime']['output'];
  handle: Scalars['String']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  integrationShopifyAppInfos: Array<IntegrationShopifyAppInfo>;
  klaviyoAppInfo?: Maybe<StoreKlaviyoAppInfo>;
  menus?: Maybe<Array<StoreMenu>>;
  modules?: Maybe<Array<Scalars['String']['output']>>;
  rewardPoints: Scalars['Int']['output'];
  shopifyAppModules?: Maybe<Array<Scalars['String']['output']>>;
  storeUrl?: Maybe<Scalars['URL']['output']>;
  stripeModules?: Maybe<Array<Scalars['String']['output']>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** Store Owner ID */
  userId: Scalars['ID']['output'];
  v1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreIntegrationShopifyAppInfosArgs = {
  filter?: IntegrationShopifyAppInfoFilter;
  sorting?: Array<IntegrationShopifyAppInfoSort>;
};

export type StoreStoreUrlArgs = {
  type: StoreType;
};

export type StoreAnalyticsDataDto = {
  __typename?: 'StoreAnalyticsDataDto';
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StoreConnection = {
  __typename?: 'StoreConnection';
  /** Array of nodes. */
  nodes: Array<Store>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

/** query creator-customer country and city */
export type StoreCountryCityData = {
  __typename?: 'StoreCountryCityData';
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCountryCityDataFilter = {
  and?: InputMaybe<Array<StoreCountryCityDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCountryCityDataFilter>>;
  storeId?: InputMaybe<StoreCountryCityDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCountryCityDataStoreV1IdFilterComparison>;
};

export type StoreCountryCityDataSort = {
  direction: SortDirection;
  field: StoreCountryCityDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCountryCityDataSortFields {
  Id = 'id',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCountryCityDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCountryCityDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreCreateInput = {
  avatarUrl?: InputMaybe<Scalars['URL']['input']>;
  handle: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** query StoreCustomerAllData list */
export type StoreCustomerAllData = {
  __typename?: 'StoreCustomerAllData';
  aov?: Maybe<Scalars['Float']['output']>;
  churnRisk?: Maybe<Scalars['Float']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  customerEmail?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  customerName?: Maybe<Scalars['String']['output']>;
  customerPhone?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  ltv?: Maybe<Scalars['Float']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  rfmStatus?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerAllDataConnection = {
  __typename?: 'StoreCustomerAllDataConnection';
  /** Array of nodes. */
  nodes: Array<StoreCustomerAllData>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StoreCustomerAllDataFilter = {
  and?: InputMaybe<Array<StoreCustomerAllDataFilter>>;
  aov?: InputMaybe<FloatFieldComparison>;
  churnRisk?: InputMaybe<FloatFieldComparison>;
  city?: InputMaybe<StringFieldComparison>;
  countryCode?: InputMaybe<StringFieldComparison>;
  customerEmail?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  customerName?: InputMaybe<StringFieldComparison>;
  customerPhone?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  ltv?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerAllDataFilter>>;
  rfmStatus?: InputMaybe<StringFieldComparison>;
  score?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<StoreCustomerAllDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerAllDataStoreV1IdFilterComparison>;
};

export type StoreCustomerAllDataSort = {
  direction: SortDirection;
  field: StoreCustomerAllDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerAllDataSortFields {
  Aov = 'aov',
  ChurnRisk = 'churnRisk',
  City = 'city',
  CountryCode = 'countryCode',
  CustomerEmail = 'customerEmail',
  CustomerId = 'customerId',
  CustomerName = 'customerName',
  CustomerPhone = 'customerPhone',
  Id = 'id',
  Ltv = 'ltv',
  RfmStatus = 'rfmStatus',
  Score = 'score',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerAllDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerAllDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** query StoreCustomerBaseSegmentUserData list */
export type StoreCustomerBaseSegmentUserData = {
  __typename?: 'StoreCustomerBaseSegmentUserData';
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  customerEmail?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  customerName?: Maybe<Scalars['String']['output']>;
  customerPhone?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerBaseSegmentUserDataConnection = {
  __typename?: 'StoreCustomerBaseSegmentUserDataConnection';
  /** Array of nodes. */
  nodes: Array<StoreCustomerBaseSegmentUserData>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StoreCustomerBaseSegmentUserDataFilter = {
  and?: InputMaybe<Array<StoreCustomerBaseSegmentUserDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerBaseSegmentUserDataFilter>>;
  score?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<StoreCustomerBaseSegmentUserDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerBaseSegmentUserDataStoreV1IdFilterComparison>;
};

export type StoreCustomerBaseSegmentUserDataSort = {
  direction: SortDirection;
  field: StoreCustomerBaseSegmentUserDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerBaseSegmentUserDataSortFields {
  Id = 'id',
  Score = 'score',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerBaseSegmentUserDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerBaseSegmentUserDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Store Customer Churn Development Data belong paid graph */
export type StoreCustomerChurnDevelopmentData = {
  __typename?: 'StoreCustomerChurnDevelopmentData';
  all?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  pct70?: Maybe<Scalars['Int']['output']>;
  pct79?: Maybe<Scalars['Int']['output']>;
  pct90?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerChurnDevelopmentDataFilter = {
  and?: InputMaybe<Array<StoreCustomerChurnDevelopmentDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerChurnDevelopmentDataFilter>>;
  paidAtDate?: InputMaybe<StoreCustomerChurnDevelopmentDataPaidAtDateFilterComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerChurnDevelopmentDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerChurnDevelopmentDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerChurnDevelopmentDataStoreV1IdFilterComparison>;
};

export type StoreCustomerChurnDevelopmentDataPaidAtDateFilterComparison = {
  between?: InputMaybe<StoreCustomerChurnDevelopmentDataPaidAtDateFilterComparisonBetween>;
};

export type StoreCustomerChurnDevelopmentDataPaidAtDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type StoreCustomerChurnDevelopmentDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerChurnDevelopmentDataSort = {
  direction: SortDirection;
  field: StoreCustomerChurnDevelopmentDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerChurnDevelopmentDataSortFields {
  Id = 'id',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerChurnDevelopmentDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerChurnDevelopmentDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Store Customer Churn Recovered Data */
export type StoreCustomerChurnRecoveredData = {
  __typename?: 'StoreCustomerChurnRecoveredData';
  gte90?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lt70?: Maybe<Scalars['Int']['output']>;
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  recoveredCustomers?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerChurnRecoveredDataFilter = {
  and?: InputMaybe<Array<StoreCustomerChurnRecoveredDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerChurnRecoveredDataFilter>>;
  paidAtDate?: InputMaybe<StoreCustomerChurnRecoveredDataPaidAtDateFilterComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerChurnRecoveredDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerChurnRecoveredDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerChurnRecoveredDataStoreV1IdFilterComparison>;
};

export type StoreCustomerChurnRecoveredDataPaidAtDateFilterComparison = {
  between?: InputMaybe<StoreCustomerChurnRecoveredDataPaidAtDateFilterComparisonBetween>;
};

export type StoreCustomerChurnRecoveredDataPaidAtDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type StoreCustomerChurnRecoveredDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerChurnRecoveredDataSort = {
  direction: SortDirection;
  field: StoreCustomerChurnRecoveredDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerChurnRecoveredDataSortFields {
  Id = 'id',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerChurnRecoveredDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerChurnRecoveredDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Store Customer Churn Risk Data */
export type StoreCustomerChurnRiskData = {
  __typename?: 'StoreCustomerChurnRiskData';
  avg?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerChurnRiskDataFilter = {
  and?: InputMaybe<Array<StoreCustomerChurnRiskDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerChurnRiskDataFilter>>;
  paidAtDate?: InputMaybe<StoreCustomerChurnRiskDataPaidAtDateFilterComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerChurnRiskDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerChurnRiskDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerChurnRiskDataStoreV1IdFilterComparison>;
};

export type StoreCustomerChurnRiskDataPaidAtDateFilterComparison = {
  between?: InputMaybe<StoreCustomerChurnRiskDataPaidAtDateFilterComparisonBetween>;
};

export type StoreCustomerChurnRiskDataPaidAtDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type StoreCustomerChurnRiskDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerChurnRiskDataSort = {
  direction: SortDirection;
  field: StoreCustomerChurnRiskDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerChurnRiskDataSortFields {
  Id = 'id',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerChurnRiskDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerChurnRiskDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Statistics between this and previous purchases for each store */
export type StoreCustomerDevelopmentData = {
  __typename?: 'StoreCustomerDevelopmentData';
  id?: Maybe<Scalars['ID']['output']>;
  newCustomer: Scalars['Int']['output'];
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  totalCustomer: Scalars['Int']['output'];
};

export type StoreCustomerDevelopmentDataFilter = {
  and?: InputMaybe<Array<StoreCustomerDevelopmentDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  newCustomer?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerDevelopmentDataFilter>>;
  paidAtDate?: InputMaybe<StoreCustomerDevelopmentDataPaidAtDateFilterComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerDevelopmentDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerDevelopmentDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerDevelopmentDataStoreV1IdFilterComparison>;
  totalCustomer?: InputMaybe<NumberFieldComparison>;
};

export type StoreCustomerDevelopmentDataPaidAtDateFilterComparison = {
  between?: InputMaybe<StoreCustomerDevelopmentDataPaidAtDateFilterComparisonBetween>;
};

export type StoreCustomerDevelopmentDataPaidAtDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type StoreCustomerDevelopmentDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerDevelopmentDataSort = {
  direction: SortDirection;
  field: StoreCustomerDevelopmentDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerDevelopmentDataSortFields {
  Id = 'id',
  NewCustomer = 'newCustomer',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
  TotalCustomer = 'totalCustomer',
}

export type StoreCustomerDevelopmentDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerDevelopmentDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Store Customer Economics Data */
export type StoreCustomerEconomicsData = {
  __typename?: 'StoreCustomerEconomicsData';
  avgGrossRevenue?: Maybe<Scalars['Float']['output']>;
  avgItemSold?: Maybe<Scalars['Float']['output']>;
  avgNetRevenue?: Maybe<Scalars['Float']['output']>;
  avgOrders?: Maybe<Scalars['Float']['output']>;
  customers?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lifetime?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  withRepurchas?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerEconomicsDataFilter = {
  and?: InputMaybe<Array<StoreCustomerEconomicsDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  lifetime?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerEconomicsDataFilter>>;
  storeId?: InputMaybe<StoreCustomerEconomicsDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerEconomicsDataStoreV1IdFilterComparison>;
};

export type StoreCustomerEconomicsDataSort = {
  direction: SortDirection;
  field: StoreCustomerEconomicsDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerEconomicsDataSortFields {
  Id = 'id',
  Lifetime = 'lifetime',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerEconomicsDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerEconomicsDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** first order data statistic by first order value */
export type StoreCustomerFirstOrderValueData = {
  __typename?: 'StoreCustomerFirstOrderValueData';
  avgOrderCount: Scalars['Float']['output'];
  group: Scalars['Int']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  maxOrderValue: Scalars['Float']['output'];
  minOrderValue: Scalars['Float']['output'];
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerFirstOrderValueDataFilter = {
  and?: InputMaybe<Array<StoreCustomerFirstOrderValueDataFilter>>;
  avgOrderCount?: InputMaybe<FloatFieldComparison>;
  group?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  maxOrderValue?: InputMaybe<FloatFieldComparison>;
  minOrderValue?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerFirstOrderValueDataFilter>>;
  storeId?: InputMaybe<StoreCustomerFirstOrderValueDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerFirstOrderValueDataStoreV1IdFilterComparison>;
};

export type StoreCustomerFirstOrderValueDataSort = {
  direction: SortDirection;
  field: StoreCustomerFirstOrderValueDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerFirstOrderValueDataSortFields {
  AvgOrderCount = 'avgOrderCount',
  Group = 'group',
  Id = 'id',
  MaxOrderValue = 'maxOrderValue',
  MinOrderValue = 'minOrderValue',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerFirstOrderValueDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerFirstOrderValueDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** List the top 10 most frequently purchased items for each order index, 0-all orders, 1-1st order  */
export type StoreCustomerFollowUpPurchaseData = {
  __typename?: 'StoreCustomerFollowUpPurchaseData';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  orderIndexOfPerStore: Scalars['Int']['output'];
  productTitle?: Maybe<Scalars['String']['output']>;
  purchaseFrequency: Scalars['Float']['output'];
  rank: Scalars['Int']['output'];
  sku?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  totalOrdersForEachItmePerIndex: Scalars['Int']['output'];
  totalOrdersPerIndex: Scalars['Int']['output'];
};

export type StoreCustomerFollowUpPurchaseDataFilter = {
  and?: InputMaybe<Array<StoreCustomerFollowUpPurchaseDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  imageUrl?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerFollowUpPurchaseDataFilter>>;
  orderIndexOfPerStore?: InputMaybe<StoreCustomerFollowUpPurchaseDataOrderIndexOfPerStoreFilterComparison>;
  productTitle?: InputMaybe<StringFieldComparison>;
  purchaseFrequency?: InputMaybe<FloatFieldComparison>;
  rank?: InputMaybe<IntFieldComparison>;
  sku?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<StoreCustomerFollowUpPurchaseDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerFollowUpPurchaseDataStoreV1IdFilterComparison>;
  totalOrdersForEachItmePerIndex?: InputMaybe<IntFieldComparison>;
  totalOrdersPerIndex?: InputMaybe<IntFieldComparison>;
};

export type StoreCustomerFollowUpPurchaseDataOrderIndexOfPerStoreFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreCustomerFollowUpPurchaseDataSort = {
  direction: SortDirection;
  field: StoreCustomerFollowUpPurchaseDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerFollowUpPurchaseDataSortFields {
  Id = 'id',
  ImageUrl = 'imageUrl',
  OrderIndexOfPerStore = 'orderIndexOfPerStore',
  ProductTitle = 'productTitle',
  PurchaseFrequency = 'purchaseFrequency',
  Rank = 'rank',
  Sku = 'sku',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
  TotalOrdersForEachItmePerIndex = 'totalOrdersForEachItmePerIndex',
  TotalOrdersPerIndex = 'totalOrdersPerIndex',
}

export type StoreCustomerFollowUpPurchaseDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerFollowUpPurchaseDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** items per basket statistic, Average Item Price, 	Average Order Value  by month,  */
export type StoreCustomerItemsPerBasketData = {
  __typename?: 'StoreCustomerItemsPerBasketData';
  averageItemPrice: Scalars['Float']['output'];
  averageOrderValue: Scalars['Float']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  itemsPerBasket: Scalars['Float']['output'];
  numOfOrders: Scalars['Int']['output'];
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  sumOfGrossItemsSold: Scalars['Float']['output'];
  sumOfGrossRevenue: Scalars['Float']['output'];
};

export type StoreCustomerItemsPerBasketDataFilter = {
  and?: InputMaybe<Array<StoreCustomerItemsPerBasketDataFilter>>;
  averageItemPrice?: InputMaybe<FloatFieldComparison>;
  averageOrderValue?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  itemsPerBasket?: InputMaybe<FloatFieldComparison>;
  numOfOrders?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerItemsPerBasketDataFilter>>;
  paidAtDate?: InputMaybe<DateFieldComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerItemsPerBasketDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerItemsPerBasketDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerItemsPerBasketDataStoreV1IdFilterComparison>;
  sumOfGrossItemsSold?: InputMaybe<FloatFieldComparison>;
  sumOfGrossRevenue?: InputMaybe<FloatFieldComparison>;
};

export type StoreCustomerItemsPerBasketDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerItemsPerBasketDataSort = {
  direction: SortDirection;
  field: StoreCustomerItemsPerBasketDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerItemsPerBasketDataSortFields {
  AverageItemPrice = 'averageItemPrice',
  AverageOrderValue = 'averageOrderValue',
  Id = 'id',
  ItemsPerBasket = 'itemsPerBasket',
  NumOfOrders = 'numOfOrders',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
  SumOfGrossItemsSold = 'sumOfGrossItemsSold',
  SumOfGrossRevenue = 'sumOfGrossRevenue',
}

export type StoreCustomerItemsPerBasketDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerItemsPerBasketDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** query Store Customer Ltv Cohorts Avg Data list */
export type StoreCustomerLtvCohortsAvgData = {
  __typename?: 'StoreCustomerLtvCohortsAvgData';
  id?: Maybe<Scalars['ID']['output']>;
  ltv1y?: Maybe<Scalars['Float']['output']>;
  ltv2y?: Maybe<Scalars['Float']['output']>;
  ltv5y?: Maybe<Scalars['Float']['output']>;
  ltv30d?: Maybe<Scalars['Float']['output']>;
  ltv90d?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerLtvCohortsAvgDataFilter = {
  and?: InputMaybe<Array<StoreCustomerLtvCohortsAvgDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerLtvCohortsAvgDataFilter>>;
  storeId?: InputMaybe<StoreCustomerLtvCohortsAvgDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerLtvCohortsAvgDataStoreV1IdFilterComparison>;
};

export type StoreCustomerLtvCohortsAvgDataSort = {
  direction: SortDirection;
  field: StoreCustomerLtvCohortsAvgDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerLtvCohortsAvgDataSortFields {
  Id = 'id',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerLtvCohortsAvgDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerLtvCohortsAvgDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreCustomerLtvCohortsDto = {
  __typename?: 'StoreCustomerLtvCohortsDto';
  cohortsSize?: Maybe<Scalars['Int']['output']>;
  ltv?: Maybe<Scalars['Float']['output']>;
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
};

/** aov-per-order-count */
export type StoreCustomerOrderAovPerOrderCountData = {
  __typename?: 'StoreCustomerOrderAovPerOrderCountData';
  aov: Scalars['Float']['output'];
  purchase: Scalars['Int']['output'];
  storeV1Id: Scalars['Int']['output'];
  totalOrders: Scalars['Int']['output'];
};

export type StoreCustomerOrderAovPerOrderCountDataFilter = {
  and?: InputMaybe<Array<StoreCustomerOrderAovPerOrderCountDataFilter>>;
  or?: InputMaybe<Array<StoreCustomerOrderAovPerOrderCountDataFilter>>;
  storeV1Id?: InputMaybe<StoreCustomerOrderAovPerOrderCountDataStoreV1IdFilterComparison>;
};

export type StoreCustomerOrderAovPerOrderCountDataSort = {
  direction: SortDirection;
  field: StoreCustomerOrderAovPerOrderCountDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerOrderAovPerOrderCountDataSortFields {
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerOrderAovPerOrderCountDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** avg order amount statistic by date and type */
export type StoreCustomerOrderAvgData = {
  __typename?: 'StoreCustomerOrderAvgData';
  avgTotal: Scalars['Float']['output'];
  firstPaidAvgTotal: Scalars['Float']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  notFirstPaidAvgTotal: Scalars['Float']['output'];
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerOrderAvgDataFilter = {
  and?: InputMaybe<Array<StoreCustomerOrderAvgDataFilter>>;
  avgTotal?: InputMaybe<FloatFieldComparison>;
  firstPaidAvgTotal?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  notFirstPaidAvgTotal?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerOrderAvgDataFilter>>;
  paidAtDate?: InputMaybe<DateFieldComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerOrderAvgDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerOrderAvgDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerOrderAvgDataStoreV1IdFilterComparison>;
};

export type StoreCustomerOrderAvgDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerOrderAvgDataSort = {
  direction: SortDirection;
  field: StoreCustomerOrderAvgDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerOrderAvgDataSortFields {
  AvgTotal = 'avgTotal',
  FirstPaidAvgTotal = 'firstPaidAvgTotal',
  Id = 'id',
  NotFirstPaidAvgTotal = 'notFirstPaidAvgTotal',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerOrderAvgDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerOrderAvgDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Statistics order cohort ltv statistic by date and type */
export type StoreCustomerOrderCohortLtvData = {
  __typename?: 'StoreCustomerOrderCohortLtvData';
  cohortsSize: Scalars['Int']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  ltvTotal1Years: Scalars['Float']['output'];
  ltvTotal2Years: Scalars['Float']['output'];
  ltvTotal5Years: Scalars['Float']['output'];
  ltvTotal30Days: Scalars['Float']['output'];
  ltvTotal90Days: Scalars['Float']['output'];
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerOrderCohortLtvDataFilter = {
  and?: InputMaybe<Array<StoreCustomerOrderCohortLtvDataFilter>>;
  cohortsSize?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  ltvTotal1Years?: InputMaybe<FloatFieldComparison>;
  ltvTotal2Years?: InputMaybe<FloatFieldComparison>;
  ltvTotal5Years?: InputMaybe<FloatFieldComparison>;
  ltvTotal30Days?: InputMaybe<FloatFieldComparison>;
  ltvTotal90Days?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerOrderCohortLtvDataFilter>>;
  paidAtDate?: InputMaybe<DateFieldComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerOrderCohortLtvDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerOrderCohortLtvDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerOrderCohortLtvDataStoreV1IdFilterComparison>;
};

export type StoreCustomerOrderCohortLtvDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerOrderCohortLtvDataSort = {
  direction: SortDirection;
  field: StoreCustomerOrderCohortLtvDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerOrderCohortLtvDataSortFields {
  CohortsSize = 'cohortsSize',
  Id = 'id',
  LtvTotal1Years = 'ltvTotal1Years',
  LtvTotal2Years = 'ltvTotal2Years',
  LtvTotal5Years = 'ltvTotal5Years',
  LtvTotal30Days = 'ltvTotal30Days',
  LtvTotal90Days = 'ltvTotal90Days',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerOrderCohortLtvDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerOrderCohortLtvDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Statistics order daily ltv statistic by date and type */
export type StoreCustomerOrderDailyLtvData = {
  __typename?: 'StoreCustomerOrderDailyLtvData';
  altv1Years?: Maybe<Scalars['Float']['output']>;
  altv2Years?: Maybe<Scalars['Float']['output']>;
  altv5Years?: Maybe<Scalars['Float']['output']>;
  altv30Days?: Maybe<Scalars['Float']['output']>;
  altv90Days?: Maybe<Scalars['Float']['output']>;
  customerCount1Years?: Maybe<Scalars['Int']['output']>;
  customerCount2Years?: Maybe<Scalars['Int']['output']>;
  customerCount5Years?: Maybe<Scalars['Int']['output']>;
  customerCount30Days?: Maybe<Scalars['Int']['output']>;
  customerCount90Days?: Maybe<Scalars['Int']['output']>;
  date: Scalars['DateTime']['output'];
  dateType: BiDateType;
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerOrderDailyLtvDataDateFilterComparison = {
  between?: InputMaybe<StoreCustomerOrderDailyLtvDataDateFilterComparisonBetween>;
};

export type StoreCustomerOrderDailyLtvDataDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type StoreCustomerOrderDailyLtvDataDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerOrderDailyLtvDataFilter = {
  altv1Years?: InputMaybe<FloatFieldComparison>;
  altv2Years?: InputMaybe<FloatFieldComparison>;
  altv5Years?: InputMaybe<FloatFieldComparison>;
  altv30Days?: InputMaybe<FloatFieldComparison>;
  altv90Days?: InputMaybe<FloatFieldComparison>;
  and?: InputMaybe<Array<StoreCustomerOrderDailyLtvDataFilter>>;
  customerCount1Years?: InputMaybe<IntFieldComparison>;
  customerCount2Years?: InputMaybe<IntFieldComparison>;
  customerCount5Years?: InputMaybe<IntFieldComparison>;
  customerCount30Days?: InputMaybe<IntFieldComparison>;
  customerCount90Days?: InputMaybe<IntFieldComparison>;
  date?: InputMaybe<StoreCustomerOrderDailyLtvDataDateFilterComparison>;
  dateType?: InputMaybe<StoreCustomerOrderDailyLtvDataDateTypeFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerOrderDailyLtvDataFilter>>;
  storeId?: InputMaybe<StoreCustomerOrderDailyLtvDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerOrderDailyLtvDataStoreV1IdFilterComparison>;
};

export type StoreCustomerOrderDailyLtvDataSort = {
  direction: SortDirection;
  field: StoreCustomerOrderDailyLtvDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerOrderDailyLtvDataSortFields {
  Altv1Years = 'altv1Years',
  Altv2Years = 'altv2Years',
  Altv5Years = 'altv5Years',
  Altv30Days = 'altv30Days',
  Altv90Days = 'altv90Days',
  CustomerCount1Years = 'customerCount1Years',
  CustomerCount2Years = 'customerCount2Years',
  CustomerCount5Years = 'customerCount5Years',
  CustomerCount30Days = 'customerCount30Days',
  CustomerCount90Days = 'customerCount90Days',
  Date = 'date',
  DateType = 'dateType',
  Id = 'id',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerOrderDailyLtvDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerOrderDailyLtvDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** customer order datetime expanded data */
export type StoreCustomerOrderDatetimePartData = {
  __typename?: 'StoreCustomerOrderDatetimePartData';
  dayOfMonth: Scalars['Int']['output'];
  dayOfWeek: Scalars['Int']['output'];
  hour: Scalars['Int']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  month: Scalars['Int']['output'];
  orderIndexOfPerStore: Scalars['Int']['output'];
  paidAt: Scalars['DateTime']['output'];
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerOrderDatetimePartDataAggregateFilter = {
  and?: InputMaybe<Array<StoreCustomerOrderDatetimePartDataAggregateFilter>>;
  dayOfMonth?: InputMaybe<IntFieldComparison>;
  dayOfWeek?: InputMaybe<IntFieldComparison>;
  hour?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  month?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerOrderDatetimePartDataAggregateFilter>>;
  orderIndexOfPerStore?: InputMaybe<IntFieldComparison>;
  paidAt?: InputMaybe<DateFieldComparison>;
  storeId?: InputMaybe<StoreCustomerOrderDatetimePartDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerOrderDatetimePartDataStoreV1IdFilterComparison>;
};

export type StoreCustomerOrderDatetimePartDataAggregateGroupBy = {
  __typename?: 'StoreCustomerOrderDatetimePartDataAggregateGroupBy';
  dayOfMonth?: Maybe<Scalars['Int']['output']>;
  dayOfWeek?: Maybe<Scalars['Int']['output']>;
  hour?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  month?: Maybe<Scalars['Int']['output']>;
  orderIndexOfPerStore?: Maybe<Scalars['Int']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerOrderDatetimePartDataAggregateGroupByPaidAtArgs = {
  by?: GroupBy;
};

export type StoreCustomerOrderDatetimePartDataAggregateResponse = {
  __typename?: 'StoreCustomerOrderDatetimePartDataAggregateResponse';
  avg?: Maybe<StoreCustomerOrderDatetimePartDataAvgAggregate>;
  count?: Maybe<StoreCustomerOrderDatetimePartDataCountAggregate>;
  groupBy?: Maybe<StoreCustomerOrderDatetimePartDataAggregateGroupBy>;
  max?: Maybe<StoreCustomerOrderDatetimePartDataMaxAggregate>;
  min?: Maybe<StoreCustomerOrderDatetimePartDataMinAggregate>;
  sum?: Maybe<StoreCustomerOrderDatetimePartDataSumAggregate>;
};

export type StoreCustomerOrderDatetimePartDataAvgAggregate = {
  __typename?: 'StoreCustomerOrderDatetimePartDataAvgAggregate';
  dayOfMonth?: Maybe<Scalars['Float']['output']>;
  dayOfWeek?: Maybe<Scalars['Float']['output']>;
  hour?: Maybe<Scalars['Float']['output']>;
  month?: Maybe<Scalars['Float']['output']>;
  orderIndexOfPerStore?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type StoreCustomerOrderDatetimePartDataCountAggregate = {
  __typename?: 'StoreCustomerOrderDatetimePartDataCountAggregate';
  dayOfMonth?: Maybe<Scalars['Int']['output']>;
  dayOfWeek?: Maybe<Scalars['Int']['output']>;
  hour?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  month?: Maybe<Scalars['Int']['output']>;
  orderIndexOfPerStore?: Maybe<Scalars['Int']['output']>;
  paidAt?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerOrderDatetimePartDataFilter = {
  and?: InputMaybe<Array<StoreCustomerOrderDatetimePartDataFilter>>;
  dayOfMonth?: InputMaybe<IntFieldComparison>;
  dayOfWeek?: InputMaybe<IntFieldComparison>;
  hour?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  month?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerOrderDatetimePartDataFilter>>;
  orderIndexOfPerStore?: InputMaybe<IntFieldComparison>;
  paidAt?: InputMaybe<DateFieldComparison>;
  storeId?: InputMaybe<StoreCustomerOrderDatetimePartDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerOrderDatetimePartDataStoreV1IdFilterComparison>;
};

export type StoreCustomerOrderDatetimePartDataMaxAggregate = {
  __typename?: 'StoreCustomerOrderDatetimePartDataMaxAggregate';
  dayOfMonth?: Maybe<Scalars['Int']['output']>;
  dayOfWeek?: Maybe<Scalars['Int']['output']>;
  hour?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  month?: Maybe<Scalars['Int']['output']>;
  orderIndexOfPerStore?: Maybe<Scalars['Int']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerOrderDatetimePartDataMinAggregate = {
  __typename?: 'StoreCustomerOrderDatetimePartDataMinAggregate';
  dayOfMonth?: Maybe<Scalars['Int']['output']>;
  dayOfWeek?: Maybe<Scalars['Int']['output']>;
  hour?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  month?: Maybe<Scalars['Int']['output']>;
  orderIndexOfPerStore?: Maybe<Scalars['Int']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerOrderDatetimePartDataSort = {
  direction: SortDirection;
  field: StoreCustomerOrderDatetimePartDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerOrderDatetimePartDataSortFields {
  DayOfMonth = 'dayOfMonth',
  DayOfWeek = 'dayOfWeek',
  Hour = 'hour',
  Id = 'id',
  Month = 'month',
  OrderIndexOfPerStore = 'orderIndexOfPerStore',
  PaidAt = 'paidAt',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerOrderDatetimePartDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerOrderDatetimePartDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreCustomerOrderDatetimePartDataSumAggregate = {
  __typename?: 'StoreCustomerOrderDatetimePartDataSumAggregate';
  dayOfMonth?: Maybe<Scalars['Float']['output']>;
  dayOfWeek?: Maybe<Scalars['Float']['output']>;
  hour?: Maybe<Scalars['Float']['output']>;
  month?: Maybe<Scalars['Float']['output']>;
  orderIndexOfPerStore?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** Statistics first paid order statistic by date and type */
export type StoreCustomerOrderFirstPaidData = {
  __typename?: 'StoreCustomerOrderFirstPaidData';
  count: Scalars['Int']['output'];
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  storeId: Scalars['ID']['output'];
};

export type StoreCustomerOrderFirstPaidDataFilter = {
  and?: InputMaybe<Array<StoreCustomerOrderFirstPaidDataFilter>>;
  or?: InputMaybe<Array<StoreCustomerOrderFirstPaidDataFilter>>;
  paidAtDate?: InputMaybe<DateFieldComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerOrderFirstPaidDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerOrderFirstPaidDataStoreIdFilterComparison>;
};

export type StoreCustomerOrderFirstPaidDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerOrderFirstPaidDataSort = {
  direction: SortDirection;
  field: StoreCustomerOrderFirstPaidDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerOrderFirstPaidDataSortFields {
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
}

export type StoreCustomerOrderFirstPaidDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

/** sold order item return item statistic by date and type */
export type StoreCustomerOrderItemReturnItemData = {
  __typename?: 'StoreCustomerOrderItemReturnItemData';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  jewelryId?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  returnCount?: Maybe<Scalars['Int']['output']>;
  returnRate?: Maybe<Scalars['Float']['output']>;
  returnRevenue?: Maybe<Scalars['Float']['output']>;
  returnRevenueRate?: Maybe<Scalars['Float']['output']>;
  soldCount?: Maybe<Scalars['Int']['output']>;
  soldRevenue?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type StoreCustomerOrderItemReturnItemDataAggregateFilter = {
  and?: InputMaybe<Array<StoreCustomerOrderItemReturnItemDataAggregateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  imageUrl?: InputMaybe<StringFieldComparison>;
  jewelryId?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerOrderItemReturnItemDataAggregateFilter>>;
  paidAtDate?: InputMaybe<DateFieldComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerOrderItemReturnItemDataPaidAtDateTypeFilterComparison>;
  returnCount?: InputMaybe<IntFieldComparison>;
  returnRate?: InputMaybe<FloatFieldComparison>;
  returnRevenue?: InputMaybe<FloatFieldComparison>;
  returnRevenueRate?: InputMaybe<FloatFieldComparison>;
  soldCount?: InputMaybe<IntFieldComparison>;
  soldRevenue?: InputMaybe<FloatFieldComparison>;
  storeId?: InputMaybe<StoreCustomerOrderItemReturnItemDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerOrderItemReturnItemDataStoreV1IdFilterComparison>;
  title?: InputMaybe<StringFieldComparison>;
};

export type StoreCustomerOrderItemReturnItemDataAggregateGroupBy = {
  __typename?: 'StoreCustomerOrderItemReturnItemDataAggregateGroupBy';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  jewelryId?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  returnCount?: Maybe<Scalars['Int']['output']>;
  returnRate?: Maybe<Scalars['Float']['output']>;
  returnRevenue?: Maybe<Scalars['Float']['output']>;
  returnRevenueRate?: Maybe<Scalars['Float']['output']>;
  soldCount?: Maybe<Scalars['Int']['output']>;
  soldRevenue?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StoreCustomerOrderItemReturnItemDataAggregateGroupByPaidAtDateArgs = {
  by?: GroupBy;
};

export type StoreCustomerOrderItemReturnItemDataAggregateResponse = {
  __typename?: 'StoreCustomerOrderItemReturnItemDataAggregateResponse';
  avg?: Maybe<StoreCustomerOrderItemReturnItemDataAvgAggregate>;
  count?: Maybe<StoreCustomerOrderItemReturnItemDataCountAggregate>;
  groupBy?: Maybe<StoreCustomerOrderItemReturnItemDataAggregateGroupBy>;
  max?: Maybe<StoreCustomerOrderItemReturnItemDataMaxAggregate>;
  min?: Maybe<StoreCustomerOrderItemReturnItemDataMinAggregate>;
  sum?: Maybe<StoreCustomerOrderItemReturnItemDataSumAggregate>;
};

export type StoreCustomerOrderItemReturnItemDataAvgAggregate = {
  __typename?: 'StoreCustomerOrderItemReturnItemDataAvgAggregate';
  jewelryId?: Maybe<Scalars['Float']['output']>;
  returnCount?: Maybe<Scalars['Float']['output']>;
  returnRate?: Maybe<Scalars['Float']['output']>;
  returnRevenue?: Maybe<Scalars['Float']['output']>;
  returnRevenueRate?: Maybe<Scalars['Float']['output']>;
  soldCount?: Maybe<Scalars['Float']['output']>;
  soldRevenue?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type StoreCustomerOrderItemReturnItemDataConnection = {
  __typename?: 'StoreCustomerOrderItemReturnItemDataConnection';
  /** Array of nodes. */
  nodes: Array<StoreCustomerOrderItemReturnItemData>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type StoreCustomerOrderItemReturnItemDataCountAggregate = {
  __typename?: 'StoreCustomerOrderItemReturnItemDataCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Scalars['Int']['output']>;
  jewelryId?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['Int']['output']>;
  paidAtDateType?: Maybe<Scalars['Int']['output']>;
  returnCount?: Maybe<Scalars['Int']['output']>;
  returnRate?: Maybe<Scalars['Int']['output']>;
  returnRevenue?: Maybe<Scalars['Int']['output']>;
  returnRevenueRate?: Maybe<Scalars['Int']['output']>;
  soldCount?: Maybe<Scalars['Int']['output']>;
  soldRevenue?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerOrderItemReturnItemDataFilter = {
  and?: InputMaybe<Array<StoreCustomerOrderItemReturnItemDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  imageUrl?: InputMaybe<StringFieldComparison>;
  jewelryId?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerOrderItemReturnItemDataFilter>>;
  paidAtDate?: InputMaybe<DateFieldComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerOrderItemReturnItemDataPaidAtDateTypeFilterComparison>;
  returnCount?: InputMaybe<IntFieldComparison>;
  returnRate?: InputMaybe<FloatFieldComparison>;
  returnRevenue?: InputMaybe<FloatFieldComparison>;
  returnRevenueRate?: InputMaybe<FloatFieldComparison>;
  soldCount?: InputMaybe<IntFieldComparison>;
  soldRevenue?: InputMaybe<FloatFieldComparison>;
  storeId?: InputMaybe<StoreCustomerOrderItemReturnItemDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerOrderItemReturnItemDataStoreV1IdFilterComparison>;
  title?: InputMaybe<StringFieldComparison>;
};

export type StoreCustomerOrderItemReturnItemDataMaxAggregate = {
  __typename?: 'StoreCustomerOrderItemReturnItemDataMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  jewelryId?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  returnCount?: Maybe<Scalars['Int']['output']>;
  returnRate?: Maybe<Scalars['Float']['output']>;
  returnRevenue?: Maybe<Scalars['Float']['output']>;
  returnRevenueRate?: Maybe<Scalars['Float']['output']>;
  soldCount?: Maybe<Scalars['Int']['output']>;
  soldRevenue?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StoreCustomerOrderItemReturnItemDataMinAggregate = {
  __typename?: 'StoreCustomerOrderItemReturnItemDataMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  jewelryId?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  returnCount?: Maybe<Scalars['Int']['output']>;
  returnRate?: Maybe<Scalars['Float']['output']>;
  returnRevenue?: Maybe<Scalars['Float']['output']>;
  returnRevenueRate?: Maybe<Scalars['Float']['output']>;
  soldCount?: Maybe<Scalars['Int']['output']>;
  soldRevenue?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StoreCustomerOrderItemReturnItemDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerOrderItemReturnItemDataSort = {
  direction: SortDirection;
  field: StoreCustomerOrderItemReturnItemDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerOrderItemReturnItemDataSortFields {
  Id = 'id',
  ImageUrl = 'imageUrl',
  JewelryId = 'jewelryId',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  ReturnCount = 'returnCount',
  ReturnRate = 'returnRate',
  ReturnRevenue = 'returnRevenue',
  ReturnRevenueRate = 'returnRevenueRate',
  SoldCount = 'soldCount',
  SoldRevenue = 'soldRevenue',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
  Title = 'title',
}

export type StoreCustomerOrderItemReturnItemDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerOrderItemReturnItemDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreCustomerOrderItemReturnItemDataSumAggregate = {
  __typename?: 'StoreCustomerOrderItemReturnItemDataSumAggregate';
  jewelryId?: Maybe<Scalars['Float']['output']>;
  returnCount?: Maybe<Scalars['Float']['output']>;
  returnRate?: Maybe<Scalars['Float']['output']>;
  returnRevenue?: Maybe<Scalars['Float']['output']>;
  returnRevenueRate?: Maybe<Scalars['Float']['output']>;
  soldCount?: Maybe<Scalars['Float']['output']>;
  soldRevenue?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** sold order item return rate statistic by date and type */
export type StoreCustomerOrderItemReturnRateData = {
  __typename?: 'StoreCustomerOrderItemReturnRateData';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  returnCount: Scalars['Int']['output'];
  returnRate: Scalars['Float']['output'];
  returnRevenue: Scalars['Float']['output'];
  returnRevenueRate: Scalars['Float']['output'];
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerOrderItemReturnRateDataFilter = {
  and?: InputMaybe<Array<StoreCustomerOrderItemReturnRateDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerOrderItemReturnRateDataFilter>>;
  paidAtDate?: InputMaybe<DateFieldComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerOrderItemReturnRateDataPaidAtDateTypeFilterComparison>;
  returnCount?: InputMaybe<IntFieldComparison>;
  returnRate?: InputMaybe<FloatFieldComparison>;
  returnRevenue?: InputMaybe<FloatFieldComparison>;
  returnRevenueRate?: InputMaybe<FloatFieldComparison>;
  storeId?: InputMaybe<StoreCustomerOrderItemReturnRateDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerOrderItemReturnRateDataStoreV1IdFilterComparison>;
};

export type StoreCustomerOrderItemReturnRateDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerOrderItemReturnRateDataSort = {
  direction: SortDirection;
  field: StoreCustomerOrderItemReturnRateDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerOrderItemReturnRateDataSortFields {
  Id = 'id',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  ReturnCount = 'returnCount',
  ReturnRate = 'returnRate',
  ReturnRevenue = 'returnRevenue',
  ReturnRevenueRate = 'returnRevenueRate',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerOrderItemReturnRateDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerOrderItemReturnRateDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** sold order item statistic by date and type */
export type StoreCustomerOrderItemSoldData = {
  __typename?: 'StoreCustomerOrderItemSoldData';
  id?: Maybe<Scalars['ID']['output']>;
  itemCount: Scalars['Int']['output'];
  itemCountPerOrder: Scalars['Float']['output'];
  itemPerPrice: Scalars['Float']['output'];
  orderCount: Scalars['Int']['output'];
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerOrderItemSoldDataFilter = {
  and?: InputMaybe<Array<StoreCustomerOrderItemSoldDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  itemCount?: InputMaybe<IntFieldComparison>;
  itemCountPerOrder?: InputMaybe<FloatFieldComparison>;
  itemPerPrice?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerOrderItemSoldDataFilter>>;
  orderCount?: InputMaybe<IntFieldComparison>;
  paidAtDate?: InputMaybe<DateFieldComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerOrderItemSoldDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerOrderItemSoldDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerOrderItemSoldDataStoreV1IdFilterComparison>;
};

export type StoreCustomerOrderItemSoldDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerOrderItemSoldDataSort = {
  direction: SortDirection;
  field: StoreCustomerOrderItemSoldDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerOrderItemSoldDataSortFields {
  Id = 'id',
  ItemCount = 'itemCount',
  ItemCountPerOrder = 'itemCountPerOrder',
  ItemPerPrice = 'itemPerPrice',
  OrderCount = 'orderCount',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerOrderItemSoldDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerOrderItemSoldDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Statistics not first paid order statistic by date and type */
export type StoreCustomerOrderNotFirstPaidData = {
  __typename?: 'StoreCustomerOrderNotFirstPaidData';
  count: Scalars['Int']['output'];
  monthInterval: Scalars['Int']['output'];
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  storeId: Scalars['ID']['output'];
};

export type StoreCustomerOrderNotFirstPaidDataFilter = {
  and?: InputMaybe<Array<StoreCustomerOrderNotFirstPaidDataFilter>>;
  monthInterval?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerOrderNotFirstPaidDataFilter>>;
  paidAtDate?: InputMaybe<DateFieldComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerOrderNotFirstPaidDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerOrderNotFirstPaidDataStoreIdFilterComparison>;
};

export type StoreCustomerOrderNotFirstPaidDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerOrderNotFirstPaidDataSort = {
  direction: SortDirection;
  field: StoreCustomerOrderNotFirstPaidDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerOrderNotFirstPaidDataSortFields {
  MonthInterval = 'monthInterval',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
}

export type StoreCustomerOrderNotFirstPaidDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

/** Statistics by paidAtDateType */
export type StoreCustomerOrderZoneData = {
  __typename?: 'StoreCustomerOrderZoneData';
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  newCustomer?: Maybe<Scalars['Int']['output']>;
  orderCount?: Maybe<Scalars['Int']['output']>;
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerOrderZoneDataCityFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
};

export type StoreCustomerOrderZoneDataCountryCodeFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
};

export type StoreCustomerOrderZoneDataFilter = {
  and?: InputMaybe<Array<StoreCustomerOrderZoneDataFilter>>;
  city?: InputMaybe<StoreCustomerOrderZoneDataCityFilterComparison>;
  countryCode?: InputMaybe<StoreCustomerOrderZoneDataCountryCodeFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerOrderZoneDataFilter>>;
  paidAtDate?: InputMaybe<StoreCustomerOrderZoneDataPaidAtDateFilterComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerOrderZoneDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerOrderZoneDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerOrderZoneDataStoreV1IdFilterComparison>;
};

export type StoreCustomerOrderZoneDataPaidAtDateFilterComparison = {
  between?: InputMaybe<StoreCustomerOrderZoneDataPaidAtDateFilterComparisonBetween>;
};

export type StoreCustomerOrderZoneDataPaidAtDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type StoreCustomerOrderZoneDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerOrderZoneDataSort = {
  direction: SortDirection;
  field: StoreCustomerOrderZoneDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerOrderZoneDataSortFields {
  City = 'city',
  CountryCode = 'countryCode',
  Id = 'id',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerOrderZoneDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerOrderZoneDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Percentile Analysis statistics of customers for each store */
export type StoreCustomerPercentileAnalysisData = {
  __typename?: 'StoreCustomerPercentileAnalysisData';
  feature: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  tile: Scalars['Int']['output'];
  val?: Maybe<Scalars['Float']['output']>;
};

export type StoreCustomerPercentileAnalysisDataFilter = {
  and?: InputMaybe<Array<StoreCustomerPercentileAnalysisDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerPercentileAnalysisDataFilter>>;
  storeId?: InputMaybe<StoreCustomerPercentileAnalysisDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerPercentileAnalysisDataStoreV1IdFilterComparison>;
};

export type StoreCustomerPercentileAnalysisDataSort = {
  direction: SortDirection;
  field: StoreCustomerPercentileAnalysisDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerPercentileAnalysisDataSortFields {
  Id = 'id',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerPercentileAnalysisDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerPercentileAnalysisDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreCustomerPersonaDto = {
  __typename?: 'StoreCustomerPersonaDto';
  aov?: Maybe<Scalars['Float']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  daySincelastOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  items?: Maybe<Scalars['Int']['output']>;
  lifetime?: Maybe<Scalars['Int']['output']>;
  ltv?: Maybe<Scalars['Float']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  productReturnRate?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

/** Statistics between this and previous purchases for each store */
export type StoreCustomerPurchaseIntervalData = {
  __typename?: 'StoreCustomerPurchaseIntervalData';
  /** average days of two purchases */
  averageDays?: Maybe<Scalars['Float']['output']>;
  /** rate of successful purchased customers */
  completeRate?: Maybe<Scalars['Float']['output']>;
  /** number of repeat purchased customers */
  customerCount: Scalars['Int']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  /** median days of two purchases */
  medianDays?: Maybe<Scalars['Float']['output']>;
  preCount?: Maybe<Scalars['Int']['output']>;
  /** rate of repeat purchased customers */
  repeatRate?: Maybe<Scalars['Float']['output']>;
  /**
   * sequence number of interval data
   * e.g. sn: 1 means 1st to 2nd
   */
  sn: Scalars['Int']['output'];
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type StoreCustomerPurchaseIntervalDataFilter = {
  and?: InputMaybe<Array<StoreCustomerPurchaseIntervalDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerPurchaseIntervalDataFilter>>;
  sn?: InputMaybe<StoreCustomerPurchaseIntervalDataSnFilterComparison>;
  storeId?: InputMaybe<StoreCustomerPurchaseIntervalDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerPurchaseIntervalDataStoreV1IdFilterComparison>;
};

export type StoreCustomerPurchaseIntervalDataSnFilterComparison = {
  between?: InputMaybe<StoreCustomerPurchaseIntervalDataSnFilterComparisonBetween>;
};

export type StoreCustomerPurchaseIntervalDataSnFilterComparisonBetween = {
  lower: Scalars['Int']['input'];
  upper: Scalars['Int']['input'];
};

export type StoreCustomerPurchaseIntervalDataSort = {
  direction: SortDirection;
  field: StoreCustomerPurchaseIntervalDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerPurchaseIntervalDataSortFields {
  Id = 'id',
  Sn = 'sn',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerPurchaseIntervalDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerPurchaseIntervalDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Statistics by paidAtDateType */
export type StoreCustomerPurchaseStatData = {
  __typename?: 'StoreCustomerPurchaseStatData';
  id?: Maybe<Scalars['ID']['output']>;
  /** number of new customers */
  newCustomer: Scalars['Int']['output'];
  /** rate of new customers */
  newCustomerPercent?: Maybe<Scalars['Float']['output']>;
  /** the start date of period */
  paidAtDate: Scalars['DateTime']['output'];
  /** the unit of period */
  paidAtDateType: BiDateType;
  /** number of repeat customers */
  repeatCustomer: Scalars['Int']['output'];
  /** rate of repeat customers */
  repeatCustomerPercent?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerPurchaseStatDataFilter = {
  and?: InputMaybe<Array<StoreCustomerPurchaseStatDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerPurchaseStatDataFilter>>;
  paidAtDate?: InputMaybe<StoreCustomerPurchaseStatDataPaidAtDateFilterComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerPurchaseStatDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerPurchaseStatDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerPurchaseStatDataStoreV1IdFilterComparison>;
};

export type StoreCustomerPurchaseStatDataPaidAtDateFilterComparison = {
  between?: InputMaybe<StoreCustomerPurchaseStatDataPaidAtDateFilterComparisonBetween>;
};

export type StoreCustomerPurchaseStatDataPaidAtDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type StoreCustomerPurchaseStatDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerPurchaseStatDataSort = {
  direction: SortDirection;
  field: StoreCustomerPurchaseStatDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerPurchaseStatDataSortFields {
  Id = 'id',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerPurchaseStatDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerPurchaseStatDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** group by score */
export type StoreCustomerRfmScoreData = {
  __typename?: 'StoreCustomerRFMScoreData';
  customerId: Scalars['Int']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerRfmScoreDataAggregateFilter = {
  and?: InputMaybe<Array<StoreCustomerRfmScoreDataAggregateFilter>>;
  customerId?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerRfmScoreDataAggregateFilter>>;
  score?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<StoreCustomerRfmScoreDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerRfmScoreDataStoreV1IdFilterComparison>;
};

export type StoreCustomerRfmScoreDataAggregateGroupBy = {
  __typename?: 'StoreCustomerRFMScoreDataAggregateGroupBy';
  customerId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerRfmScoreDataAggregateResponse = {
  __typename?: 'StoreCustomerRFMScoreDataAggregateResponse';
  avg?: Maybe<StoreCustomerRfmScoreDataAvgAggregate>;
  count?: Maybe<StoreCustomerRfmScoreDataCountAggregate>;
  groupBy?: Maybe<StoreCustomerRfmScoreDataAggregateGroupBy>;
  max?: Maybe<StoreCustomerRfmScoreDataMaxAggregate>;
  min?: Maybe<StoreCustomerRfmScoreDataMinAggregate>;
  sum?: Maybe<StoreCustomerRfmScoreDataSumAggregate>;
};

export type StoreCustomerRfmScoreDataAvgAggregate = {
  __typename?: 'StoreCustomerRFMScoreDataAvgAggregate';
  customerId?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type StoreCustomerRfmScoreDataCountAggregate = {
  __typename?: 'StoreCustomerRFMScoreDataCountAggregate';
  customerId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerRfmScoreDataFilter = {
  and?: InputMaybe<Array<StoreCustomerRfmScoreDataFilter>>;
  customerId?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerRfmScoreDataFilter>>;
  score?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<StoreCustomerRfmScoreDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerRfmScoreDataStoreV1IdFilterComparison>;
};

export type StoreCustomerRfmScoreDataMaxAggregate = {
  __typename?: 'StoreCustomerRFMScoreDataMaxAggregate';
  customerId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerRfmScoreDataMinAggregate = {
  __typename?: 'StoreCustomerRFMScoreDataMinAggregate';
  customerId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerRfmScoreDataSort = {
  direction: SortDirection;
  field: StoreCustomerRfmScoreDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerRfmScoreDataSortFields {
  CustomerId = 'customerId',
  Id = 'id',
  Score = 'score',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerRfmScoreDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerRfmScoreDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreCustomerRfmScoreDataSumAggregate = {
  __typename?: 'StoreCustomerRFMScoreDataSumAggregate';
  customerId?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** Statistics by paidAtDateType */
export type StoreCustomerRepeatPurchaseDevelopmentData = {
  __typename?: 'StoreCustomerRepeatPurchaseDevelopmentData';
  id?: Maybe<Scalars['ID']['output']>;
  newCustomer?: Maybe<Scalars['Int']['output']>;
  newCustomerPercent?: Maybe<Scalars['Float']['output']>;
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  repeatCustomer?: Maybe<Scalars['Int']['output']>;
  repeatCustomerPercent?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  totalCustomer?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataAggregateFilter = {
  and?: InputMaybe<Array<StoreCustomerRepeatPurchaseDevelopmentDataAggregateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerRepeatPurchaseDevelopmentDataAggregateFilter>>;
  paidAtDate?: InputMaybe<StoreCustomerRepeatPurchaseDevelopmentDataPaidAtDateFilterComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerRepeatPurchaseDevelopmentDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerRepeatPurchaseDevelopmentDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerRepeatPurchaseDevelopmentDataStoreV1IdFilterComparison>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataAggregateGroupBy = {
  __typename?: 'StoreCustomerRepeatPurchaseDevelopmentDataAggregateGroupBy';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataAggregateGroupByPaidAtDateArgs = {
  by?: GroupBy;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataAggregateResponse = {
  __typename?: 'StoreCustomerRepeatPurchaseDevelopmentDataAggregateResponse';
  avg?: Maybe<StoreCustomerRepeatPurchaseDevelopmentDataAvgAggregate>;
  count?: Maybe<StoreCustomerRepeatPurchaseDevelopmentDataCountAggregate>;
  groupBy?: Maybe<StoreCustomerRepeatPurchaseDevelopmentDataAggregateGroupBy>;
  max?: Maybe<StoreCustomerRepeatPurchaseDevelopmentDataMaxAggregate>;
  min?: Maybe<StoreCustomerRepeatPurchaseDevelopmentDataMinAggregate>;
  sum?: Maybe<StoreCustomerRepeatPurchaseDevelopmentDataSumAggregate>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataAvgAggregate = {
  __typename?: 'StoreCustomerRepeatPurchaseDevelopmentDataAvgAggregate';
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataCountAggregate = {
  __typename?: 'StoreCustomerRepeatPurchaseDevelopmentDataCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['Int']['output']>;
  paidAtDateType?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataFilter = {
  and?: InputMaybe<Array<StoreCustomerRepeatPurchaseDevelopmentDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreCustomerRepeatPurchaseDevelopmentDataFilter>>;
  paidAtDate?: InputMaybe<StoreCustomerRepeatPurchaseDevelopmentDataPaidAtDateFilterComparison>;
  paidAtDateType?: InputMaybe<StoreCustomerRepeatPurchaseDevelopmentDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreCustomerRepeatPurchaseDevelopmentDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerRepeatPurchaseDevelopmentDataStoreV1IdFilterComparison>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataMaxAggregate = {
  __typename?: 'StoreCustomerRepeatPurchaseDevelopmentDataMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataMinAggregate = {
  __typename?: 'StoreCustomerRepeatPurchaseDevelopmentDataMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataPaidAtDateFilterComparison = {
  between?: InputMaybe<StoreCustomerRepeatPurchaseDevelopmentDataPaidAtDateFilterComparisonBetween>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataPaidAtDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type StoreCustomerRepeatPurchaseDevelopmentDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataSort = {
  direction: SortDirection;
  field: StoreCustomerRepeatPurchaseDevelopmentDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerRepeatPurchaseDevelopmentDataSortFields {
  Id = 'id',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerRepeatPurchaseDevelopmentDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreCustomerRepeatPurchaseDevelopmentDataSumAggregate = {
  __typename?: 'StoreCustomerRepeatPurchaseDevelopmentDataSumAggregate';
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** query by paidAtDate */
export type StoreCustomerZoneEverydayData = {
  __typename?: 'StoreCustomerZoneEverydayData';
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  newCustomer: Scalars['Int']['output'];
  orderCount: Scalars['Int']['output'];
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreCustomerZoneEverydayDataAggregateFilter = {
  and?: InputMaybe<Array<StoreCustomerZoneEverydayDataAggregateFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  countryCode?: InputMaybe<StoreCustomerZoneEverydayDataCountryCodeFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  newCustomer?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerZoneEverydayDataAggregateFilter>>;
  orderCount?: InputMaybe<IntFieldComparison>;
  paidAtDate?: InputMaybe<StoreCustomerZoneEverydayDataPaidAtDateFilterComparison>;
  storeId?: InputMaybe<StoreCustomerZoneEverydayDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerZoneEverydayDataStoreV1IdFilterComparison>;
};

export type StoreCustomerZoneEverydayDataAggregateGroupBy = {
  __typename?: 'StoreCustomerZoneEverydayDataAggregateGroupBy';
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  newCustomer?: Maybe<Scalars['Int']['output']>;
  orderCount?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerZoneEverydayDataAggregateGroupByPaidAtDateArgs = {
  by?: GroupBy;
};

export type StoreCustomerZoneEverydayDataAggregateResponse = {
  __typename?: 'StoreCustomerZoneEverydayDataAggregateResponse';
  avg?: Maybe<StoreCustomerZoneEverydayDataAvgAggregate>;
  count?: Maybe<StoreCustomerZoneEverydayDataCountAggregate>;
  groupBy?: Maybe<StoreCustomerZoneEverydayDataAggregateGroupBy>;
  max?: Maybe<StoreCustomerZoneEverydayDataMaxAggregate>;
  min?: Maybe<StoreCustomerZoneEverydayDataMinAggregate>;
  sum?: Maybe<StoreCustomerZoneEverydayDataSumAggregate>;
};

export type StoreCustomerZoneEverydayDataAvgAggregate = {
  __typename?: 'StoreCustomerZoneEverydayDataAvgAggregate';
  newCustomer?: Maybe<Scalars['Float']['output']>;
  orderCount?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type StoreCustomerZoneEverydayDataCountAggregate = {
  __typename?: 'StoreCustomerZoneEverydayDataCountAggregate';
  city?: Maybe<Scalars['Int']['output']>;
  countryCode?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  newCustomer?: Maybe<Scalars['Int']['output']>;
  orderCount?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerZoneEverydayDataCountryCodeFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
};

export type StoreCustomerZoneEverydayDataFilter = {
  and?: InputMaybe<Array<StoreCustomerZoneEverydayDataFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  countryCode?: InputMaybe<StoreCustomerZoneEverydayDataCountryCodeFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  newCustomer?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StoreCustomerZoneEverydayDataFilter>>;
  orderCount?: InputMaybe<IntFieldComparison>;
  paidAtDate?: InputMaybe<StoreCustomerZoneEverydayDataPaidAtDateFilterComparison>;
  storeId?: InputMaybe<StoreCustomerZoneEverydayDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreCustomerZoneEverydayDataStoreV1IdFilterComparison>;
};

export type StoreCustomerZoneEverydayDataMaxAggregate = {
  __typename?: 'StoreCustomerZoneEverydayDataMaxAggregate';
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  newCustomer?: Maybe<Scalars['Int']['output']>;
  orderCount?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerZoneEverydayDataMinAggregate = {
  __typename?: 'StoreCustomerZoneEverydayDataMinAggregate';
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  newCustomer?: Maybe<Scalars['Int']['output']>;
  orderCount?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreCustomerZoneEverydayDataPaidAtDateFilterComparison = {
  between?: InputMaybe<StoreCustomerZoneEverydayDataPaidAtDateFilterComparisonBetween>;
};

export type StoreCustomerZoneEverydayDataPaidAtDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type StoreCustomerZoneEverydayDataSort = {
  direction: SortDirection;
  field: StoreCustomerZoneEverydayDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreCustomerZoneEverydayDataSortFields {
  City = 'city',
  CountryCode = 'countryCode',
  Id = 'id',
  NewCustomer = 'newCustomer',
  OrderCount = 'orderCount',
  PaidAtDate = 'paidAtDate',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreCustomerZoneEverydayDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreCustomerZoneEverydayDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreCustomerZoneEverydayDataSumAggregate = {
  __typename?: 'StoreCustomerZoneEverydayDataSumAggregate';
  newCustomer?: Maybe<Scalars['Float']['output']>;
  orderCount?: Maybe<Scalars['Float']['output']>;
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type StoreDeleteResponse = {
  __typename?: 'StoreDeleteResponse';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  blueprintSubscriptionCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  blueprintSubscriptionId?: Maybe<Scalars['String']['output']>;
  blueprintSubscriptionPeriodEndAt?: Maybe<Scalars['DateTime']['output']>;
  blueprintSubscriptionPeriodStartAt?: Maybe<Scalars['DateTime']['output']>;
  blueprintSubscriptionStatus?: Maybe<StripeSubscriptionStatus>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  handle?: Maybe<Scalars['String']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  menus?: Maybe<Array<StoreMenu>>;
  modules?: Maybe<Array<Scalars['String']['output']>>;
  rewardPoints?: Maybe<Scalars['Int']['output']>;
  shopifyAppModules?: Maybe<Array<Scalars['String']['output']>>;
  stripeModules?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  v1Id?: Maybe<Scalars['Int']['output']>;
};

export enum StoreDiscountType {
  FlatRateOff = 'FLAT_RATE_OFF',
  PercentOff = 'PERCENT_OFF',
  ShippingFree = 'SHIPPING_FREE',
}

export type StoreEntryDto = {
  __typename?: 'StoreEntryDto';
  /** ID */
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type StoreFilter = {
  and?: InputMaybe<Array<StoreFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  handle?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreFilter>>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  userId?: InputMaybe<IdFilterComparison>;
  v1Id?: InputMaybe<NumberFieldComparison>;
};

export type StoreKlaviyoAppCapturedListDto = {
  __typename?: 'StoreKlaviyoAppCapturedListDto';
  emailCount: Scalars['Int']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sendEvent?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type StoreKlaviyoAppInfo = {
  __typename?: 'StoreKlaviyoAppInfo';
  capturedEmailCountByTags: Array<StoreKlaviyoAppInfoCapturedEmailCountDto>;
  capturedList?: Maybe<Array<StoreKlaviyoAppCapturedListDto>>;
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  oAuthStatus: KlaviyoOauthStatus;
  pullListId?: Maybe<Scalars['String']['output']>;
  segmentId?: Maybe<Scalars['String']['output']>;
  storeId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type StoreKlaviyoAppInfoCapturedEmailCountDto = {
  __typename?: 'StoreKlaviyoAppInfoCapturedEmailCountDto';
  count: Scalars['Int']['output'];
  tags?: Maybe<Scalars['String']['output']>;
};

export type StoreKlaviyoAppInfoConnection = {
  __typename?: 'StoreKlaviyoAppInfoConnection';
  /** Array of nodes. */
  nodes: Array<StoreKlaviyoAppInfo>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StoreKlaviyoAppInfoFilter = {
  and?: InputMaybe<Array<StoreKlaviyoAppInfoFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  oAuthStatus?: InputMaybe<StoreKlaviyoAppInfoOAuthStatusFilterComparison>;
  or?: InputMaybe<Array<StoreKlaviyoAppInfoFilter>>;
  storeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StoreKlaviyoAppInfoOAuthStatusFilterComparison = {
  in?: InputMaybe<Array<KlaviyoOauthStatus>>;
  notIn?: InputMaybe<Array<KlaviyoOauthStatus>>;
};

export type StoreKlaviyoAppInfoSort = {
  direction: SortDirection;
  field: StoreKlaviyoAppInfoSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreKlaviyoAppInfoSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  OAuthStatus = 'oAuthStatus',
  StoreId = 'storeId',
  UpdatedAt = 'updatedAt',
}

export type StoreMarketBestChannelsData = {
  __typename?: 'StoreMarketBestChannelsData';
  channelName: Scalars['String']['output'];
  conversions: Scalars['Int']['output'];
};

export enum StoreMenu {
  EmailCapture = 'EMAIL_CAPTURE',
  ProductDesign = 'PRODUCT_DESIGN',
}

export type StoreModuleItem = {
  __typename?: 'StoreModuleItem';
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  isBundle: Scalars['Boolean']['output'];
  isDraft: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  /** @deprecated not used */
  isOptional: Scalars['Boolean']['output'];
  /** When `isBundle` is true, it contains the names from which `isBundle` is false items. */
  modules?: Maybe<Array<Scalars['String']['output']>>;
  /** unique name */
  name: Scalars['String']['output'];
  prices: Array<StoreModuleItemPrice>;
  stripeId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  trialDays?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type StoreModuleItemPricesArgs = {
  filter?: StoreModuleItemPriceFilter;
  sorting?: Array<StoreModuleItemPriceSort>;
};

export type StoreModuleItemConnection = {
  __typename?: 'StoreModuleItemConnection';
  /** Array of nodes. */
  nodes: Array<StoreModuleItem>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StoreModuleItemCreateInput = {
  isBundle?: Scalars['Boolean']['input'];
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isOptional?: InputMaybe<Scalars['Boolean']['input']>;
  modules?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  /** left blank will create new */
  stripeId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  trialDays?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreModuleItemFilter = {
  and?: InputMaybe<Array<StoreModuleItemFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isBundle?: InputMaybe<BooleanFieldComparison>;
  isDraft?: InputMaybe<BooleanFieldComparison>;
  isEnabled?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<StoreModuleItemFilter>>;
  prices?: InputMaybe<StoreModuleItemFilterStoreModuleItemPriceFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StoreModuleItemFilterStoreModuleItemPriceFilter = {
  and?: InputMaybe<Array<StoreModuleItemFilterStoreModuleItemPriceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isEnabled?: InputMaybe<BooleanFieldComparison>;
  itemId?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreModuleItemFilterStoreModuleItemPriceFilter>>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StoreModuleItemPrice = {
  __typename?: 'StoreModuleItemPrice';
  createdAt: Scalars['DateTime']['output'];
  crossedPriceBp?: Maybe<Scalars['Int']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  itemId: Scalars['ID']['output'];
  priceBp: Scalars['Int']['output'];
  /** @deprecated unused */
  recurringAggregateUsage?: Maybe<StripePriceRecurringAggregateUsage>;
  recurringInterval: StripePriceRecurringInterval;
  recurringIntervalCount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated unused */
  recurringUsageType?: Maybe<StripePriceRecurringUsageType>;
  /** @deprecated not implement */
  stripeId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type StoreModuleItemPriceConnection = {
  __typename?: 'StoreModuleItemPriceConnection';
  /** Array of nodes. */
  nodes: Array<StoreModuleItemPrice>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StoreModuleItemPriceCreateInput = {
  crossedPriceBp?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  itemId: Scalars['ID']['input'];
  priceBp: Scalars['Int']['input'];
  recurringAggregateUsage?: InputMaybe<StripePriceRecurringAggregateUsage>;
  recurringInterval: StripePriceRecurringInterval;
  recurringIntervalCount?: InputMaybe<Scalars['Int']['input']>;
  recurringUsageType?: InputMaybe<StripePriceRecurringUsageType>;
  stripeId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type StoreModuleItemPriceDeleteResponse = {
  __typename?: 'StoreModuleItemPriceDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crossedPriceBp?: Maybe<Scalars['Int']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  itemId?: Maybe<Scalars['ID']['output']>;
  priceBp?: Maybe<Scalars['Int']['output']>;
  /** @deprecated unused */
  recurringAggregateUsage?: Maybe<StripePriceRecurringAggregateUsage>;
  recurringInterval?: Maybe<StripePriceRecurringInterval>;
  recurringIntervalCount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated unused */
  recurringUsageType?: Maybe<StripePriceRecurringUsageType>;
  /** @deprecated not implement */
  stripeId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type StoreModuleItemPriceFilter = {
  and?: InputMaybe<Array<StoreModuleItemPriceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isEnabled?: InputMaybe<BooleanFieldComparison>;
  itemId?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreModuleItemPriceFilter>>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StoreModuleItemPriceSort = {
  direction: SortDirection;
  field: StoreModuleItemPriceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreModuleItemPriceSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsEnabled = 'isEnabled',
  ItemId = 'itemId',
  Title = 'title',
  UpdatedAt = 'updatedAt',
}

export type StoreModuleItemPriceUpdateInput = {
  crossedPriceBp?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  priceBp?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type StoreModuleItemSort = {
  direction: SortDirection;
  field: StoreModuleItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreModuleItemSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsBundle = 'isBundle',
  IsDraft = 'isDraft',
  IsEnabled = 'isEnabled',
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export type StoreModuleItemUpdateInput = {
  isBundle?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isOptional?: InputMaybe<Scalars['Boolean']['input']>;
  modules?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** left blank will create new */
  stripeId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  trialDays?: InputMaybe<Scalars['Int']['input']>;
};

/** query StoreOrderAllData list */
export type StoreOrderAllData = {
  __typename?: 'StoreOrderAllData';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  items?: Maybe<Scalars['Int']['output']>;
  itemsReturned?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  repeatPurchase?: Maybe<Scalars['Int']['output']>;
  revenueReturned?: Maybe<Scalars['Float']['output']>;
  shippingRevenue?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  total?: Maybe<Scalars['Float']['output']>;
  vat?: Maybe<Scalars['Float']['output']>;
};

export type StoreOrderAllDataConnection = {
  __typename?: 'StoreOrderAllDataConnection';
  /** Array of nodes. */
  nodes: Array<StoreOrderAllData>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StoreOrderAllDataFilter = {
  and?: InputMaybe<Array<StoreOrderAllDataFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  discount?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  items?: InputMaybe<NumberFieldComparison>;
  itemsReturned?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<StoreOrderAllDataFilter>>;
  paidAt?: InputMaybe<DateFieldComparison>;
  repeatPurchase?: InputMaybe<NumberFieldComparison>;
  revenueReturned?: InputMaybe<FloatFieldComparison>;
  shippingRevenue?: InputMaybe<FloatFieldComparison>;
  storeId?: InputMaybe<StoreOrderAllDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreOrderAllDataStoreV1IdFilterComparison>;
  total?: InputMaybe<FloatFieldComparison>;
  vat?: InputMaybe<FloatFieldComparison>;
};

export type StoreOrderAllDataSort = {
  direction: SortDirection;
  field: StoreOrderAllDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreOrderAllDataSortFields {
  City = 'city',
  Country = 'country',
  CustomerId = 'customerId',
  Discount = 'discount',
  Id = 'id',
  Items = 'items',
  ItemsReturned = 'itemsReturned',
  PaidAt = 'paidAt',
  RepeatPurchase = 'repeatPurchase',
  RevenueReturned = 'revenueReturned',
  ShippingRevenue = 'shippingRevenue',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
  Total = 'total',
  Vat = 'vat',
}

export type StoreOrderAllDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreOrderAllDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** Statistics by paidAtDateType */
export type StoreOrderCountDistributionData = {
  __typename?: 'StoreOrderCountDistributionData';
  id?: Maybe<Scalars['ID']['output']>;
  numberOfCustomer?: Maybe<Scalars['Int']['output']>;
  numberOfOrder?: Maybe<Scalars['String']['output']>;
  paidAtDate: Scalars['DateTime']['output'];
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  totalOfCustomer?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderCountDistributionDataAggregateFilter = {
  and?: InputMaybe<Array<StoreOrderCountDistributionDataAggregateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreOrderCountDistributionDataAggregateFilter>>;
  paidAtDate?: InputMaybe<StoreOrderCountDistributionDataPaidAtDateFilterComparison>;
  storeId?: InputMaybe<StoreOrderCountDistributionDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreOrderCountDistributionDataStoreV1IdFilterComparison>;
};

export type StoreOrderCountDistributionDataAggregateGroupBy = {
  __typename?: 'StoreOrderCountDistributionDataAggregateGroupBy';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderCountDistributionDataAggregateGroupByPaidAtDateArgs = {
  by?: GroupBy;
};

export type StoreOrderCountDistributionDataAggregateResponse = {
  __typename?: 'StoreOrderCountDistributionDataAggregateResponse';
  avg?: Maybe<StoreOrderCountDistributionDataAvgAggregate>;
  count?: Maybe<StoreOrderCountDistributionDataCountAggregate>;
  groupBy?: Maybe<StoreOrderCountDistributionDataAggregateGroupBy>;
  max?: Maybe<StoreOrderCountDistributionDataMaxAggregate>;
  min?: Maybe<StoreOrderCountDistributionDataMinAggregate>;
  sum?: Maybe<StoreOrderCountDistributionDataSumAggregate>;
};

export type StoreOrderCountDistributionDataAvgAggregate = {
  __typename?: 'StoreOrderCountDistributionDataAvgAggregate';
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type StoreOrderCountDistributionDataCountAggregate = {
  __typename?: 'StoreOrderCountDistributionDataCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderCountDistributionDataFilter = {
  and?: InputMaybe<Array<StoreOrderCountDistributionDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreOrderCountDistributionDataFilter>>;
  paidAtDate?: InputMaybe<StoreOrderCountDistributionDataPaidAtDateFilterComparison>;
  storeId?: InputMaybe<StoreOrderCountDistributionDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreOrderCountDistributionDataStoreV1IdFilterComparison>;
};

export type StoreOrderCountDistributionDataMaxAggregate = {
  __typename?: 'StoreOrderCountDistributionDataMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderCountDistributionDataMinAggregate = {
  __typename?: 'StoreOrderCountDistributionDataMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderCountDistributionDataPaidAtDateFilterComparison = {
  between?: InputMaybe<StoreOrderCountDistributionDataPaidAtDateFilterComparisonBetween>;
};

export type StoreOrderCountDistributionDataPaidAtDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type StoreOrderCountDistributionDataSort = {
  direction: SortDirection;
  field: StoreOrderCountDistributionDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreOrderCountDistributionDataSortFields {
  Id = 'id',
  PaidAtDate = 'paidAtDate',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreOrderCountDistributionDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreOrderCountDistributionDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreOrderCountDistributionDataSumAggregate = {
  __typename?: 'StoreOrderCountDistributionDataSumAggregate';
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** Statistics by paidAtDateType */
export type StoreOrderDevelopmentData = {
  __typename?: 'StoreOrderDevelopmentData';
  id?: Maybe<Scalars['ID']['output']>;
  orderCount?: Maybe<Scalars['Int']['output']>;
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderDevelopmentDataAggregateFilter = {
  and?: InputMaybe<Array<StoreOrderDevelopmentDataAggregateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreOrderDevelopmentDataAggregateFilter>>;
  paidAtDate?: InputMaybe<StoreOrderDevelopmentDataPaidAtDateFilterComparison>;
  paidAtDateType?: InputMaybe<StoreOrderDevelopmentDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreOrderDevelopmentDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreOrderDevelopmentDataStoreV1IdFilterComparison>;
};

export type StoreOrderDevelopmentDataAggregateGroupBy = {
  __typename?: 'StoreOrderDevelopmentDataAggregateGroupBy';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderDevelopmentDataAggregateGroupByPaidAtDateArgs = {
  by?: GroupBy;
};

export type StoreOrderDevelopmentDataAggregateResponse = {
  __typename?: 'StoreOrderDevelopmentDataAggregateResponse';
  avg?: Maybe<StoreOrderDevelopmentDataAvgAggregate>;
  count?: Maybe<StoreOrderDevelopmentDataCountAggregate>;
  groupBy?: Maybe<StoreOrderDevelopmentDataAggregateGroupBy>;
  max?: Maybe<StoreOrderDevelopmentDataMaxAggregate>;
  min?: Maybe<StoreOrderDevelopmentDataMinAggregate>;
  sum?: Maybe<StoreOrderDevelopmentDataSumAggregate>;
};

export type StoreOrderDevelopmentDataAvgAggregate = {
  __typename?: 'StoreOrderDevelopmentDataAvgAggregate';
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type StoreOrderDevelopmentDataCountAggregate = {
  __typename?: 'StoreOrderDevelopmentDataCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['Int']['output']>;
  paidAtDateType?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderDevelopmentDataFilter = {
  and?: InputMaybe<Array<StoreOrderDevelopmentDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreOrderDevelopmentDataFilter>>;
  paidAtDate?: InputMaybe<StoreOrderDevelopmentDataPaidAtDateFilterComparison>;
  paidAtDateType?: InputMaybe<StoreOrderDevelopmentDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreOrderDevelopmentDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreOrderDevelopmentDataStoreV1IdFilterComparison>;
};

export type StoreOrderDevelopmentDataMaxAggregate = {
  __typename?: 'StoreOrderDevelopmentDataMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderDevelopmentDataMinAggregate = {
  __typename?: 'StoreOrderDevelopmentDataMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderDevelopmentDataPaidAtDateFilterComparison = {
  between?: InputMaybe<StoreOrderDevelopmentDataPaidAtDateFilterComparisonBetween>;
};

export type StoreOrderDevelopmentDataPaidAtDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type StoreOrderDevelopmentDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreOrderDevelopmentDataSort = {
  direction: SortDirection;
  field: StoreOrderDevelopmentDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreOrderDevelopmentDataSortFields {
  Id = 'id',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreOrderDevelopmentDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreOrderDevelopmentDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreOrderDevelopmentDataSumAggregate = {
  __typename?: 'StoreOrderDevelopmentDataSumAggregate';
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** Statistics by paidAtDateType */
export type StoreOrderDiscountData = {
  __typename?: 'StoreOrderDiscountData';
  discontPercent?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate: Scalars['DateTime']['output'];
  paidAtDateType: BiDateType;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  subtotal?: Maybe<Scalars['Float']['output']>;
};

export type StoreOrderDiscountDataAggregateFilter = {
  and?: InputMaybe<Array<StoreOrderDiscountDataAggregateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreOrderDiscountDataAggregateFilter>>;
  paidAtDate?: InputMaybe<StoreOrderDiscountDataPaidAtDateFilterComparison>;
  paidAtDateType?: InputMaybe<StoreOrderDiscountDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreOrderDiscountDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreOrderDiscountDataStoreV1IdFilterComparison>;
};

export type StoreOrderDiscountDataAggregateGroupBy = {
  __typename?: 'StoreOrderDiscountDataAggregateGroupBy';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderDiscountDataAggregateGroupByPaidAtDateArgs = {
  by?: GroupBy;
};

export type StoreOrderDiscountDataAggregateResponse = {
  __typename?: 'StoreOrderDiscountDataAggregateResponse';
  avg?: Maybe<StoreOrderDiscountDataAvgAggregate>;
  count?: Maybe<StoreOrderDiscountDataCountAggregate>;
  groupBy?: Maybe<StoreOrderDiscountDataAggregateGroupBy>;
  max?: Maybe<StoreOrderDiscountDataMaxAggregate>;
  min?: Maybe<StoreOrderDiscountDataMinAggregate>;
  sum?: Maybe<StoreOrderDiscountDataSumAggregate>;
};

export type StoreOrderDiscountDataAvgAggregate = {
  __typename?: 'StoreOrderDiscountDataAvgAggregate';
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type StoreOrderDiscountDataCountAggregate = {
  __typename?: 'StoreOrderDiscountDataCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  paidAtDate?: Maybe<Scalars['Int']['output']>;
  paidAtDateType?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderDiscountDataFilter = {
  and?: InputMaybe<Array<StoreOrderDiscountDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreOrderDiscountDataFilter>>;
  paidAtDate?: InputMaybe<StoreOrderDiscountDataPaidAtDateFilterComparison>;
  paidAtDateType?: InputMaybe<StoreOrderDiscountDataPaidAtDateTypeFilterComparison>;
  storeId?: InputMaybe<StoreOrderDiscountDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreOrderDiscountDataStoreV1IdFilterComparison>;
};

export type StoreOrderDiscountDataMaxAggregate = {
  __typename?: 'StoreOrderDiscountDataMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderDiscountDataMinAggregate = {
  __typename?: 'StoreOrderDiscountDataMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  paidAtDate?: Maybe<Scalars['DateTime']['output']>;
  paidAtDateType?: Maybe<BiDateType>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderDiscountDataPaidAtDateFilterComparison = {
  between?: InputMaybe<StoreOrderDiscountDataPaidAtDateFilterComparisonBetween>;
};

export type StoreOrderDiscountDataPaidAtDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type StoreOrderDiscountDataPaidAtDateTypeFilterComparison = {
  eq?: InputMaybe<BiDateType>;
};

export type StoreOrderDiscountDataSort = {
  direction: SortDirection;
  field: StoreOrderDiscountDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreOrderDiscountDataSortFields {
  Id = 'id',
  PaidAtDate = 'paidAtDate',
  PaidAtDateType = 'paidAtDateType',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreOrderDiscountDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreOrderDiscountDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreOrderDiscountDataSumAggregate = {
  __typename?: 'StoreOrderDiscountDataSumAggregate';
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** calculate AOV, meadian value based on  the lastest 240 months orders, data for predicting freeshipping threshold */
export type StoreOrderFreeShippingThresholdData = {
  __typename?: 'StoreOrderFreeShippingThresholdData';
  AOV?: Maybe<Scalars['Float']['output']>;
  bucketSize?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  medianValue?: Maybe<Scalars['Float']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  subtotalOfLevel: Scalars['Float']['output'];
  totalOrder?: Maybe<Scalars['Int']['output']>;
  totalOrderOfFreeShipping?: Maybe<Scalars['Float']['output']>;
};

export type StoreOrderFreeShippingThresholdDataAggregateFilter = {
  and?: InputMaybe<Array<StoreOrderFreeShippingThresholdDataAggregateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreOrderFreeShippingThresholdDataAggregateFilter>>;
  storeId?: InputMaybe<StoreOrderFreeShippingThresholdDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreOrderFreeShippingThresholdDataStoreV1IdFilterComparison>;
};

export type StoreOrderFreeShippingThresholdDataAggregateGroupBy = {
  __typename?: 'StoreOrderFreeShippingThresholdDataAggregateGroupBy';
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderFreeShippingThresholdDataAggregateResponse = {
  __typename?: 'StoreOrderFreeShippingThresholdDataAggregateResponse';
  avg?: Maybe<StoreOrderFreeShippingThresholdDataAvgAggregate>;
  count?: Maybe<StoreOrderFreeShippingThresholdDataCountAggregate>;
  groupBy?: Maybe<StoreOrderFreeShippingThresholdDataAggregateGroupBy>;
  max?: Maybe<StoreOrderFreeShippingThresholdDataMaxAggregate>;
  min?: Maybe<StoreOrderFreeShippingThresholdDataMinAggregate>;
  sum?: Maybe<StoreOrderFreeShippingThresholdDataSumAggregate>;
};

export type StoreOrderFreeShippingThresholdDataAvgAggregate = {
  __typename?: 'StoreOrderFreeShippingThresholdDataAvgAggregate';
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

export type StoreOrderFreeShippingThresholdDataCountAggregate = {
  __typename?: 'StoreOrderFreeShippingThresholdDataCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  storeId?: Maybe<Scalars['Int']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderFreeShippingThresholdDataFilter = {
  and?: InputMaybe<Array<StoreOrderFreeShippingThresholdDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreOrderFreeShippingThresholdDataFilter>>;
  storeId?: InputMaybe<StoreOrderFreeShippingThresholdDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreOrderFreeShippingThresholdDataStoreV1IdFilterComparison>;
};

export type StoreOrderFreeShippingThresholdDataMaxAggregate = {
  __typename?: 'StoreOrderFreeShippingThresholdDataMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderFreeShippingThresholdDataMinAggregate = {
  __typename?: 'StoreOrderFreeShippingThresholdDataMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id?: Maybe<Scalars['Int']['output']>;
};

export type StoreOrderFreeShippingThresholdDataSort = {
  direction: SortDirection;
  field: StoreOrderFreeShippingThresholdDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreOrderFreeShippingThresholdDataSortFields {
  Id = 'id',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreOrderFreeShippingThresholdDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreOrderFreeShippingThresholdDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreOrderFreeShippingThresholdDataSumAggregate = {
  __typename?: 'StoreOrderFreeShippingThresholdDataSumAggregate';
  storeV1Id?: Maybe<Scalars['Float']['output']>;
};

/** Store Order Item All Data */
export type StoreOrderItemAllData = {
  __typename?: 'StoreOrderItemAllData';
  grossItemSold?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  inventoryName?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreOrderItemBestSellData = {
  __typename?: 'StoreOrderItemBestSellData';
  grossItemSold?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  productTitle: Scalars['String']['output'];
  sku: Scalars['String']['output'];
};

export enum StoreOrderType {
  Affiliate = 'AFFILIATE',
  Sales = 'SALES',
  Warehouse = 'WAREHOUSE',
}

/** query StoreProductAllData list */
export type StoreProductAllData = {
  __typename?: 'StoreProductAllData';
  creatorSKU?: Maybe<Scalars['String']['output']>;
  customers?: Maybe<Scalars['Float']['output']>;
  grossItemsSold?: Maybe<Scalars['Float']['output']>;
  grossRevenue?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  netRevenue?: Maybe<Scalars['Float']['output']>;
  newCustomers?: Maybe<Scalars['Float']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  pietraSKU?: Maybe<Scalars['String']['output']>;
  productTitle?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
};

export type StoreProductAllDataConnection = {
  __typename?: 'StoreProductAllDataConnection';
  /** Array of nodes. */
  nodes: Array<StoreProductAllData>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StoreProductAllDataFilter = {
  and?: InputMaybe<Array<StoreProductAllDataFilter>>;
  creatorSKU?: InputMaybe<StringFieldComparison>;
  customers?: InputMaybe<FloatFieldComparison>;
  grossItemsSold?: InputMaybe<FloatFieldComparison>;
  grossRevenue?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  imageUrl?: InputMaybe<StringFieldComparison>;
  netRevenue?: InputMaybe<FloatFieldComparison>;
  newCustomers?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<StoreProductAllDataFilter>>;
  orders?: InputMaybe<NumberFieldComparison>;
  pietraSKU?: InputMaybe<StringFieldComparison>;
  productTitle?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<StoreProductAllDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreProductAllDataStoreV1IdFilterComparison>;
};

export type StoreProductAllDataSort = {
  direction: SortDirection;
  field: StoreProductAllDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreProductAllDataSortFields {
  CreatorSku = 'creatorSKU',
  Customers = 'customers',
  GrossItemsSold = 'grossItemsSold',
  GrossRevenue = 'grossRevenue',
  Id = 'id',
  ImageUrl = 'imageUrl',
  NetRevenue = 'netRevenue',
  NewCustomers = 'newCustomers',
  Orders = 'orders',
  PietraSku = 'pietraSKU',
  ProductTitle = 'productTitle',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
}

export type StoreProductAllDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreProductAllDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

/** cross selling analysis,Percentage of the product combination bought out of all combinations  */
export type StoreProductCrossSellingAnalysisData = {
  __typename?: 'StoreProductCrossSellingAnalysisData';
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl_1?: Maybe<Scalars['String']['output']>;
  imageUrl_2?: Maybe<Scalars['String']['output']>;
  productTitle_1?: Maybe<Scalars['String']['output']>;
  productTitle_2?: Maybe<Scalars['String']['output']>;
  purchaseFrequency: Scalars['Float']['output'];
  sku_1?: Maybe<Scalars['String']['output']>;
  sku_2?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeTotalCombinationOccurrences: Scalars['Int']['output'];
  storeV1Id: Scalars['Int']['output'];
};

export type StoreProductCrossSellingAnalysisDataFilter = {
  and?: InputMaybe<Array<StoreProductCrossSellingAnalysisDataFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  imageUrl_1?: InputMaybe<StringFieldComparison>;
  imageUrl_2?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<StoreProductCrossSellingAnalysisDataFilter>>;
  productTitle_1?: InputMaybe<StringFieldComparison>;
  productTitle_2?: InputMaybe<StringFieldComparison>;
  purchaseFrequency?: InputMaybe<FloatFieldComparison>;
  sku_1?: InputMaybe<StringFieldComparison>;
  sku_2?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<StoreProductCrossSellingAnalysisDataStoreIdFilterComparison>;
  storeTotalCombinationOccurrences?: InputMaybe<IntFieldComparison>;
  storeV1Id?: InputMaybe<StoreProductCrossSellingAnalysisDataStoreV1IdFilterComparison>;
};

export type StoreProductCrossSellingAnalysisDataSort = {
  direction: SortDirection;
  field: StoreProductCrossSellingAnalysisDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreProductCrossSellingAnalysisDataSortFields {
  Id = 'id',
  ImageUrl_1 = 'imageUrl_1',
  ImageUrl_2 = 'imageUrl_2',
  ProductTitle_1 = 'productTitle_1',
  ProductTitle_2 = 'productTitle_2',
  PurchaseFrequency = 'purchaseFrequency',
  Sku_1 = 'sku_1',
  Sku_2 = 'sku_2',
  StoreId = 'storeId',
  StoreTotalCombinationOccurrences = 'storeTotalCombinationOccurrences',
  StoreV1Id = 'storeV1Id',
}

export type StoreProductCrossSellingAnalysisDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreProductCrossSellingAnalysisDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreRevenueByCohortsDto = {
  __typename?: 'StoreRevenueByCohortsDto';
  firstPurchaseMonth: Scalars['DateTime']['output'];
  grossRevenue?: Maybe<Scalars['Float']['output']>;
  netRevenue?: Maybe<Scalars['Float']['output']>;
  paidAt: Scalars['DateTime']['output'];
};

export type StoreRewardIncomeRecord = {
  __typename?: 'StoreRewardIncomeRecord';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  modules?: Maybe<Array<Scalars['String']['output']>>;
  note?: Maybe<Scalars['String']['output']>;
  orderAmount?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  paymentId?: Maybe<Scalars['Int']['output']>;
  points: Scalars['Int']['output'];
  remainingPoints: Scalars['Int']['output'];
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  revokedBy?: Maybe<Scalars['Int']['output']>;
  revokedReason?: Maybe<Scalars['String']['output']>;
  source: StoreRewardIncomeSource;
  storeId: Scalars['ID']['output'];
  subId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type StoreRewardIncomeRecordConnection = {
  __typename?: 'StoreRewardIncomeRecordConnection';
  /** Array of nodes. */
  nodes: Array<StoreRewardIncomeRecord>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StoreRewardIncomeRecordFilter = {
  and?: InputMaybe<Array<StoreRewardIncomeRecordFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdBy?: InputMaybe<IntFieldComparison>;
  expiredAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreRewardIncomeRecordFilter>>;
  orderAmount?: InputMaybe<IntFieldComparison>;
  orderId?: InputMaybe<IntFieldComparison>;
  paymentId?: InputMaybe<IntFieldComparison>;
  points?: InputMaybe<IntFieldComparison>;
  remainingPoints?: InputMaybe<IntFieldComparison>;
  revokedAt?: InputMaybe<DateFieldComparison>;
  revokedBy?: InputMaybe<IntFieldComparison>;
  source?: InputMaybe<StoreRewardIncomeRecordSourceFilterComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  subId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StoreRewardIncomeRecordSort = {
  direction: SortDirection;
  field: StoreRewardIncomeRecordSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreRewardIncomeRecordSortFields {
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  ExpiredAt = 'expiredAt',
  Id = 'id',
  OrderAmount = 'orderAmount',
  OrderId = 'orderId',
  PaymentId = 'paymentId',
  Points = 'points',
  RemainingPoints = 'remainingPoints',
  RevokedAt = 'revokedAt',
  RevokedBy = 'revokedBy',
  Source = 'source',
  StoreId = 'storeId',
  SubId = 'subId',
  UpdatedAt = 'updatedAt',
}

export type StoreRewardIncomeRecordSourceFilterComparison = {
  eq?: InputMaybe<StoreRewardIncomeSource>;
  in?: InputMaybe<Array<StoreRewardIncomeSource>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<StoreRewardIncomeSource>;
  notIn?: InputMaybe<Array<StoreRewardIncomeSource>>;
};

export enum StoreRewardIncomeSource {
  Fbp = 'FBP',
  Fulfillment = 'FULFILLMENT',
  Manual = 'MANUAL',
  Marketing = 'MARKETING',
  Sourcing = 'SOURCING',
  Subscription = 'SUBSCRIPTION',
}

export type StoreRewardOrder = {
  __typename?: 'StoreRewardOrder';
  amount: Scalars['Int']['output'];
  code?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  points: Scalars['Int']['output'];
  product?: Maybe<StoreRewardProduct>;
  productId?: Maybe<Scalars['ID']['output']>;
  storeId: Scalars['ID']['output'];
  type: StoreRewardProductType;
  updatedAt: Scalars['DateTime']['output'];
};

export type StoreRewardOrderConnection = {
  __typename?: 'StoreRewardOrderConnection';
  /** Array of nodes. */
  nodes: Array<StoreRewardOrder>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StoreRewardOrderFilter = {
  amount?: InputMaybe<IntFieldComparison>;
  and?: InputMaybe<Array<StoreRewardOrderFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StoreRewardOrderFilter>>;
  points?: InputMaybe<IntFieldComparison>;
  productId?: InputMaybe<IdFilterComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  type?: InputMaybe<StoreRewardOrderTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StoreRewardOrderSort = {
  direction: SortDirection;
  field: StoreRewardOrderSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreRewardOrderSortFields {
  Amount = 'amount',
  Code = 'code',
  CreatedAt = 'createdAt',
  Id = 'id',
  Points = 'points',
  ProductId = 'productId',
  StoreId = 'storeId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

export type StoreRewardOrderTypeFilterComparison = {
  eq?: InputMaybe<StoreRewardProductType>;
  in?: InputMaybe<Array<StoreRewardProductType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<StoreRewardProductType>;
  notIn?: InputMaybe<Array<StoreRewardProductType>>;
};

export type StoreRewardProduct = {
  __typename?: 'StoreRewardProduct';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  isDraft: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  points: Scalars['Int']['output'];
  type: StoreRewardProductType;
  updatedAt: Scalars['DateTime']['output'];
};

export type StoreRewardProductConnection = {
  __typename?: 'StoreRewardProductConnection';
  /** Array of nodes. */
  nodes: Array<StoreRewardProduct>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StoreRewardProductCreateInput = {
  amount: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  points: Scalars['Int']['input'];
  type: StoreRewardProductType;
};

export type StoreRewardProductDeleteResponse = {
  __typename?: 'StoreRewardProductDeleteResponse';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<StoreRewardProductType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type StoreRewardProductFilter = {
  and?: InputMaybe<Array<StoreRewardProductFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isDraft?: InputMaybe<BooleanFieldComparison>;
  isEnabled?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<StoreRewardProductFilter>>;
  type?: InputMaybe<StoreRewardProductTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StoreRewardProductSort = {
  direction: SortDirection;
  field: StoreRewardProductSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreRewardProductSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsDraft = 'isDraft',
  IsEnabled = 'isEnabled',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

export enum StoreRewardProductType {
  Fulfillment = 'FULFILLMENT',
  Manual = 'MANUAL',
  Marketing = 'MARKETING',
  Sourcing = 'SOURCING',
}

export type StoreRewardProductTypeFilterComparison = {
  eq?: InputMaybe<StoreRewardProductType>;
  in?: InputMaybe<Array<StoreRewardProductType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<StoreRewardProductType>;
  notIn?: InputMaybe<Array<StoreRewardProductType>>;
};

export type StoreRewardProductUpdateInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StoreRewardProductType>;
};

export type StoreShopifyAppInfo = {
  __typename?: 'StoreShopifyAppInfo';
  accessToken: Scalars['String']['output'];
  apiKey: Scalars['String']['output'];
  apiSecret: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  isWebhookSetup: Scalars['Boolean']['output'];
  shopName: Scalars['String']['output'];
  storeId: Scalars['ID']['output'];
  type: StoreShopifyAppType;
  updatedAt: Scalars['DateTime']['output'];
};

export type StoreShopifyAppInfoConnection = {
  __typename?: 'StoreShopifyAppInfoConnection';
  /** Array of nodes. */
  nodes: Array<StoreShopifyAppInfo>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StoreShopifyAppInfoCreateInput = {
  accessToken: Scalars['String']['input'];
  apiKey: Scalars['String']['input'];
  apiSecret: Scalars['String']['input'];
  shopName: Scalars['String']['input'];
  storeId: Scalars['ID']['input'];
};

export type StoreShopifyAppInfoDeleteResponse = {
  __typename?: 'StoreShopifyAppInfoDeleteResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  apiKey?: Maybe<Scalars['String']['output']>;
  apiSecret?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  isWebhookSetup?: Maybe<Scalars['Boolean']['output']>;
  shopName?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<StoreShopifyAppType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type StoreShopifyAppInfoFilter = {
  and?: InputMaybe<Array<StoreShopifyAppInfoFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isWebhookSetup?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<StoreShopifyAppInfoFilter>>;
  shopName?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  type?: InputMaybe<StoreShopifyAppInfoTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StoreShopifyAppInfoSort = {
  direction: SortDirection;
  field: StoreShopifyAppInfoSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreShopifyAppInfoSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsWebhookSetup = 'isWebhookSetup',
  ShopName = 'shopName',
  StoreId = 'storeId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

export type StoreShopifyAppInfoTypeFilterComparison = {
  in?: InputMaybe<Array<StoreShopifyAppType>>;
  notIn?: InputMaybe<Array<StoreShopifyAppType>>;
};

export type StoreShopifyAppInfoUpdateInput = {
  accessToken: Scalars['String']['input'];
  apiKey: Scalars['String']['input'];
  apiSecret: Scalars['String']['input'];
  shopName: Scalars['String']['input'];
};

export enum StoreShopifyAppType {
  Custom = 'CUSTOM',
  Public = 'PUBLIC',
}

export type StoreShopifyDiscountCodeCreateInput = {
  amount?: InputMaybe<Scalars['PositiveFloat']['input']>;
  appliesOnEachItem?: InputMaybe<Scalars['Boolean']['input']>;
  appliesOncePerCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  code: Scalars['String']['input'];
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  percentage?: InputMaybe<Scalars['Int']['input']>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
  type: StoreDiscountType;
  usageLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreShopifyDiscountCodeDto = {
  __typename?: 'StoreShopifyDiscountCodeDto';
  code: Scalars['String']['output'];
  shopifyDiscountCodeTypeName: Scalars['String']['output'];
  shopifyGid: Scalars['String']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type StoreShopifyScriptTagCreateInput = {
  cache?: InputMaybe<Scalars['Boolean']['input']>;
  displayScope: StoreShopifyScriptTagDisplayScope;
  src: Scalars['String']['input'];
};

export enum StoreShopifyScriptTagDisplayScope {
  All = 'All',
  OnlineStore = 'OnlineStore',
  OrderStatus = 'OrderStatus',
}

export type StoreShopifyScriptTagDto = {
  __typename?: 'StoreShopifyScriptTagDto';
  cache: Scalars['Boolean']['output'];
  displayScope: StoreShopifyScriptTagDisplayScope;
  id: Scalars['ID']['output'];
  src: Scalars['String']['output'];
};

export type StoreShopifyWebhookCreateInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  topic: StoreShopifyWebhookTopic;
};

export type StoreShopifyWebhookDto = {
  __typename?: 'StoreShopifyWebhookDto';
  callbackUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  topic: StoreShopifyWebhookTopic;
};

export enum StoreShopifyWebhookTopic {
  AppPurchasesOneTimeUpdate = 'AppPurchasesOneTimeUpdate',
  AppSubscriptionsApproachingCappedAmount = 'AppSubscriptionsApproachingCappedAmount',
  AppSubscriptionsUpdate = 'AppSubscriptionsUpdate',
  AppUninstalled = 'AppUninstalled',
  AttributedSessionsFirst = 'AttributedSessionsFirst',
  AttributedSessionsLast = 'AttributedSessionsLast',
  AuditEventsAdminApiActivity = 'AuditEventsAdminApiActivity',
  BulkOperationsFinish = 'BulkOperationsFinish',
  CartsCreate = 'CartsCreate',
  CartsUpdate = 'CartsUpdate',
  ChannelsDelete = 'ChannelsDelete',
  CheckoutsCreate = 'CheckoutsCreate',
  CheckoutsDelete = 'CheckoutsDelete',
  CheckoutsUpdate = 'CheckoutsUpdate',
  CollectionListingsAdd = 'CollectionListingsAdd',
  CollectionListingsRemove = 'CollectionListingsRemove',
  CollectionListingsUpdate = 'CollectionListingsUpdate',
  CollectionPublicationsCreate = 'CollectionPublicationsCreate',
  CollectionPublicationsDelete = 'CollectionPublicationsDelete',
  CollectionPublicationsUpdate = 'CollectionPublicationsUpdate',
  CollectionsCreate = 'CollectionsCreate',
  CollectionsDelete = 'CollectionsDelete',
  CollectionsUpdate = 'CollectionsUpdate',
  CompaniesCreate = 'CompaniesCreate',
  CompaniesDelete = 'CompaniesDelete',
  CompaniesUpdate = 'CompaniesUpdate',
  CompanyContactsCreate = 'CompanyContactsCreate',
  CompanyContactsDelete = 'CompanyContactsDelete',
  CompanyContactsUpdate = 'CompanyContactsUpdate',
  CompanyLocationsCreate = 'CompanyLocationsCreate',
  CompanyLocationsDelete = 'CompanyLocationsDelete',
  CompanyLocationsUpdate = 'CompanyLocationsUpdate',
  CustomerGroupsCreate = 'CustomerGroupsCreate',
  CustomerGroupsDelete = 'CustomerGroupsDelete',
  CustomerGroupsUpdate = 'CustomerGroupsUpdate',
  CustomerPaymentMethodsCreate = 'CustomerPaymentMethodsCreate',
  CustomerPaymentMethodsRevoke = 'CustomerPaymentMethodsRevoke',
  CustomerPaymentMethodsUpdate = 'CustomerPaymentMethodsUpdate',
  CustomersCreate = 'CustomersCreate',
  CustomersDelete = 'CustomersDelete',
  CustomersDisable = 'CustomersDisable',
  CustomersEnable = 'CustomersEnable',
  CustomersMarketingConsentUpdate = 'CustomersMarketingConsentUpdate',
  CustomersMerge = 'CustomersMerge',
  CustomersUpdate = 'CustomersUpdate',
  DisputesCreate = 'DisputesCreate',
  DisputesUpdate = 'DisputesUpdate',
  DomainsCreate = 'DomainsCreate',
  DomainsDestroy = 'DomainsDestroy',
  DomainsUpdate = 'DomainsUpdate',
  DraftOrdersCreate = 'DraftOrdersCreate',
  DraftOrdersDelete = 'DraftOrdersDelete',
  DraftOrdersUpdate = 'DraftOrdersUpdate',
  FulfillmentEventsCreate = 'FulfillmentEventsCreate',
  FulfillmentEventsDelete = 'FulfillmentEventsDelete',
  FulfillmentOrdersCancellationRequestAccepted = 'FulfillmentOrdersCancellationRequestAccepted',
  FulfillmentOrdersCancellationRequestRejected = 'FulfillmentOrdersCancellationRequestRejected',
  FulfillmentOrdersCancellationRequestSubmitted = 'FulfillmentOrdersCancellationRequestSubmitted',
  FulfillmentOrdersCancelled = 'FulfillmentOrdersCancelled',
  FulfillmentOrdersFulfillmentRequestAccepted = 'FulfillmentOrdersFulfillmentRequestAccepted',
  FulfillmentOrdersFulfillmentRequestRejected = 'FulfillmentOrdersFulfillmentRequestRejected',
  FulfillmentOrdersFulfillmentRequestSubmitted = 'FulfillmentOrdersFulfillmentRequestSubmitted',
  FulfillmentOrdersFulfillmentServiceFailedToComplete = 'FulfillmentOrdersFulfillmentServiceFailedToComplete',
  FulfillmentOrdersHoldReleased = 'FulfillmentOrdersHoldReleased',
  FulfillmentOrdersLineItemsPreparedForLocalDelivery = 'FulfillmentOrdersLineItemsPreparedForLocalDelivery',
  FulfillmentOrdersLineItemsPreparedForPickup = 'FulfillmentOrdersLineItemsPreparedForPickup',
  FulfillmentOrdersMoved = 'FulfillmentOrdersMoved',
  FulfillmentOrdersOrderRoutingComplete = 'FulfillmentOrdersOrderRoutingComplete',
  FulfillmentOrdersPlacedOnHold = 'FulfillmentOrdersPlacedOnHold',
  FulfillmentOrdersRescheduled = 'FulfillmentOrdersRescheduled',
  FulfillmentOrdersScheduledFulfillmentOrderReady = 'FulfillmentOrdersScheduledFulfillmentOrderReady',
  FulfillmentsCreate = 'FulfillmentsCreate',
  FulfillmentsUpdate = 'FulfillmentsUpdate',
  InventoryItemsCreate = 'InventoryItemsCreate',
  InventoryItemsDelete = 'InventoryItemsDelete',
  InventoryItemsUpdate = 'InventoryItemsUpdate',
  InventoryLevelsConnect = 'InventoryLevelsConnect',
  InventoryLevelsDisconnect = 'InventoryLevelsDisconnect',
  InventoryLevelsUpdate = 'InventoryLevelsUpdate',
  LocalesCreate = 'LocalesCreate',
  LocalesUpdate = 'LocalesUpdate',
  LocationsActivate = 'LocationsActivate',
  LocationsCreate = 'LocationsCreate',
  LocationsDeactivate = 'LocationsDeactivate',
  LocationsDelete = 'LocationsDelete',
  LocationsUpdate = 'LocationsUpdate',
  MarketsCreate = 'MarketsCreate',
  MarketsDelete = 'MarketsDelete',
  MarketsUpdate = 'MarketsUpdate',
  OrderTransactionsCreate = 'OrderTransactionsCreate',
  OrdersCancelled = 'OrdersCancelled',
  OrdersCreate = 'OrdersCreate',
  OrdersDelete = 'OrdersDelete',
  OrdersEdited = 'OrdersEdited',
  OrdersFulfilled = 'OrdersFulfilled',
  OrdersPaid = 'OrdersPaid',
  OrdersPartiallyFulfilled = 'OrdersPartiallyFulfilled',
  OrdersUpdated = 'OrdersUpdated',
  PaymentSchedulesDue = 'PaymentSchedulesDue',
  PaymentTermsCreate = 'PaymentTermsCreate',
  PaymentTermsDelete = 'PaymentTermsDelete',
  PaymentTermsUpdate = 'PaymentTermsUpdate',
  ProductListingsAdd = 'ProductListingsAdd',
  ProductListingsRemove = 'ProductListingsRemove',
  ProductListingsUpdate = 'ProductListingsUpdate',
  ProductPublicationsCreate = 'ProductPublicationsCreate',
  ProductPublicationsDelete = 'ProductPublicationsDelete',
  ProductPublicationsUpdate = 'ProductPublicationsUpdate',
  ProductsCreate = 'ProductsCreate',
  ProductsDelete = 'ProductsDelete',
  ProductsUpdate = 'ProductsUpdate',
  ProfilesCreate = 'ProfilesCreate',
  ProfilesDelete = 'ProfilesDelete',
  ProfilesUpdate = 'ProfilesUpdate',
  RefundsCreate = 'RefundsCreate',
  ReturnsApprove = 'ReturnsApprove',
  ReturnsCancel = 'ReturnsCancel',
  ReturnsClose = 'ReturnsClose',
  ReturnsDecline = 'ReturnsDecline',
  ReturnsReopen = 'ReturnsReopen',
  ReturnsRequest = 'ReturnsRequest',
  ReverseDeliveriesAttachDeliverable = 'ReverseDeliveriesAttachDeliverable',
  ReverseFulfillmentOrdersDispose = 'ReverseFulfillmentOrdersDispose',
  ScheduledProductListingsAdd = 'ScheduledProductListingsAdd',
  ScheduledProductListingsRemove = 'ScheduledProductListingsRemove',
  ScheduledProductListingsUpdate = 'ScheduledProductListingsUpdate',
  SegmentsCreate = 'SegmentsCreate',
  SegmentsDelete = 'SegmentsDelete',
  SegmentsUpdate = 'SegmentsUpdate',
  SellingPlanGroupsCreate = 'SellingPlanGroupsCreate',
  SellingPlanGroupsDelete = 'SellingPlanGroupsDelete',
  SellingPlanGroupsUpdate = 'SellingPlanGroupsUpdate',
  ShippingAddressesCreate = 'ShippingAddressesCreate',
  ShippingAddressesUpdate = 'ShippingAddressesUpdate',
  ShopUpdate = 'ShopUpdate',
  SubscriptionBillingAttemptsChallenged = 'SubscriptionBillingAttemptsChallenged',
  SubscriptionBillingAttemptsFailure = 'SubscriptionBillingAttemptsFailure',
  SubscriptionBillingAttemptsSuccess = 'SubscriptionBillingAttemptsSuccess',
  SubscriptionBillingCycleEditsCreate = 'SubscriptionBillingCycleEditsCreate',
  SubscriptionBillingCycleEditsDelete = 'SubscriptionBillingCycleEditsDelete',
  SubscriptionBillingCycleEditsUpdate = 'SubscriptionBillingCycleEditsUpdate',
  SubscriptionContractsCreate = 'SubscriptionContractsCreate',
  SubscriptionContractsUpdate = 'SubscriptionContractsUpdate',
  TaxServicesCreate = 'TaxServicesCreate',
  TaxServicesUpdate = 'TaxServicesUpdate',
  TenderTransactionsCreate = 'TenderTransactionsCreate',
  ThemesCreate = 'ThemesCreate',
  ThemesDelete = 'ThemesDelete',
  ThemesPublish = 'ThemesPublish',
  ThemesUpdate = 'ThemesUpdate',
  VariantsInStock = 'VariantsInStock',
  VariantsOutOfStock = 'VariantsOutOfStock',
}

export type StoreSort = {
  direction: SortDirection;
  field: StoreSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreSortFields {
  CreatedAt = 'createdAt',
  Handle = 'handle',
  Id = 'id',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  V1Id = 'v1Id',
}

export enum StoreType {
  Shopify = 'SHOPIFY',
}

export type StoreUpdateInput = {
  avatarUrl?: InputMaybe<Scalars['URL']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** query StoreVariantsAllData list */
export type StoreVariantsAllData = {
  __typename?: 'StoreVariantsAllData';
  customers?: Maybe<Scalars['Int']['output']>;
  grossItemsSold?: Maybe<Scalars['Int']['output']>;
  grossRevenue?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  netRevenue?: Maybe<Scalars['Float']['output']>;
  newCustomers?: Maybe<Scalars['Int']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  productTitle?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  storeV1Id: Scalars['Int']['output'];
  variantTitle?: Maybe<Scalars['String']['output']>;
};

export type StoreVariantsAllDataConnection = {
  __typename?: 'StoreVariantsAllDataConnection';
  /** Array of nodes. */
  nodes: Array<StoreVariantsAllData>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StoreVariantsAllDataFilter = {
  and?: InputMaybe<Array<StoreVariantsAllDataFilter>>;
  customers?: InputMaybe<IntFieldComparison>;
  grossItemsSold?: InputMaybe<IntFieldComparison>;
  grossRevenue?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  imageUrl?: InputMaybe<StringFieldComparison>;
  netRevenue?: InputMaybe<FloatFieldComparison>;
  newCustomers?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<StoreVariantsAllDataFilter>>;
  orders?: InputMaybe<IntFieldComparison>;
  productTitle?: InputMaybe<StringFieldComparison>;
  sku?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<StoreVariantsAllDataStoreIdFilterComparison>;
  storeV1Id?: InputMaybe<StoreVariantsAllDataStoreV1IdFilterComparison>;
  variantTitle?: InputMaybe<StringFieldComparison>;
};

export type StoreVariantsAllDataSort = {
  direction: SortDirection;
  field: StoreVariantsAllDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StoreVariantsAllDataSortFields {
  Customers = 'customers',
  GrossItemsSold = 'grossItemsSold',
  GrossRevenue = 'grossRevenue',
  Id = 'id',
  ImageUrl = 'imageUrl',
  NetRevenue = 'netRevenue',
  NewCustomers = 'newCustomers',
  Orders = 'orders',
  ProductTitle = 'productTitle',
  Sku = 'sku',
  StoreId = 'storeId',
  StoreV1Id = 'storeV1Id',
  VariantTitle = 'variantTitle',
}

export type StoreVariantsAllDataStoreIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreVariantsAllDataStoreV1IdFilterComparison = {
  eq?: InputMaybe<Scalars['Int']['input']>;
};

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notILike?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export enum StripePriceRecurringAggregateUsage {
  LastDuringPeriod = 'last_during_period',
  LastEver = 'last_ever',
  Max = 'max',
  Sum = 'sum',
}

export enum StripePriceRecurringInterval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

export enum StripePriceRecurringUsageType {
  Licensed = 'licensed',
  Metered = 'metered',
}

export enum StripeSubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Paused = 'paused',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int']['output'];
};

export type UpdateManyWarehouseLotCodesInput = {
  /** Filter used to find fields to update */
  filter: WarehouseLotCodeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: WarehouseLotActivateInput;
};

export type UpdateManyWarehouseRulesInput = {
  /** Filter used to find fields to update */
  filter: WarehouseRuleUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateWarehouseRuleInput;
};

export type UpdateOneAffiliateOfferInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: AffiliateOfferUpdateInput;
};

export type UpdateOneAffiliateReferralInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: AffiliateReferralUpdateInput;
};

export type UpdateOneCompanyInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: CompanyUpdateInput;
};

export type UpdateOneCompanyModuleItemPriceInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: CompanyModuleItemPriceUpdateInput;
};

export type UpdateOneStoreInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: StoreUpdateInput;
};

export type UpdateOneStoreModuleItemPriceInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: StoreModuleItemPriceUpdateInput;
};

export type UpdateOneStoreRewardProductInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: StoreRewardProductUpdateInput;
};

export type UpdateOneStoreShopifyAppInfoInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: StoreShopifyAppInfoUpdateInput;
};

export type UpdateOneWarehouseBundleInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateWarehouseBundle;
};

export type UpdateOneWarehouseLotCodeInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: WarehouseLotActivateInput;
};

export type UpdateOneWarehousePackageInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: WarehousePackageUpdateInput;
};

export type UpdateOneWarehouseProductInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: WarehouseProductUpdateInput;
};

export type UpdateOneWarehouseProductSkuAliasInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateWarehouseProductSkuAlias;
};

export type UpdateOneWarehouseRuleInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateWarehouseRuleInput;
};

export type UpdateWarehouseBundle = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  warehouseLocation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWarehouseProductSkuAlias = {
  alias?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateWarehouseRuleInput = {
  rule: WarehouseRuleInput;
  v2RuleId: Scalars['String']['input'];
};

export type UpdateWarehouseRuleStatusInput = {
  status: Scalars['Boolean']['input'];
  v2RuleId: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isInternal: Scalars['Boolean']['output'];
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Role>>;
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
  v1Id?: Maybe<Scalars['Int']['output']>;
};

export type UserCreateInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  roles?: InputMaybe<Array<Role>>;
  username: Scalars['String']['input'];
};

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Role>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  v1Id?: Maybe<Scalars['Int']['output']>;
};

export type UserFilter = {
  and?: InputMaybe<Array<UserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isAdmin?: InputMaybe<BooleanFieldComparison>;
  isEnabled?: InputMaybe<BooleanFieldComparison>;
  isInternal?: InputMaybe<BooleanFieldComparison>;
  nickname?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  username?: InputMaybe<StringFieldComparison>;
  v1Id?: InputMaybe<NumberFieldComparison>;
};

export type UserOffsetConnection = {
  __typename?: 'UserOffsetConnection';
  /** Array of nodes. */
  nodes: Array<User>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UserSort = {
  direction: SortDirection;
  field: UserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserSortFields {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  IsAdmin = 'isAdmin',
  IsEnabled = 'isEnabled',
  IsInternal = 'isInternal',
  Nickname = 'nickname',
  Phone = 'phone',
  UpdatedAt = 'updatedAt',
  Username = 'username',
  V1Id = 'v1Id',
}

export type UserUpdateInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<Role>>;
};

export type WarehouseAttachment = {
  __typename?: 'WarehouseAttachment';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type WarehouseAttachmentFilter = {
  and?: InputMaybe<Array<WarehouseAttachmentFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WarehouseAttachmentFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseAttachmentSort = {
  direction: SortDirection;
  field: WarehouseAttachmentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseAttachmentSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
}

export type WarehouseBeginPick = {
  __typename?: 'WarehouseBeginPick';
  itemsToPick: Array<PickingQuantity>;
  orderId: Scalars['String']['output'];
  picked?: Maybe<Scalars['Boolean']['output']>;
};

export type WarehouseBox = {
  __typename?: 'WarehouseBox';
  barcode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** unit: in */
  height?: Maybe<Scalars['Float']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  /** unit: in */
  length?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** PIETRA or CREATOR */
  source?: Maybe<WarehouseBoxSource>;
  updatedAt: Scalars['DateTime']['output'];
  /** unit: in */
  width?: Maybe<Scalars['Float']['output']>;
};

export type WarehouseBoxConnection = {
  __typename?: 'WarehouseBoxConnection';
  /** Array of nodes. */
  nodes: Array<WarehouseBox>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WarehouseBoxCreateInput = {
  barcode?: InputMaybe<Scalars['String']['input']>;
  /** unit: in */
  height?: InputMaybe<Scalars['Float']['input']>;
  /** unit: in */
  length?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  source: WarehouseBoxSource;
  /** unit: in */
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type WarehouseBoxDeleteResponse = {
  __typename?: 'WarehouseBoxDeleteResponse';
  barcode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** unit: in */
  height?: Maybe<Scalars['Float']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** unit: in */
  length?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** PIETRA or CREATOR */
  source?: Maybe<WarehouseBoxSource>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** unit: in */
  width?: Maybe<Scalars['Float']['output']>;
};

export type WarehouseBoxFilter = {
  and?: InputMaybe<Array<WarehouseBoxFilter>>;
  barcode?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseBoxFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseBoxSort = {
  direction: SortDirection;
  field: WarehouseBoxSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseBoxSortFields {
  Barcode = 'barcode',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export enum WarehouseBoxSource {
  /** Shipping material provided by the creator */
  Creator = 'CREATOR',
  /** Shipping material provided by Pietra */
  Pietra = 'PIETRA',
}

export type WarehouseBundle = {
  __typename?: 'WarehouseBundle';
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  product: WarehouseProduct;
  /** fetch name of product for bundle */
  productName: Scalars['String']['output'];
  quantity: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  warehouseLocation?: Maybe<Scalars['String']['output']>;
};

export type WarehouseBundleConnection = {
  __typename?: 'WarehouseBundleConnection';
  /** Array of edges. */
  edges: Array<WarehouseBundleEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type WarehouseBundleDeleteResponse = {
  __typename?: 'WarehouseBundleDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  warehouseLocation?: Maybe<Scalars['String']['output']>;
};

export type WarehouseBundleEdge = {
  __typename?: 'WarehouseBundleEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the WarehouseBundle */
  node: WarehouseBundle;
};

export type WarehouseBundleFilter = {
  and?: InputMaybe<Array<WarehouseBundleFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WarehouseBundleFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseBundleSort = {
  direction: SortDirection;
  field: WarehouseBundleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseBundleSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
}

export type WarehouseCaptureLotRecordInput = {
  lineItemId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
  sku: Scalars['String']['input'];
};

export type WarehouseFacility = {
  __typename?: 'WarehouseFacility';
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shippingAddress1?: Maybe<Scalars['String']['output']>;
  shippingAddress2?: Maybe<Scalars['String']['output']>;
  shippingCity?: Maybe<Scalars['String']['output']>;
  shippingCompany?: Maybe<Scalars['String']['output']>;
  shippingCountry?: Maybe<Scalars['String']['output']>;
  shippingCountryCode?: Maybe<Scalars['String']['output']>;
  shippingName?: Maybe<Scalars['String']['output']>;
  shippingPhone?: Maybe<Scalars['String']['output']>;
  shippingProvince?: Maybe<Scalars['String']['output']>;
  shippingProvinceCode?: Maybe<Scalars['String']['output']>;
  shippingZip?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type WarehouseFacilityConnection = {
  __typename?: 'WarehouseFacilityConnection';
  /** Array of nodes. */
  nodes: Array<WarehouseFacility>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WarehouseFacilityFilter = {
  and?: InputMaybe<Array<WarehouseFacilityFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseFacilityFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseFacilitySort = {
  direction: SortDirection;
  field: WarehouseFacilitySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseFacilitySortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export type WarehouseInventory = {
  __typename?: 'WarehouseInventory';
  actualQuantity: Scalars['Int']['output'];
  availableQuantity: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  facilityId: Scalars['ID']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  incomingQuantity: Scalars['Int']['output'];
  /** the quantity sum of open WarehouseOrders */
  outgoingQuantity: Scalars['Int']['output'];
  productId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type WarehouseLotActivateInput = {
  lotId: Scalars['String']['input'];
  productId: Scalars['String']['input'];
};

export type WarehouseLotCode = {
  __typename?: 'WarehouseLotCode';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  expirationDate: Scalars['String']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  initialQuantity: Scalars['Int']['output'];
  lotCode: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  productId: Scalars['ID']['output'];
  receivedDate: Scalars['String']['output'];
  remainingQuantity: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  warehouseOrderLineItemDto: Array<WarehouseOrderLineItem>;
};

export type WarehouseLotCodeWarehouseOrderLineItemDtoArgs = {
  filter?: WarehouseOrderLineItemFilter;
  sorting?: Array<WarehouseOrderLineItemSort>;
};

export type WarehouseLotCodeConnection = {
  __typename?: 'WarehouseLotCodeConnection';
  /** Array of edges. */
  edges: Array<WarehouseLotCodeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type WarehouseLotCodeDeleteResponse = {
  __typename?: 'WarehouseLotCodeDeleteResponse';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  initialQuantity?: Maybe<Scalars['Int']['output']>;
  lotCode?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['ID']['output']>;
  receivedDate?: Maybe<Scalars['String']['output']>;
  remainingQuantity?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WarehouseLotCodeEdge = {
  __typename?: 'WarehouseLotCodeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the WarehouseLotCode */
  node: WarehouseLotCode;
};

export type WarehouseLotCodeFilter = {
  and?: InputMaybe<Array<WarehouseLotCodeFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WarehouseLotCodeFilter>>;
  productId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseLotCodeSort = {
  direction: SortDirection;
  field: WarehouseLotCodeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseLotCodeSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  ProductId = 'productId',
  UpdatedAt = 'updatedAt',
}

export type WarehouseLotCodeUpdateFilter = {
  and?: InputMaybe<Array<WarehouseLotCodeUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WarehouseLotCodeUpdateFilter>>;
  productId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseLotCreateBatch = {
  lots: Array<WarehouseLotCreateInput>;
  sku: Scalars['String']['input'];
};

export type WarehouseLotCreateInput = {
  active: Scalars['Boolean']['input'];
  expirationDate: Scalars['DateTime']['input'];
  initialQuantity: Scalars['Int']['input'];
  lotCode: Scalars['String']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
  receivedDate: Scalars['DateTime']['input'];
};

export type WarehouseLotsFetchInput = {
  includeDeactivatedLots?: InputMaybe<Scalars['Boolean']['input']>;
  sku: Scalars['String']['input'];
};

export type WarehouseOrder = {
  __typename?: 'WarehouseOrder';
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  facility: WarehouseFacility;
  facilityId: Scalars['ID']['output'];
  giftNote?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  items: Array<WarehouseOrderLineItem>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  /** resolve rules at packout for orders */
  orderRules: Array<WarehouseRule>;
  origin?: Maybe<OrderOrigin>;
  packages?: Maybe<Array<WarehousePackage>>;
  packedAt?: Maybe<Scalars['DateTime']['output']>;
  packer?: Maybe<User>;
  packerId?: Maybe<Scalars['ID']['output']>;
  packingAt?: Maybe<Scalars['DateTime']['output']>;
  pickedAt?: Maybe<Scalars['DateTime']['output']>;
  picker?: Maybe<User>;
  pickerId?: Maybe<Scalars['ID']['output']>;
  pickingAt?: Maybe<Scalars['DateTime']['output']>;
  processLineId?: Maybe<Scalars['Int']['output']>;
  readyAt?: Maybe<Scalars['DateTime']['output']>;
  referenceId?: Maybe<Scalars['String']['output']>;
  referenceType?: Maybe<ReferenceType>;
  shippingAddress1?: Maybe<Scalars['String']['output']>;
  shippingAddress2?: Maybe<Scalars['String']['output']>;
  shippingCity?: Maybe<Scalars['String']['output']>;
  shippingCompany?: Maybe<Scalars['String']['output']>;
  shippingCountry?: Maybe<Scalars['String']['output']>;
  shippingCountryCode?: Maybe<Scalars['String']['output']>;
  shippingEmail?: Maybe<Scalars['String']['output']>;
  shippingMethod?: Maybe<Scalars['String']['output']>;
  shippingName?: Maybe<Scalars['String']['output']>;
  shippingPhone?: Maybe<Scalars['String']['output']>;
  shippingProvince?: Maybe<Scalars['String']['output']>;
  shippingProvinceCode?: Maybe<Scalars['String']['output']>;
  shippingZip?: Maybe<Scalars['String']['output']>;
  sn: Scalars['String']['output'];
  status: WarehouseOrderStatus;
  store: StoreEntryDto;
  storeId: Scalars['ID']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  unablePack: Scalars['Boolean']['output'];
  unablePick: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vatRegistration?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
};

export type WarehouseOrderItemsArgs = {
  filter?: WarehouseOrderLineItemFilter;
  sorting?: Array<WarehouseOrderLineItemSort>;
};

export type WarehouseOrderPackagesArgs = {
  filter?: WarehousePackageFilter;
  sorting?: Array<WarehousePackageSort>;
};

/** Create central warehouse order request parameters */
export type WarehouseOrderCreateInput = {
  facilityId?: Scalars['ID']['input'];
  giftNote?: InputMaybe<Scalars['String']['input']>;
  items: Array<WarehouseOrderLineItemCreateInput>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<OrderOrigin>;
  processLineId?: InputMaybe<Scalars['Int']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
  referenceType?: InputMaybe<ReferenceType>;
  shippingAddress1: Scalars['String']['input'];
  shippingAddress2?: InputMaybe<Scalars['String']['input']>;
  shippingCity: Scalars['String']['input'];
  shippingCompany?: InputMaybe<Scalars['String']['input']>;
  shippingCountry?: InputMaybe<Scalars['String']['input']>;
  shippingCountryCode: Scalars['String']['input'];
  shippingEmail?: InputMaybe<Scalars['String']['input']>;
  shippingMethod?: InputMaybe<Scalars['String']['input']>;
  shippingName: Scalars['String']['input'];
  shippingPhone?: InputMaybe<Scalars['String']['input']>;
  shippingProvince?: InputMaybe<Scalars['String']['input']>;
  shippingProvinceCode: Scalars['String']['input'];
  shippingZip: Scalars['String']['input'];
  sn: Scalars['String']['input'];
  storeId: Scalars['ID']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  vatRegistration?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseOrderFilter = {
  and?: InputMaybe<Array<WarehouseOrderFilter>>;
  cancelledAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  facilityId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  items?: InputMaybe<WarehouseOrderFilterWarehouseOrderLineItemFilter>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseOrderFilter>>;
  origin?: InputMaybe<OrderOriginFilterComparison>;
  packages?: InputMaybe<WarehouseOrderFilterWarehousePackageFilter>;
  packedAt?: InputMaybe<DateFieldComparison>;
  packer?: InputMaybe<WarehouseOrderFilterUserFilter>;
  packerId?: InputMaybe<IdFilterComparison>;
  packingAt?: InputMaybe<DateFieldComparison>;
  pickedAt?: InputMaybe<DateFieldComparison>;
  picker?: InputMaybe<WarehouseOrderFilterUserFilter>;
  pickerId?: InputMaybe<IdFilterComparison>;
  pickingAt?: InputMaybe<DateFieldComparison>;
  readyAt?: InputMaybe<DateFieldComparison>;
  referenceId?: InputMaybe<StringFieldComparison>;
  referenceType?: InputMaybe<ReferenceTypeFilterComparison>;
  shippingEmail?: InputMaybe<StringFieldComparison>;
  shippingMethod?: InputMaybe<StringFieldComparison>;
  shippingName?: InputMaybe<StringFieldComparison>;
  shippingZip?: InputMaybe<StringFieldComparison>;
  sn?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<WarehouseOrderStatusFilterComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  tags?: InputMaybe<WarehouseOrderTagsFilterComparison>;
  unablePack?: InputMaybe<BooleanFieldComparison>;
  unablePick?: InputMaybe<BooleanFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  vendor?: InputMaybe<StringFieldComparison>;
};

export type WarehouseOrderFilterUserFilter = {
  and?: InputMaybe<Array<WarehouseOrderFilterUserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isAdmin?: InputMaybe<BooleanFieldComparison>;
  isEnabled?: InputMaybe<BooleanFieldComparison>;
  isInternal?: InputMaybe<BooleanFieldComparison>;
  nickname?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseOrderFilterUserFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  username?: InputMaybe<StringFieldComparison>;
  v1Id?: InputMaybe<NumberFieldComparison>;
};

export type WarehouseOrderFilterWarehouseOrderLineItemFilter = {
  and?: InputMaybe<Array<WarehouseOrderFilterWarehouseOrderLineItemFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseOrderFilterWarehouseOrderLineItemFilter>>;
  orderId?: InputMaybe<IdFilterComparison>;
  product?: InputMaybe<WarehouseOrderFilterWarehouseOrderLineItemFilterWarehouseProductFilter>;
  productId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseOrderFilterWarehouseOrderLineItemFilterWarehouseProductFilter = {
  and?: InputMaybe<Array<WarehouseOrderFilterWarehouseOrderLineItemFilterWarehouseProductFilter>>;
  category?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseOrderFilterWarehouseOrderLineItemFilterWarehouseProductFilter>>;
  originCountryCode?: InputMaybe<StringFieldComparison>;
  sku?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseOrderFilterWarehousePackageFilter = {
  and?: InputMaybe<Array<WarehouseOrderFilterWarehousePackageFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WarehouseOrderFilterWarehousePackageFilter>>;
  orderId?: InputMaybe<IdFilterComparison>;
  shipmentOrderId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<WarehousePackageStatusFilterComparison>;
  trackingCompany?: InputMaybe<StringFieldComparison>;
  trackingNumber?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export enum WarehouseOrderItemPackingStatus {
  /** Init status,Front end does not display */
  None = 'NONE',
  /** show [Confirmed] */
  Packed = 'PACKED',
  UnableToPack = 'UNABLE_TO_PACK',
}

export enum WarehouseOrderItemPickingStatus {
  /** Init status,Front end does not display */
  None = 'NONE',
  /** show [In tote] */
  Picked = 'PICKED',
  /** show [Unable to pick] */
  UnableToPick = 'UNABLE_TO_PICK',
}

export type WarehouseOrderLineItem = {
  __typename?: 'WarehouseOrderLineItem';
  category?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated using priceBp instead */
  customsPriceBp?: Maybe<Scalars['Int']['output']>;
  harmonizedCode?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  order: WarehouseOrder;
  orderId: Scalars['ID']['output'];
  originCountryCode?: Maybe<Scalars['String']['output']>;
  packedQuantity: Scalars['Int']['output'];
  /** resolving note */
  packingNote?: Maybe<Scalars['String']['output']>;
  packingStatus: WarehouseOrderItemPackingStatus;
  pickedQuantity: Scalars['Int']['output'];
  pickingStatus: WarehouseOrderItemPickingStatus;
  priceBp?: Maybe<Scalars['Int']['output']>;
  product: WarehouseProduct;
  productId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  referenceId?: Maybe<Scalars['String']['output']>;
  referenceType?: Maybe<ReferenceType>;
  /** resolve sku level rules */
  rules: Array<WarehouseRule>;
  updatedAt: Scalars['DateTime']['output'];
  warehouseLocation?: Maybe<Scalars['String']['output']>;
  /** lb */
  weight?: Maybe<Scalars['Float']['output']>;
};

export type WarehouseOrderLineItemConnection = {
  __typename?: 'WarehouseOrderLineItemConnection';
  /** Array of nodes. */
  nodes: Array<WarehouseOrderLineItem>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WarehouseOrderLineItemCreateInput = {
  category: Scalars['String']['input'];
  customsPriceBp?: InputMaybe<Scalars['Int']['input']>;
  harmonizedCode: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['ID']['input']>;
  originCountryCode: Scalars['String']['input'];
  packingNoteS3Url?: InputMaybe<Scalars['String']['input']>;
  priceBp?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  referenceId?: InputMaybe<Scalars['String']['input']>;
  referenceType?: InputMaybe<ReferenceType>;
  warehouseLocation?: InputMaybe<Scalars['String']['input']>;
  /** lb */
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type WarehouseOrderLineItemFilter = {
  and?: InputMaybe<Array<WarehouseOrderLineItemFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseOrderLineItemFilter>>;
  orderId?: InputMaybe<IdFilterComparison>;
  product?: InputMaybe<WarehouseOrderLineItemFilterWarehouseProductFilter>;
  productId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseOrderLineItemFilterWarehouseProductFilter = {
  and?: InputMaybe<Array<WarehouseOrderLineItemFilterWarehouseProductFilter>>;
  category?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseOrderLineItemFilterWarehouseProductFilter>>;
  originCountryCode?: InputMaybe<StringFieldComparison>;
  sku?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseOrderLineItemSort = {
  direction: SortDirection;
  field: WarehouseOrderLineItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseOrderLineItemSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OrderId = 'orderId',
  ProductId = 'productId',
  UpdatedAt = 'updatedAt',
}

export type WarehouseOrderOffsetConnection = {
  __typename?: 'WarehouseOrderOffsetConnection';
  /** Array of nodes. */
  nodes: Array<WarehouseOrder>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WarehouseOrderShippingInfoUpdateInput = {
  shippingAddress1?: InputMaybe<Scalars['String']['input']>;
  shippingAddress2?: InputMaybe<Scalars['String']['input']>;
  shippingCity?: InputMaybe<Scalars['String']['input']>;
  shippingCompany?: InputMaybe<Scalars['String']['input']>;
  shippingCountry?: InputMaybe<Scalars['String']['input']>;
  shippingCountryCode?: InputMaybe<Scalars['String']['input']>;
  shippingEmail?: InputMaybe<Scalars['String']['input']>;
  shippingMethod?: InputMaybe<Scalars['String']['input']>;
  shippingName?: InputMaybe<Scalars['String']['input']>;
  shippingPhone?: InputMaybe<Scalars['String']['input']>;
  shippingProvince?: InputMaybe<Scalars['String']['input']>;
  shippingProvinceCode?: InputMaybe<Scalars['String']['input']>;
  shippingZip?: InputMaybe<Scalars['String']['input']>;
  vatRegistration?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseOrderSort = {
  direction: SortDirection;
  field: WarehouseOrderSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseOrderSortFields {
  CancelledAt = 'cancelledAt',
  CreatedAt = 'createdAt',
  FacilityId = 'facilityId',
  Id = 'id',
  Name = 'name',
  Origin = 'origin',
  PackedAt = 'packedAt',
  PackerId = 'packerId',
  PackingAt = 'packingAt',
  PickedAt = 'pickedAt',
  PickerId = 'pickerId',
  PickingAt = 'pickingAt',
  ReadyAt = 'readyAt',
  ReferenceId = 'referenceId',
  ReferenceType = 'referenceType',
  ShippingEmail = 'shippingEmail',
  ShippingMethod = 'shippingMethod',
  ShippingName = 'shippingName',
  ShippingZip = 'shippingZip',
  Sn = 'sn',
  Status = 'status',
  StoreId = 'storeId',
  Tags = 'tags',
  UnablePack = 'unablePack',
  UnablePick = 'unablePick',
  UpdatedAt = 'updatedAt',
  Vendor = 'vendor',
}

export enum WarehouseOrderStatus {
  Canceled = 'CANCELED',
  Open = 'OPEN',
  Packed = 'PACKED',
  Packing = 'PACKING',
  Picked = 'PICKED',
  Picking = 'PICKING',
  Ready = 'READY',
}

export type WarehouseOrderStatusFilterComparison = {
  eq?: InputMaybe<WarehouseOrderStatus>;
  gt?: InputMaybe<WarehouseOrderStatus>;
  gte?: InputMaybe<WarehouseOrderStatus>;
  iLike?: InputMaybe<WarehouseOrderStatus>;
  in?: InputMaybe<Array<WarehouseOrderStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<WarehouseOrderStatus>;
  lt?: InputMaybe<WarehouseOrderStatus>;
  lte?: InputMaybe<WarehouseOrderStatus>;
  neq?: InputMaybe<WarehouseOrderStatus>;
  notILike?: InputMaybe<WarehouseOrderStatus>;
  notIn?: InputMaybe<Array<WarehouseOrderStatus>>;
  notLike?: InputMaybe<WarehouseOrderStatus>;
};

export type WarehouseOrderTagsFilterComparison = {
  like?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type WarehousePackage = {
  __typename?: 'WarehousePackage';
  /** unit: lb */
  billableWeight?: Maybe<Scalars['Float']['output']>;
  carrierService?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** unit: lb */
  dimensionWeight?: Maybe<Scalars['Float']['output']>;
  failedReason?: Maybe<Scalars['String']['output']>;
  /** unit: in */
  height?: Maybe<Scalars['Float']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  items?: Maybe<Array<WarehouseOrderLineItem>>;
  labelUrl?: Maybe<Scalars['String']['output']>;
  /** unit: in */
  length?: Maybe<Scalars['Float']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  orderId: Scalars['ID']['output'];
  shipmentOrderId?: Maybe<Scalars['String']['output']>;
  signOnDeliveryAmount?: Maybe<Scalars['Int']['output']>;
  status: WarehousePackageStatus;
  template?: Maybe<Scalars['String']['output']>;
  trackingCompany?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** unit: lb */
  weight?: Maybe<Scalars['Float']['output']>;
  /** unit: in */
  width?: Maybe<Scalars['Float']['output']>;
};

export type WarehousePackageCalculateResultDto = {
  __typename?: 'WarehousePackageCalculateResultDto';
  carrierService: Scalars['String']['output'];
  parcel: WarehousePackageCalculateResultParcelDto;
  signOnDeliveryAmount?: Maybe<Scalars['Int']['output']>;
  useFedex: Scalars['Boolean']['output'];
};

export type WarehousePackageCalculateResultParcelDto = {
  __typename?: 'WarehousePackageCalculateResultParcelDto';
  distance_unit: Scalars['String']['output'];
  extra?: Maybe<Scalars['JSON']['output']>;
  height: Scalars['String']['output'];
  length: Scalars['String']['output'];
  mass_unit: Scalars['String']['output'];
  metadata?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Scalars['String']['output']>;
  weight: Scalars['String']['output'];
  width: Scalars['String']['output'];
};

export type WarehousePackageConnection = {
  __typename?: 'WarehousePackageConnection';
  /** Array of nodes. */
  nodes: Array<WarehousePackage>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WarehousePackageCreateInput = {
  carrierService?: InputMaybe<Scalars['String']['input']>;
  /** unit: in */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** left empty means all items */
  itemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit: in */
  length?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
  signOnDeliveryAmount?: InputMaybe<Scalars['Int']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
  trackingCompany?: InputMaybe<Scalars['String']['input']>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  trackingUrl?: InputMaybe<Scalars['String']['input']>;
  /** unit: lb */
  weight?: InputMaybe<Scalars['Float']['input']>;
  /** unit: in */
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type WarehousePackageDeleteResponse = {
  __typename?: 'WarehousePackageDeleteResponse';
  /** unit: lb */
  billableWeight?: Maybe<Scalars['Float']['output']>;
  carrierService?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** unit: lb */
  dimensionWeight?: Maybe<Scalars['Float']['output']>;
  failedReason?: Maybe<Scalars['String']['output']>;
  /** unit: in */
  height?: Maybe<Scalars['Float']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  items?: Maybe<Array<WarehouseOrderLineItem>>;
  labelUrl?: Maybe<Scalars['String']['output']>;
  /** unit: in */
  length?: Maybe<Scalars['Float']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['ID']['output']>;
  shipmentOrderId?: Maybe<Scalars['String']['output']>;
  signOnDeliveryAmount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<WarehousePackageStatus>;
  template?: Maybe<Scalars['String']['output']>;
  trackingCompany?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** unit: lb */
  weight?: Maybe<Scalars['Float']['output']>;
  /** unit: in */
  width?: Maybe<Scalars['Float']['output']>;
};

export type WarehousePackageFilter = {
  and?: InputMaybe<Array<WarehousePackageFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WarehousePackageFilter>>;
  orderId?: InputMaybe<IdFilterComparison>;
  shipmentOrderId?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<WarehousePackageStatusFilterComparison>;
  trackingCompany?: InputMaybe<StringFieldComparison>;
  trackingNumber?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehousePackageSort = {
  direction: SortDirection;
  field: WarehousePackageSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehousePackageSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  OrderId = 'orderId',
  ShipmentOrderId = 'shipmentOrderId',
  Status = 'status',
  TrackingCompany = 'trackingCompany',
  TrackingNumber = 'trackingNumber',
  UpdatedAt = 'updatedAt',
}

export enum WarehousePackageStatus {
  Failed = 'FAILED',
  None = 'NONE',
  Succeed = 'SUCCEED',
}

export type WarehousePackageStatusFilterComparison = {
  in?: InputMaybe<Array<WarehousePackageStatus>>;
  notIn?: InputMaybe<Array<WarehousePackageStatus>>;
};

export type WarehousePackageUpdateInput = {
  carrierService?: InputMaybe<Scalars['String']['input']>;
  /** unit: in */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** unit: in */
  length?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  signOnDeliveryAmount?: InputMaybe<Scalars['Int']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
  trackingCompany?: InputMaybe<Scalars['String']['input']>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  trackingUrl?: InputMaybe<Scalars['String']['input']>;
  /** unit: lb */
  weight?: InputMaybe<Scalars['Float']['input']>;
  /** unit: in */
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type WarehousePickInput = {
  orderId: Scalars['ID']['input'];
  scannedId: Scalars['String']['input'];
};

export enum WarehousePickingStatus {
  Picked = 'PICKED',
  Picking = 'PICKING',
  UnableToPick = 'UNABLE_TO_PICK',
}

export type WarehouseProduct = {
  __typename?: 'WarehouseProduct';
  barcode?: Maybe<Scalars['String']['output']>;
  bundledMetadata?: Maybe<Array<WarehouseBundle>>;
  category?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  harmonizedCode?: Maybe<Scalars['String']['output']>;
  /** in */
  height?: Maybe<Scalars['Float']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** inventory levels of product */
  inventories: Array<WarehouseInventory>;
  /** in */
  length?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  originCountryCode?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  /** sku aliases of product */
  skuAliases: Array<WarehouseProductSkuAlias>;
  store: StoreEntryDto;
  storeId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** lb */
  weight?: Maybe<Scalars['Float']['output']>;
  /** in */
  width?: Maybe<Scalars['Float']['output']>;
};

export type WarehouseProductBundledMetadataArgs = {
  filter?: WarehouseBundleFilter;
  sorting?: Array<WarehouseBundleSort>;
};

export type WarehouseProductInventoriesArgs = {
  facilityId?: InputMaybe<Scalars['ID']['input']>;
};

export type WarehouseProductConnection = {
  __typename?: 'WarehouseProductConnection';
  /** Array of nodes. */
  nodes: Array<WarehouseProduct>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WarehouseProductCreateInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  harmonizedCode?: InputMaybe<Scalars['String']['input']>;
  /** in */
  height?: InputMaybe<Scalars['Float']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** in */
  length?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  originCountryCode?: InputMaybe<Scalars['String']['input']>;
  sku: Scalars['String']['input'];
  storeId: Scalars['ID']['input'];
  /** lb */
  weight?: InputMaybe<Scalars['Float']['input']>;
  /** in */
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type WarehouseProductFilter = {
  and?: InputMaybe<Array<WarehouseProductFilter>>;
  category?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseProductFilter>>;
  originCountryCode?: InputMaybe<StringFieldComparison>;
  sku?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseProductSkuAlias = {
  __typename?: 'WarehouseProductSkuAlias';
  alias: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  sku: Scalars['String']['output'];
  storeId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type WarehouseProductSkuAliasConnection = {
  __typename?: 'WarehouseProductSkuAliasConnection';
  /** Array of nodes. */
  nodes: Array<WarehouseProductSkuAlias>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WarehouseProductSkuAliasDeleteResponse = {
  __typename?: 'WarehouseProductSkuAliasDeleteResponse';
  alias?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WarehouseProductSkuAliasFilter = {
  alias?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<WarehouseProductSkuAliasFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WarehouseProductSkuAliasFilter>>;
  sku?: InputMaybe<StringFieldComparison>;
  storeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseProductSkuAliasSort = {
  direction: SortDirection;
  field: WarehouseProductSkuAliasSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseProductSkuAliasSortFields {
  Alias = 'alias',
  CreatedAt = 'createdAt',
  Id = 'id',
  Sku = 'sku',
  StoreId = 'storeId',
  UpdatedAt = 'updatedAt',
}

export type WarehouseProductSort = {
  direction: SortDirection;
  field: WarehouseProductSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseProductSortFields {
  Category = 'category',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OriginCountryCode = 'originCountryCode',
  Sku = 'sku',
  StoreId = 'storeId',
  UpdatedAt = 'updatedAt',
}

export type WarehouseProductUpdateInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  harmonizedCode?: InputMaybe<Scalars['String']['input']>;
  /** in */
  height?: InputMaybe<Scalars['Float']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** in */
  length?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  originCountryCode?: InputMaybe<Scalars['String']['input']>;
  /** lb */
  weight?: InputMaybe<Scalars['Float']['input']>;
  /** in */
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type WarehouseRule = {
  __typename?: 'WarehouseRule';
  attachments: Array<WarehouseAttachment>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  ruleType: WarehouseRules;
  sku?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vendor?: Maybe<Scalars['String']['output']>;
};

export type WarehouseRuleAttachmentsArgs = {
  filter?: WarehouseAttachmentFilter;
  sorting?: Array<WarehouseAttachmentSort>;
};

export type WarehouseRuleConnection = {
  __typename?: 'WarehouseRuleConnection';
  /** Array of nodes. */
  nodes: Array<WarehouseRule>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WarehouseRuleDeleteResponse = {
  __typename?: 'WarehouseRuleDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  ruleType?: Maybe<WarehouseRules>;
  sku?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
};

export type WarehouseRuleFilter = {
  and?: InputMaybe<Array<WarehouseRuleFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WarehouseRuleFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type WarehouseRuleInput = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  note?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  wholesaleMetadata?: InputMaybe<WholesaleMetadataInput>;
};

export type WarehouseRuleSort = {
  direction: SortDirection;
  field: WarehouseRuleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseRuleSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
}

export type WarehouseRuleUpdateFilter = {
  and?: InputMaybe<Array<WarehouseRuleUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WarehouseRuleUpdateFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export enum WarehouseRules {
  AllOrder = 'ALL_ORDER',
  SkuLevel = 'SKU_LEVEL',
  WholesaleOrder = 'WHOLESALE_ORDER',
}

export type WarehouseScan = {
  __typename?: 'WarehouseScan';
  scannedProduct?: Maybe<PickingQuantity>;
  warnings: Array<Scalars['String']['output']>;
};

export type WholesaleMetadataInput = {
  tag?: InputMaybe<Scalars['String']['input']>;
  vendor: Scalars['String']['input'];
};

export type GenerateAiDesignPictureMutationVariables = Exact<{
  input: AiDesignPictureInput;
}>;

export type GenerateAiDesignPictureMutation = {
  __typename?: 'Mutation';
  generateAiDesignPicture: { __typename?: 'AiDesignPictureDto'; url: string };
};

export type AiLabColorChangeMutationVariables = Exact<{
  input: AiLabColorChangeInput;
}>;

export type AiLabColorChangeMutation = {
  __typename?: 'Mutation';
  aiLabColorChange: { __typename?: 'AiLabCommonDto'; content: string };
};

export type AiLabReplaceBackgroudMutationVariables = Exact<{
  flowType: ReplaceBackgroundFlowType;
  input: AiLabReplaceBackgroudInput;
}>;

export type AiLabReplaceBackgroudMutation = {
  __typename?: 'Mutation';
  aiLabReplaceBackgroud: { __typename?: 'AiLabCommonDto'; content: string };
};

export type AiLabFabricPatternMutationVariables = Exact<{
  input: AiLabFabricPatternInput;
}>;

export type AiLabFabricPatternMutation = {
  __typename?: 'Mutation';
  aiLabFabricPattern: { __typename?: 'AiLabCommonDto'; content: string };
};

export type AiLabFabricPattern1MutationVariables = Exact<{
  input: AiLabFabricPattern1Input;
}>;

export type AiLabFabricPattern1Mutation = {
  __typename?: 'Mutation';
  aiLabFabricPattern1: { __typename?: 'AiLabCommonDto'; content: string };
};

export type AiLabFabricPattern2MutationVariables = Exact<{
  input: AiLabFabricPattern2Input;
}>;

export type AiLabFabricPattern2Mutation = {
  __typename?: 'Mutation';
  aiLabFabricPattern2: { __typename?: 'AiLabCommonDto'; content: string };
};

export type AiPlaygroundOutfitMutationVariables = Exact<{
  input: AiPlaygroundOutfitInput;
}>;

export type AiPlaygroundOutfitMutation = {
  __typename?: 'Mutation';
  aiPlaygroundOutfit: { __typename?: 'AiPlaygroundOutfitDto'; images: Array<string> };
};

export type StoreModuleItemsQueryVariables = Exact<{
  paging: OffsetPaging;
  filter: StoreModuleItemFilter;
  sorting: Array<StoreModuleItemSort> | StoreModuleItemSort;
}>;

export type StoreModuleItemsQuery = {
  __typename?: 'Query';
  list: {
    __typename?: 'StoreModuleItemConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'StoreModuleItem';
      createdAt: any;
      id: string;
      isBundle: boolean;
      isDraft: boolean;
      isEnabled: boolean;
      isOptional: boolean;
      name: string;
      title: string;
      trialDays?: number | null;
      updatedAt: any;
      stripeId: string;
      prices: Array<{
        __typename?: 'StoreModuleItemPrice';
        id: string;
        createdAt: any;
        crossedPriceBp?: number | null;
        isEnabled: boolean;
        itemId: string;
        priceBp: number;
        recurringAggregateUsage?: StripePriceRecurringAggregateUsage | null;
        recurringInterval: StripePriceRecurringInterval;
        recurringIntervalCount?: number | null;
        recurringUsageType?: StripePriceRecurringUsageType | null;
        title: string;
        updatedAt: any;
      }>;
    }>;
  };
};

export type IssueAnonymousTokenMutationVariables = Exact<{
  anonymousUserId: Scalars['String']['input'];
}>;

export type IssueAnonymousTokenMutation = {
  __typename?: 'Mutation';
  issueAnonymousToken: { __typename?: 'LoginDto'; token: any };
};

export const GenerateAiDesignPictureDocument = gql`
  mutation generateAiDesignPicture($input: AiDesignPictureInput!) {
    generateAiDesignPicture(input: $input) {
      url
    }
  }
`;
export type GenerateAiDesignPictureMutationFn = Apollo.MutationFunction<
  GenerateAiDesignPictureMutation,
  GenerateAiDesignPictureMutationVariables
>;

/**
 * __useGenerateAiDesignPictureMutation__
 *
 * To run a mutation, you first call `useGenerateAiDesignPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAiDesignPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAiDesignPictureMutation, { data, loading, error }] = useGenerateAiDesignPictureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateAiDesignPictureMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateAiDesignPictureMutation, GenerateAiDesignPictureMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateAiDesignPictureMutation, GenerateAiDesignPictureMutationVariables>(
    GenerateAiDesignPictureDocument,
    options,
  );
}
export type GenerateAiDesignPictureMutationHookResult = ReturnType<typeof useGenerateAiDesignPictureMutation>;
export type GenerateAiDesignPictureMutationResult = Apollo.MutationResult<GenerateAiDesignPictureMutation>;
export type GenerateAiDesignPictureMutationOptions = Apollo.BaseMutationOptions<
  GenerateAiDesignPictureMutation,
  GenerateAiDesignPictureMutationVariables
>;
export const AiLabColorChangeDocument = gql`
  mutation aiLabColorChange($input: AiLabColorChangeInput!) {
    aiLabColorChange(input: $input) {
      content
    }
  }
`;
export type AiLabColorChangeMutationFn = Apollo.MutationFunction<
  AiLabColorChangeMutation,
  AiLabColorChangeMutationVariables
>;

/**
 * __useAiLabColorChangeMutation__
 *
 * To run a mutation, you first call `useAiLabColorChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAiLabColorChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aiLabColorChangeMutation, { data, loading, error }] = useAiLabColorChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAiLabColorChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<AiLabColorChangeMutation, AiLabColorChangeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AiLabColorChangeMutation, AiLabColorChangeMutationVariables>(
    AiLabColorChangeDocument,
    options,
  );
}
export type AiLabColorChangeMutationHookResult = ReturnType<typeof useAiLabColorChangeMutation>;
export type AiLabColorChangeMutationResult = Apollo.MutationResult<AiLabColorChangeMutation>;
export type AiLabColorChangeMutationOptions = Apollo.BaseMutationOptions<
  AiLabColorChangeMutation,
  AiLabColorChangeMutationVariables
>;
export const AiLabReplaceBackgroudDocument = gql`
  mutation aiLabReplaceBackgroud($flowType: ReplaceBackgroundFlowType!, $input: AiLabReplaceBackgroudInput!) {
    aiLabReplaceBackgroud(flowType: $flowType, input: $input) {
      content
    }
  }
`;
export type AiLabReplaceBackgroudMutationFn = Apollo.MutationFunction<
  AiLabReplaceBackgroudMutation,
  AiLabReplaceBackgroudMutationVariables
>;

/**
 * __useAiLabReplaceBackgroudMutation__
 *
 * To run a mutation, you first call `useAiLabReplaceBackgroudMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAiLabReplaceBackgroudMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aiLabReplaceBackgroudMutation, { data, loading, error }] = useAiLabReplaceBackgroudMutation({
 *   variables: {
 *      flowType: // value for 'flowType'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAiLabReplaceBackgroudMutation(
  baseOptions?: Apollo.MutationHookOptions<AiLabReplaceBackgroudMutation, AiLabReplaceBackgroudMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AiLabReplaceBackgroudMutation, AiLabReplaceBackgroudMutationVariables>(
    AiLabReplaceBackgroudDocument,
    options,
  );
}
export type AiLabReplaceBackgroudMutationHookResult = ReturnType<typeof useAiLabReplaceBackgroudMutation>;
export type AiLabReplaceBackgroudMutationResult = Apollo.MutationResult<AiLabReplaceBackgroudMutation>;
export type AiLabReplaceBackgroudMutationOptions = Apollo.BaseMutationOptions<
  AiLabReplaceBackgroudMutation,
  AiLabReplaceBackgroudMutationVariables
>;
export const AiLabFabricPatternDocument = gql`
  mutation aiLabFabricPattern($input: AiLabFabricPatternInput!) {
    aiLabFabricPattern(input: $input) {
      content
    }
  }
`;
export type AiLabFabricPatternMutationFn = Apollo.MutationFunction<
  AiLabFabricPatternMutation,
  AiLabFabricPatternMutationVariables
>;

/**
 * __useAiLabFabricPatternMutation__
 *
 * To run a mutation, you first call `useAiLabFabricPatternMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAiLabFabricPatternMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aiLabFabricPatternMutation, { data, loading, error }] = useAiLabFabricPatternMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAiLabFabricPatternMutation(
  baseOptions?: Apollo.MutationHookOptions<AiLabFabricPatternMutation, AiLabFabricPatternMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AiLabFabricPatternMutation, AiLabFabricPatternMutationVariables>(
    AiLabFabricPatternDocument,
    options,
  );
}
export type AiLabFabricPatternMutationHookResult = ReturnType<typeof useAiLabFabricPatternMutation>;
export type AiLabFabricPatternMutationResult = Apollo.MutationResult<AiLabFabricPatternMutation>;
export type AiLabFabricPatternMutationOptions = Apollo.BaseMutationOptions<
  AiLabFabricPatternMutation,
  AiLabFabricPatternMutationVariables
>;
export const AiLabFabricPattern1Document = gql`
  mutation aiLabFabricPattern1($input: AiLabFabricPattern1Input!) {
    aiLabFabricPattern1(input: $input) {
      content
    }
  }
`;
export type AiLabFabricPattern1MutationFn = Apollo.MutationFunction<
  AiLabFabricPattern1Mutation,
  AiLabFabricPattern1MutationVariables
>;

/**
 * __useAiLabFabricPattern1Mutation__
 *
 * To run a mutation, you first call `useAiLabFabricPattern1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAiLabFabricPattern1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aiLabFabricPattern1Mutation, { data, loading, error }] = useAiLabFabricPattern1Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAiLabFabricPattern1Mutation(
  baseOptions?: Apollo.MutationHookOptions<AiLabFabricPattern1Mutation, AiLabFabricPattern1MutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AiLabFabricPattern1Mutation, AiLabFabricPattern1MutationVariables>(
    AiLabFabricPattern1Document,
    options,
  );
}
export type AiLabFabricPattern1MutationHookResult = ReturnType<typeof useAiLabFabricPattern1Mutation>;
export type AiLabFabricPattern1MutationResult = Apollo.MutationResult<AiLabFabricPattern1Mutation>;
export type AiLabFabricPattern1MutationOptions = Apollo.BaseMutationOptions<
  AiLabFabricPattern1Mutation,
  AiLabFabricPattern1MutationVariables
>;
export const AiLabFabricPattern2Document = gql`
  mutation aiLabFabricPattern2($input: AiLabFabricPattern2Input!) {
    aiLabFabricPattern2(input: $input) {
      content
    }
  }
`;
export type AiLabFabricPattern2MutationFn = Apollo.MutationFunction<
  AiLabFabricPattern2Mutation,
  AiLabFabricPattern2MutationVariables
>;

/**
 * __useAiLabFabricPattern2Mutation__
 *
 * To run a mutation, you first call `useAiLabFabricPattern2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAiLabFabricPattern2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aiLabFabricPattern2Mutation, { data, loading, error }] = useAiLabFabricPattern2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAiLabFabricPattern2Mutation(
  baseOptions?: Apollo.MutationHookOptions<AiLabFabricPattern2Mutation, AiLabFabricPattern2MutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AiLabFabricPattern2Mutation, AiLabFabricPattern2MutationVariables>(
    AiLabFabricPattern2Document,
    options,
  );
}
export type AiLabFabricPattern2MutationHookResult = ReturnType<typeof useAiLabFabricPattern2Mutation>;
export type AiLabFabricPattern2MutationResult = Apollo.MutationResult<AiLabFabricPattern2Mutation>;
export type AiLabFabricPattern2MutationOptions = Apollo.BaseMutationOptions<
  AiLabFabricPattern2Mutation,
  AiLabFabricPattern2MutationVariables
>;
export const AiPlaygroundOutfitDocument = gql`
  mutation aiPlaygroundOutfit($input: AiPlaygroundOutfitInput!) {
    aiPlaygroundOutfit(input: $input) {
      images
    }
  }
`;
export type AiPlaygroundOutfitMutationFn = Apollo.MutationFunction<
  AiPlaygroundOutfitMutation,
  AiPlaygroundOutfitMutationVariables
>;

/**
 * __useAiPlaygroundOutfitMutation__
 *
 * To run a mutation, you first call `useAiPlaygroundOutfitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAiPlaygroundOutfitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aiPlaygroundOutfitMutation, { data, loading, error }] = useAiPlaygroundOutfitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAiPlaygroundOutfitMutation(
  baseOptions?: Apollo.MutationHookOptions<AiPlaygroundOutfitMutation, AiPlaygroundOutfitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AiPlaygroundOutfitMutation, AiPlaygroundOutfitMutationVariables>(
    AiPlaygroundOutfitDocument,
    options,
  );
}
export type AiPlaygroundOutfitMutationHookResult = ReturnType<typeof useAiPlaygroundOutfitMutation>;
export type AiPlaygroundOutfitMutationResult = Apollo.MutationResult<AiPlaygroundOutfitMutation>;
export type AiPlaygroundOutfitMutationOptions = Apollo.BaseMutationOptions<
  AiPlaygroundOutfitMutation,
  AiPlaygroundOutfitMutationVariables
>;
export const StoreModuleItemsDocument = gql`
  query storeModuleItems($paging: OffsetPaging!, $filter: StoreModuleItemFilter!, $sorting: [StoreModuleItemSort!]!) {
    list: storeModuleItems(paging: $paging, filter: $filter, sorting: $sorting) {
      nodes {
        createdAt
        id
        isBundle
        isDraft
        isEnabled
        isOptional
        name
        title
        trialDays
        prices {
          id
          createdAt
          crossedPriceBp
          isEnabled
          itemId
          priceBp
          recurringAggregateUsage
          recurringInterval
          recurringIntervalCount
          recurringUsageType
          title
          updatedAt
        }
        updatedAt
        stripeId
      }
      totalCount
    }
  }
`;

/**
 * __useStoreModuleItemsQuery__
 *
 * To run a query within a React component, call `useStoreModuleItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreModuleItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreModuleItemsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useStoreModuleItemsQuery(
  baseOptions: Apollo.QueryHookOptions<StoreModuleItemsQuery, StoreModuleItemsQueryVariables> &
    ({ variables: StoreModuleItemsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreModuleItemsQuery, StoreModuleItemsQueryVariables>(StoreModuleItemsDocument, options);
}
export function useStoreModuleItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StoreModuleItemsQuery, StoreModuleItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoreModuleItemsQuery, StoreModuleItemsQueryVariables>(StoreModuleItemsDocument, options);
}
export function useStoreModuleItemsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<StoreModuleItemsQuery, StoreModuleItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StoreModuleItemsQuery, StoreModuleItemsQueryVariables>(
    StoreModuleItemsDocument,
    options,
  );
}
export type StoreModuleItemsQueryHookResult = ReturnType<typeof useStoreModuleItemsQuery>;
export type StoreModuleItemsLazyQueryHookResult = ReturnType<typeof useStoreModuleItemsLazyQuery>;
export type StoreModuleItemsSuspenseQueryHookResult = ReturnType<typeof useStoreModuleItemsSuspenseQuery>;
export type StoreModuleItemsQueryResult = Apollo.QueryResult<StoreModuleItemsQuery, StoreModuleItemsQueryVariables>;
export const IssueAnonymousTokenDocument = gql`
  mutation issueAnonymousToken($anonymousUserId: String!) {
    issueAnonymousToken(anonymousUserId: $anonymousUserId) {
      token
    }
  }
`;
export type IssueAnonymousTokenMutationFn = Apollo.MutationFunction<
  IssueAnonymousTokenMutation,
  IssueAnonymousTokenMutationVariables
>;

/**
 * __useIssueAnonymousTokenMutation__
 *
 * To run a mutation, you first call `useIssueAnonymousTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueAnonymousTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueAnonymousTokenMutation, { data, loading, error }] = useIssueAnonymousTokenMutation({
 *   variables: {
 *      anonymousUserId: // value for 'anonymousUserId'
 *   },
 * });
 */
export function useIssueAnonymousTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<IssueAnonymousTokenMutation, IssueAnonymousTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IssueAnonymousTokenMutation, IssueAnonymousTokenMutationVariables>(
    IssueAnonymousTokenDocument,
    options,
  );
}
export type IssueAnonymousTokenMutationHookResult = ReturnType<typeof useIssueAnonymousTokenMutation>;
export type IssueAnonymousTokenMutationResult = Apollo.MutationResult<IssueAnonymousTokenMutation>;
export type IssueAnonymousTokenMutationOptions = Apollo.BaseMutationOptions<
  IssueAnonymousTokenMutation,
  IssueAnonymousTokenMutationVariables
>;
